@import './palette.less';
@import './default.less';
@import './default-palette.less';
@import './dark.less';
@import './dark-palette.less';

:root {
  .prizmV3Palette();
}

:root,
*[data-prizm-theme='light'] {
  .prizmV3ThemeDefaultPalette();
  .prizmDefaultThemeV3();
}

*[data-prizm-theme='dark'] {
  .prizmV3ThemeDarkPalette();
  .prizmDarkThemeV3();
}
