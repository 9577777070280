.fullsize(@position: absolute, @mode: height) {
  position: @position;
  top: 0;
  left: 0;

  & when (@mode = height) {
    width: 100%;
    height: 100%;
  }

  & when (@mode = inset) {
    bottom: 0;
    right: 0;
  }
}

.clearbtn() {
  appearance: none;
  padding: 0;
  border: 0;
  background: none;
  font-size: inherit;
  line-height: inherit;
}

.ellipsis () {
  overflow: hidden;
  text-overflow: ellipsis;
}

.transition(@param, @speed: var(--prizm-duration, 300ms)) {
  transition-property: @param;
  transition-duration: @speed;
  transition-timing-function: ease-in-out;
}

.autofill(@mode:default) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border-radius: inherit;
    color: inherit !important;
    background-color: transparent !important;

    & when (@mode = default) {
      -webkit-text-fill-color: black !important;
      border-color: #fff5c0ff;
      -webkit-box-shadow: 0 0 0 100rem #fff5c0ff inset !important; // to overlay native background
    }
  }
}

.clearinput(@mode: default) {
  .autofill(@mode);
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: inherit;
  background: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor; // for Safari
}

.createStackingContext() {
  position: relative;
  z-index: 0;
}

.customize-scroll() {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      width: 1rem;
      height: 1rem;
      border-radius: 6.25rem;
      background-clip: padding-box;
      border: 2.667rem solid transparent;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--prizm-clear-hover);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--prizm-clear-active);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: var(--prizm-text-03);
    }
  }
}

// centering with translate
.center-left() {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.center-top() {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.center-all() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination-btn() {
  border: none;
  background: none;
  outline: none;
  display: flex;
  gap: 4px;
  padding: 8px;
  text-align: left;
  cursor: pointer;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;

  color: var(--prizm-text-icon-secondary);

  &:hover:not(.active):not(:active):not(:disabled) {
    background-color: var(--prizm-button-ghost-hover);
    border-radius: 2px;
  }

  &:active,
  &.active {
    color: var(--prizm-button-primary-solid-active);
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
  }
}
