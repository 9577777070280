@icon-font-family: prizmIcons16;

@font-face {
  font-family: @icon-font-family;
  src:
    local('Prizm Icons 16'),
    url('prizmIcons16.woff2') format('woff2'),
    url('prizmIcons16.woff') format('woff'),
    url('prizmIcons16.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
