@icon-font-family: prizmIcons;

.prizm-icon {
  font-family: @icon-font-family;
  font-style: normal;

  &.account-card-details:before {
    content: '';
  }
  &.account-circle:before {
    content: '';
  }
  &.account-contact-sync:before {
    content: '';
  }
  &.account-done:before {
    content: '';
  }
  &.account-down-list-open:before {
    content: '';
  }
  &.account-down-list:before {
    content: '';
  }
  &.account-edit:before {
    content: '';
  }
  &.account-group:before {
    content: '';
  }
  &.account-key:before {
    content: '';
  }
  &.account-location:before {
    content: '';
  }
  &.account-lock:before {
    content: '';
  }
  &.account-log-in:before {
    content: '';
  }
  &.account-logout:before {
    content: '';
  }
  &.account-minus:before {
    content: '';
  }
  &.account-multiple-minus:before {
    content: '';
  }
  &.account-multiple-plus:before {
    content: '';
  }
  &.account-multiple-shield:before {
    content: '';
  }
  &.account-plus:before {
    content: '';
  }
  &.account-remove:before {
    content: '';
  }
  &.account-role:before {
    content: '';
  }
  &.account-search:before {
    content: '';
  }
  &.account-setting-1:before {
    content: '';
  }
  &.account-setting:before {
    content: '';
  }
  &.account-shield-1:before {
    content: '';
  }
  &.account-shield-alarm:before {
    content: '';
  }
  &.account-shield-off:before {
    content: '';
  }
  &.account-shield:before {
    content: '';
  }
  &.account-switch:before {
    content: '';
  }
  &.account-unlock:before {
    content: '';
  }
  &.account-worker:before {
    content: '';
  }
  &.account:before {
    content: '';
  }
  &.add-alarm:before {
    content: '';
  }
  &.add-bell-plus:before {
    content: '';
  }
  &.add-bookmark-plus:before {
    content: '';
  }
  &.add-cart-plus:before {
    content: '';
  }
  &.add-circle-fill:before {
    content: '';
  }
  &.add-circle:before {
    content: '';
  }
  &.add-file-plus:before {
    content: '';
  }
  &.add-folder-plus:before {
    content: '';
  }
  &.add-label-add:before {
    content: '';
  }
  &.add-language:before {
    content: '';
  }
  &.add-library-plus:before {
    content: '';
  }
  &.add-line-add:before {
    content: '';
  }
  &.add-map-marker-plus:before {
    content: '';
  }
  &.add-phone:before {
    content: '';
  }
  &.add-plus-box-fill:before {
    content: '';
  }
  &.add-plus-box:before {
    content: '';
  }
  &.add-plus-circle-multiple:before {
    content: '';
  }
  &.add-plus-drop-down-list:before {
    content: '';
  }
  &.add-plus:before {
    content: '';
  }
  &.add-shape-square-plus:before {
    content: '';
  }
  &.alerts-alarm-light:before {
    content: '';
  }
  &.alerts-bell-fill:before {
    content: '';
  }
  &.alerts-bell-off:before {
    content: '';
  }
  &.alerts-bell-ring-fill:before {
    content: '';
  }
  &.alerts-bell-ring:before {
    content: '';
  }
  &.alerts-bell:before {
    content: '';
  }
  &.alerts-box-fill:before {
    content: '';
  }
  &.alerts-circle-fill:before {
    content: '';
  }
  &.alerts-circle-mini-1:before {
    content: '';
  }
  &.alerts-circle-mini:before {
    content: '';
  }
  &.alerts-circle:before {
    content: '';
  }
  &.alerts-decagram-fill:before {
    content: '';
  }
  &.alerts-decagram:before {
    content: '';
  }
  &.alerts-false_alerts1:before {
    content: '';
  }
  &.alerts-false_alerts2:before {
    content: '';
  }
  &.alerts-flag-fill:before {
    content: '';
  }
  &.alerts-flag:before {
    content: '';
  }
  &.alerts-help-box-fill:before {
    content: '';
  }
  &.alerts-help-box:before {
    content: '';
  }
  &.alerts-help-circle-outline:before {
    content: '';
  }
  &.alerts-info-add-incongruity:before {
    content: '';
  }
  &.alerts-info-circle-fill:before {
    content: '';
  }
  &.alerts-info-square-fill:before {
    content: '';
  }
  &.alerts-info-square:before {
    content: '';
  }
  &.alerts-info-triangle-fill:before {
    content: '';
  }
  &.alerts-info-triangle:before {
    content: '';
  }
  &.alerts-inform-fill-mini:before {
    content: '';
  }
  &.alerts-inform-mini:before {
    content: '';
  }
  &.alerts-inform:before {
    content: '';
  }
  &.alerts-information-variant:before {
    content: '';
  }
  &.alerts-mark:before {
    content: '';
  }
  &.alerts-octagon-fill:before {
    content: '';
  }
  &.alerts-octagon:before {
    content: '';
  }
  &.alerts-pulse:before {
    content: '';
  }
  &.alerts-question-mark:before {
    content: '';
  }
  &.alerts-square:before {
    content: '';
  }
  &.alerts-warning:before {
    content: '';
  }
  &.alerts:before {
    content: '';
  }
  &.success-circle-fill:before {
    content: '';
  }
  &.success-circle-outline:before {
    content: '';
  }
  &.success-decagram-fill:before {
    content: '';
  }
  &.success-decagram-outline:before {
    content: '';
  }
  &.arrows-all:before {
    content: '';
  }
  &.arrows-arrow-circle-down:before {
    content: '';
  }
  &.arrows-arrow-circle-left:before {
    content: '';
  }
  &.arrows-arrow-circle-right:before {
    content: '';
  }
  &.arrows-arrow-circle-up:before {
    content: '';
  }
  &.arrows-arrow-expand-horizontal:before {
    content: '';
  }
  &.arrows-arrow-expand-vertical:before {
    content: '';
  }
  &.arrows-arrow-left:before {
    content: '';
  }
  &.arrows-arrow-right:before {
    content: '';
  }
  &.arrows-arrow-up-level:before {
    content: '';
  }
  &.arrows-autorenew-1:before {
    content: '';
  }
  &.arrows-autorenew:before {
    content: '';
  }
  &.arrows-back:before {
    content: '';
  }
  &.arrows-cached-1:before {
    content: '';
  }
  &.arrows-cached:before {
    content: '';
  }
  &.arrows-chevron-left:before {
    content: '';
  }
  &.arrows-chevron-right:before {
    content: '';
  }
  &.arrows-collapse-four-arrows:before {
    content: '';
  }
  &.arrows-collapse:before {
    content: '';
  }
  &.arrows-compare:before {
    content: '';
  }
  &.arrows-corner-chek-in:before {
    content: '';
  }
  &.arrows-corner-down-left:before {
    content: '';
  }
  &.arrows-corner-down-right:before {
    content: '';
  }
  &.arrows-corner-left-down:before {
    content: '';
  }
  &.arrows-corner-left-up:before {
    content: '';
  }
  &.arrows-corner-right-down:before {
    content: '';
  }
  &.arrows-corner-right-up:before {
    content: '';
  }
  &.arrows-corner-up-left:before {
    content: '';
  }
  &.arrows-corner-up-right:before {
    content: '';
  }
  &.arrows-diagonal-arrow-left-down:before {
    content: '';
  }
  &.arrows-diagonal-arrow-left-up:before {
    content: '';
  }
  &.arrows-diagonal-arrow-right-down:before {
    content: '';
  }
  &.arrows-diagonal-arrow-right-up:before {
    content: '';
  }
  &.arrows-down-2:before {
    content: '';
  }
  &.arrows-down-bold:before {
    content: '';
  }
  &.arrows-down-double-2:before {
    content: '';
  }
  &.arrows-down-double-top-2:before {
    content: '';
  }
  &.arrows-down-limit-1:before {
    content: '';
  }
  &.arrows-down-limit-double-1:before {
    content: '';
  }
  &.arrows-down:before {
    content: '';
  }
  &.arrows-download:before {
    content: '';
  }
  &.arrows-expand-all:before {
    content: '';
  }
  &.arrows-expand:before {
    content: '';
  }
  &.arrows-left-bold:before {
    content: '';
  }
  &.arrows-next:before {
    content: '';
  }
  &.arrows-refresh:before {
    content: '';
  }
  &.arrows-replay:before {
    content: '';
  }
  &.arrows-right-bold:before {
    content: '';
  }
  &.arrows-shuffle-variant:before {
    content: '';
  }
  &.arrows-shuffle:before {
    content: '';
  }
  &.arrows-swap-horiz-2:before {
    content: '';
  }
  &.arrows-swap-horiz:before {
    content: '';
  }
  &.arrows-swap-vert-2:before {
    content: '';
  }
  &.arrows-swap-vert:before {
    content: '';
  }
  &.arrows-up-2:before {
    content: '';
  }
  &.arrows-up-bold:before {
    content: '';
  }
  &.arrows-up-double-2:before {
    content: '';
  }
  &.arrows-up-double-top-2:before {
    content: '';
  }
  &.arrows-up-limit-1:before {
    content: '';
  }
  &.arrows-up-limit-double-1:before {
    content: '';
  }
  &.arrows-up:before {
    content: '';
  }
  &.audio-add-library-music:before {
    content: '';
  }
  &.audio-artist:before {
    content: '';
  }
  &.audio-bookmark-music:before {
    content: '';
  }
  &.audio-file-music:before {
    content: '';
  }
  &.audio-microphone-off:before {
    content: '';
  }
  &.audio-microphone:before {
    content: '';
  }
  &.audio-music-box:before {
    content: '';
  }
  &.audio-music-circle:before {
    content: '';
  }
  &.audio-music-off:before {
    content: '';
  }
  &.audio-music:before {
    content: '';
  }
  &.audio-volume-high:before {
    content: '';
  }
  &.audio-volume-low:before {
    content: '';
  }
  &.audio-volume-medium:before {
    content: '';
  }
  &.audio-volume-minus:before {
    content: '';
  }
  &.audio-volume-mute:before {
    content: '';
  }
  &.audio-volume-off:before {
    content: '';
  }
  &.audio-volume-plus:before {
    content: '';
  }
  &.badges-clipboard-tex:before {
    content: '';
  }
  &.badges-clipboard:before {
    content: '';
  }
  &.badges-code-download:before {
    content: '';
  }
  &.badges-code:before {
    content: '';
  }
  &.badges-tag-badges-tag-archve:before {
    content: '';
  }
  &.badges-tag-fill:before {
    content: '';
  }
  &.badges-tag-multiple:before {
    content: '';
  }
  &.badges-tag-plus:before {
    content: '';
  }
  &.badges-tag-remove:before {
    content: '';
  }
  &.badges-tag-search:before {
    content: '';
  }
  &.badges-tag-with-an-exclamation-mark:before {
    content: '';
  }
  &.badges-tag-with-binding-to-data:before {
    content: '';
  }
  &.badges-tag-without-binding-to-data:before {
    content: '';
  }
  &.badges-tag:before {
    content: '';
  }
  &.battery-10:before {
    content: '';
  }
  &.battery-30:before {
    content: '';
  }
  &.battery-50:before {
    content: '';
  }
  &.battery-80:before {
    content: '';
  }
  &.battery-alert:before {
    content: '';
  }
  &.battery-charging:before {
    content: '';
  }
  &.battery-flash-off:before {
    content: '';
  }
  &.battery-flash-ok:before {
    content: '';
  }
  &.battery-flash:before {
    content: '';
  }
  &.battery-minus:before {
    content: '';
  }
  &.battery-plus:before {
    content: '';
  }
  &.battery:before {
    content: '';
  }
  &.bookmarks-book-open-page-variant:before {
    content: '';
  }
  &.bookmarks-book-open-plus:before {
    content: '';
  }
  &.bookmarks-book-open-re-1:before {
    content: '';
  }
  &.bookmarks-book-open-re:before {
    content: '';
  }
  &.bookmarks-book-open-variant:before {
    content: '';
  }
  &.bookmarks-book-open:before {
    content: '';
  }
  &.bookmarks-book:before {
    content: '';
  }
  &.bookmarks-bookmark-check-fill:before {
    content: '';
  }
  &.bookmarks-bookmark-check:before {
    content: '';
  }
  &.bookmarks-bookmark-fill:before {
    content: '';
  }
  &.bookmarks-bookmark-music-fill:before {
    content: '';
  }
  &.bookmarks-bookmark-music:before {
    content: '';
  }
  &.bookmarks-bookmark-plus-fill:before {
    content: '';
  }
  &.bookmarks-bookmark-plus:before {
    content: '';
  }
  &.bookmarks-bookmark-remove-2:before {
    content: '';
  }
  &.bookmarks-bookmark-remove-fill:before {
    content: '';
  }
  &.bookmarks-bookmark-remove:before {
    content: '';
  }
  &.bookmarks-bookmark:before {
    content: '';
  }
  &.camera-camera:before {
    content: '';
  }
  &.camera-clapperboard-close:before {
    content: '';
  }
  &.camera-clapperboard:before {
    content: '';
  }
  &.camera-close:before {
    content: '';
  }
  &.camera-film-close:before {
    content: '';
  }
  &.camera-film:before {
    content: '';
  }
  &.camera-image:before {
    content: '';
  }
  &.camera-video-close:before {
    content: '';
  }
  &.camera-video-off:before {
    content: '';
  }
  &.camera-video:before {
    content: '';
  }
  &.camera:before {
    content: '';
  }
  &.cancel-block:before {
    content: '';
  }
  &.cancel-close-box-fill:before {
    content: '';
  }
  &.cancel-close-box:before {
    content: '';
  }
  &.cancel-close-circle-fill:before {
    content: '';
  }
  &.cancel-close-circle:before {
    content: '';
  }
  &.cancel-close-small:before {
    content: '';
  }
  &.cancel-close:before {
    content: '';
  }
  &.cancel-delete-line:before {
    content: '';
  }
  &.cancel-delete-content:before {
    content: '';
  }
  &.cansel-delete-file:before {
    content: '';
  }
  &.charts-arc-1:before {
    content: '';
  }
  &.charts-arc:before {
    content: '';
  }
  &.charts-areaspline:before {
    content: '';
  }
  &.charts-bar-graph:before {
    content: '';
  }
  &.charts-bar-stacked:before {
    content: '';
  }
  &.charts-bar:before {
    content: '';
  }
  &.charts-bubble-line:before {
    content: '';
  }
  &.charts-bubble:before {
    content: '';
  }
  &.charts-chart-donut-variant-half:before {
    content: '';
  }
  &.charts-chart-donut-variant:before {
    content: '';
  }
  &.charts-discharge:before {
    content: '';
  }
  &.charts-donut:before {
    content: '';
  }
  &.charts-elevation-decline:before {
    content: '';
  }
  &.charts-elevation-rise:before {
    content: '';
  }
  &.charts-finance:before {
    content: '';
  }
  &.charts-gantt:before {
    content: '';
  }
  &.charts-google-analytics:before {
    content: '';
  }
  &.charts-google-assistant:before {
    content: '';
  }
  &.charts-hexagon-multiple:before {
    content: '';
  }
  &.charts-histogram-chart-dashed-2:before {
    content: '';
  }
  &.charts-histogram-chart-dashed:before {
    content: '';
  }
  &.charts-histogram:before {
    content: '';
  }
  &.charts-line-variant:before {
    content: '';
  }
  &.charts-pie-1:before {
    content: '';
  }
  &.charts-pie-2:before {
    content: '';
  }
  &.charts-pie:before {
    content: '';
  }
  &.charts-poll-box:before {
    content: '';
  }
  &.charts-poll:before {
    content: '';
  }
  &.charts-scatterplot-hexbin:before {
    content: '';
  }
  &.charts-signal:before {
    content: '';
  }
  &.charts-smart-trend:before {
    content: '';
  }
  &.charts-trending-down:before {
    content: '';
  }
  &.charts-trending-up:before {
    content: '';
  }
  &.chevron-middle:before {
    content: '';
  }
  &.chevrons-close-all:before {
    content: '';
  }
  &.chevrons-double-down:before {
    content: '';
  }
  &.chevrons-double-left-mini:before {
    content: '';
  }
  &.chevrons-double-left:before {
    content: '';
  }
  &.chevrons-double-right-mini:before {
    content: '';
  }
  &.chevrons-double-right:before {
    content: '';
  }
  &.chevrons-double-up:before {
    content: '';
  }
  &.chevrons-down:before {
    content: '';
  }
  &.chevrons-dropdown-small:before {
    content: '';
  }
  &.chevrons-dropdown:before {
    content: '';
  }
  &.chevrons-dropup:before {
    content: '';
  }
  &.chevrons-input:before {
    content: '';
  }
  &.chevrons-left-mini:before {
    content: '';
  }
  &.chevrons-left:before {
    content: '';
  }
  &.chevrons-menu-left-small:before {
    content: '';
  }
  &.chevrons-menu-left:before {
    content: '';
  }
  &.chevrons-menu-right:before {
    content: '';
  }
  &.chevrons-open-all:before {
    content: '';
  }
  &.chevrons-right-mini:before {
    content: '';
  }
  &.chevrons-right:before {
    content: '';
  }
  &.chevrons-unfold-less-horizontal:before {
    content: '';
  }
  &.chevrons-unfold-less-verica:before {
    content: '';
  }
  &.chevrons-unfold-more-horizontal:before {
    content: '';
  }
  &.chevrons-unfold-more-vertical:before {
    content: '';
  }
  &.chevrons-up:before {
    content: '';
  }
  &.date-access-alarm:before {
    content: '';
  }
  &.date-asterisk:before {
    content: '';
  }
  &.date-calendar-blank:before {
    content: '';
  }
  &.date-calendar-check:before {
    content: '';
  }
  &.date-calendar-clock:before {
    content: '';
  }
  &.date-calendar-plus:before {
    content: '';
  }
  &.date-calendar-question:before {
    content: '';
  }
  &.date-calendar-range:before {
    content: '';
  }
  &.date-calendar-remove:before {
    content: '';
  }
  &.date-calendar-text:before {
    content: '';
  }
  &.date-calendar-today:before {
    content: '';
  }
  &.date-calendar:before {
    content: '';
  }
  &.date-clock-alert:before {
    content: '';
  }
  &.date-clock-end:before {
    content: '';
  }
  &.date-clock-in:before {
    content: '';
  }
  &.date-clock-out:before {
    content: '';
  }
  &.date-clock-start:before {
    content: '';
  }
  &.date-clock:before {
    content: '';
  }
  &.date-day:before {
    content: '';
  }
  &.date-history:before {
    content: '';
  }
  &.date-hour:before {
    content: '';
  }
  &.date-inform-add:before {
    content: '';
  }
  &.date-inform-delete:before {
    content: '';
  }
  &.date-midnight:before {
    content: '';
  }
  &.date-minute:before {
    content: '';
  }
  &.date-month:before {
    content: '';
  }
  &.date-now:before {
    content: '';
  }
  &.date-period-dropdown-list-open:before {
    content: '';
  }
  &.date-period-dropdown-list:before {
    content: '';
  }
  &.date-range:before {
    content: '';
  }
  &.date-second:before {
    content: '';
  }
  &.date-time-add-2:before {
    content: '';
  }
  &.date-time-add:before {
    content: '';
  }
  &.date-time-remove-2:before {
    content: '';
  }
  &.date-time-remove:before {
    content: '';
  }
  &.date-timer-sand-empty:before {
    content: '';
  }
  &.date-timer:before {
    content: '';
  }
  &.date-update:before {
    content: '';
  }
  &.date-year:before {
    content: '';
  }
  &.delete-do-not-disturb:before {
    content: '';
  }
  &.delete-drop:before {
    content: '';
  }
  &.delete-empty:before {
    content: '';
  }
  &.delete-forever:before {
    content: '';
  }
  &.delete-label-delete:before {
    content: '';
  }
  &.delete-minus-circle:before {
    content: '';
  }
  &.delete-minus-square:before {
    content: '';
  }
  &.delete-minus:before {
    content: '';
  }
  &.delete-restore:before {
    content: '';
  }
  &.delete:before {
    content: '';
  }
  &.devices-android-debug-bridge:before {
    content: '';
  }
  &.devices-android:before {
    content: '';
  }
  &.devices-camera-front:before {
    content: '';
  }
  &.devices-cellphone-basic:before {
    content: '';
  }
  &.devices-cellphone-link-off:before {
    content: '';
  }
  &.devices-cellphone-link:before {
    content: '';
  }
  &.devices-cellphone-wireless:before {
    content: '';
  }
  &.devices-cellphone:before {
    content: '';
  }
  &.devices-desktop-tower:before {
    content: '';
  }
  &.devices-hard-drive-outline:before {
    content: '';
  }
  &.devices-headphones:before {
    content: '';
  }
  &.devices-input-device:before {
    content: '';
  }
  &.devices-laptop-off-1:before {
    content: '';
  }
  &.devices-laptop-off:before {
    content: '';
  }
  &.devices-laptop:before {
    content: '';
  }
  &.devices-monitor:before {
    content: '';
  }
  &.devices-printer-dropdown-list:before {
    content: '';
  }
  &.devices-printer:before {
    content: '';
  }
  &.devices-sensor:before {
    content: '';
  }
  &.devices-speaker:before {
    content: '';
  }
  &.devices-tablet:before {
    content: '';
  }
  &.devices-touchpad:before {
    content: '';
  }
  &.devices-transmitter:before {
    content: '';
  }
  &.devices-tv:before {
    content: '';
  }
  &.devices-watch-variant:before {
    content: '';
  }
  &.editor-add-column:before {
    content: '';
  }
  &.editor-add-row:before {
    content: '';
  }
  &.editor-alfavit:before {
    content: '';
  }
  &.editor-attach-2:before {
    content: '';
  }
  &.editor-attach-file:before {
    content: '';
  }
  &.editor-auto:before {
    content: '';
  }
  &.editor-auto1:before {
    content: '';
  }
  &.editor-border-all:before {
    content: '';
  }
  &.editor-border-bottom:before {
    content: '';
  }
  &.editor-border-clear:before {
    content: '';
  }
  &.editor-border-color:before {
    content: '';
  }
  &.editor-border-horizontal:before {
    content: '';
  }
  &.editor-border-inner:before {
    content: '';
  }
  &.editor-border-left:before {
    content: '';
  }
  &.editor-border-outer:before {
    content: '';
  }
  &.editor-border-right:before {
    content: '';
  }
  &.editor-border-style:before {
    content: '';
  }
  &.editor-border-top:before {
    content: '';
  }
  &.editor-border-vertical:before {
    content: '';
  }
  &.editor-brace-left:before {
    content: '';
  }
  &.editor-brace-right:before {
    content: '';
  }
  &.editor-copy-column:before {
    content: '';
  }
  &.editor-copy-drop:before {
    content: '';
  }
  &.editor-copy-row:before {
    content: '';
  }
  &.editor-copy:before {
    content: '';
  }
  &.editor-crop:before {
    content: '';
  }
  &.editor-cross-out:before {
    content: '';
  }
  &.editor-curly-brace-left:before {
    content: '';
  }
  &.editor-curly-brace-right:before {
    content: '';
  }
  &.editor-delete-column:before {
    content: '';
  }
  &.editor-delete-row:before {
    content: '';
  }
  &.editor-disconnect-link:before {
    content: '';
  }
  &.editor-discrete-set-1:before {
    content: '';
  }
  &.editor-discrete-set:before {
    content: '';
  }
  &.editor-division:before {
    content: '';
  }
  &.editor-dots:before {
    content: '';
  }
  &.editor-down-1:before {
    content: '';
  }
  &.editor-down:before {
    content: '';
  }
  &.editor-drag-and-drop:before {
    content: '';
  }
  &.editor-dropdown-list-open:before {
    content: '';
  }
  &.editor-dropdown-list:before {
    content: '';
  }
  &.editor-droplet:before {
    content: '';
  }
  &.editor-format-align-center:before {
    content: '';
  }
  &.editor-format-align-justify:before {
    content: '';
  }
  &.editor-format-align-left:before {
    content: '';
  }
  &.editor-format-align-right:before {
    content: '';
  }
  &.editor-format-bold:before {
    content: '';
  }
  &.editor-format-color-fill:before {
    content: '';
  }
  &.editor-format-color-reset:before {
    content: '';
  }
  &.editor-format-color-text:before {
    content: '';
  }
  &.editor-format-indent:before {
    content: '';
  }
  &.editor-format-indent1:before {
    content: '';
  }
  &.editor-format-italic:before {
    content: '';
  }
  &.editor-format-list-bulleted:before {
    content: '';
  }
  &.editor-format-list-numbered:before {
    content: '';
  }
  &.editor-format-textdirection-l-to-r:before {
    content: '';
  }
  &.editor-format-underlined:before {
    content: '';
  }
  &.editor-formula:before {
    content: '';
  }
  &.editor-functions:before {
    content: '';
  }
  &.editor-group-choose:before {
    content: '';
  }
  &.editor-group-dropdown:before {
    content: '';
  }
  &.editor-group-panel:before {
    content: '';
  }
  &.editor-group:before {
    content: '';
  }
  &.editor-hash:before {
    content: '';
  }
  &.editor-horizontal-align-center-:before {
    content: '';
  }
  &.editor-insert-emoticon-sad:before {
    content: '';
  }
  &.editor-insert-emoticon:before {
    content: '';
  }
  &.editor-insert-link-add:before {
    content: '';
  }
  &.editor-insert-link-delete:before {
    content: '';
  }
  &.editor-insert-link:before {
    content: '';
  }
  &.editor-line-height:before {
    content: '';
  }
  &.editor-link:before {
    content: '';
  }
  &.editor-list-dushed-2:before {
    content: '';
  }
  &.editor-merge-type:before {
    content: '';
  }
  &.editor-mini:before {
    content: '';
  }
  &.editor-minus:before {
    content: '';
  }
  &.editor-mode:before {
    content: '';
  }
  &.editor-multiplication:before {
    content: '';
  }
  &.editor-panel-down:before {
    content: '';
  }
  &.editor-panel-left:before {
    content: '';
  }
  &.editor-panel-right:before {
    content: '';
  }
  &.editor-panel-top:before {
    content: '';
  }
  &.editor-percent-1:before {
    content: '';
  }
  &.editor-percent:before {
    content: '';
  }
  &.editor-plus:before {
    content: '';
  }
  &.editor-right-1:before {
    content: '';
  }
  &.editor-right:before {
    content: '';
  }
  &.editor-screen:before {
    content: '';
  }
  &.editor-search-hierarchy:before {
    content: '';
  }
  &.editor-search-tags-words:before {
    content: '';
  }
  &.editor-sort-asc:before {
    content: '';
  }
  &.editor-subskrit-text:before {
    content: '';
  }
  &.editor-superskrit-text:before {
    content: '';
  }
  &.editor-table-lookup:before {
    content: '';
  }
  &.editor-table:before {
    content: '';
  }
  &.editor-tag:before {
    content: '';
  }
  &.editor-template:before {
    content: '';
  }
  &.editor-title:before {
    content: '';
  }
  &.editor-transpose-right:before {
    content: '';
  }
  &.editor-transpose-top:before {
    content: '';
  }
  &.editor-transpose:before {
    content: '';
  }
  &.editor-ungroup:before {
    content: '';
  }
  &.editor-vertical-align-bottom:before {
    content: '';
  }
  &.editor-vertical-align-center:before {
    content: '';
  }
  &.editor-vertical-align-center1:before {
    content: '';
  }
  &.editor-vertical-align-top:before {
    content: '';
  }
  &.editor-window:before {
    content: '';
  }
  &.editor-wrap-text:before {
    content: '';
  }
  &.files-alarm:before {
    content: '';
  }
  &.files-archive:before {
    content: '';
  }
  &.files-box:before {
    content: '';
  }
  &.files-cloud-check:before {
    content: '';
  }
  &.files-cloud:before {
    content: '';
  }
  &.files-cloup-upload:before {
    content: '';
  }
  &.files-collection-2:before {
    content: '';
  }
  &.files-confirmation:before {
    content: '';
  }
  &.files-correct:before {
    content: '';
  }
  &.files-description-properties:before {
    content: '';
  }
  &.files-document-image:before {
    content: '';
  }
  &.files-document-text:before {
    content: '';
  }
  &.files-excel-box:before {
    content: '';
  }
  &.files-export-1:before {
    content: '';
  }
  &.files-export-arr:before {
    content: '';
  }
  &.files-export-csv:before {
    content: '';
  }
  &.files-export-pdf:before {
    content: '';
  }
  &.files-export-sap:before {
    content: '';
  }
  &.files-export-xlsx:before {
    content: '';
  }
  &.files-export:before {
    content: '';
  }
  &.files-file-close:before {
    content: '';
  }
  &.files-file-doc:before {
    content: '';
  }
  &.files-file-docx:before {
    content: '';
  }
  &.files-file-open:before {
    content: '';
  }
  &.files-file-pdf:before {
    content: '';
  }
  &.files-file-play:before {
    content: '';
  }
  &.files-file-ppt:before {
    content: '';
  }
  &.files-file-pptx:before {
    content: '';
  }
  &.files-file-remove:before {
    content: '';
  }
  &.files-file-txt:before {
    content: '';
  }
  &.files-file-video:before {
    content: '';
  }
  &.files-file-xls:before {
    content: '';
  }
  &.files-file-xlsx:before {
    content: '';
  }
  &.files-file:before {
    content: '';
  }
  &.files-find-1:before {
    content: '';
  }
  &.files-find:before {
    content: '';
  }
  &.files-folder-1:before {
    content: '';
  }
  &.files-folder-add:before {
    content: '';
  }
  &.files-folder-close:before {
    content: '';
  }
  &.files-folder-delete:before {
    content: '';
  }
  &.files-folder-open-1:before {
    content: '';
  }
  &.files-folder-open:before {
    content: '';
  }
  &.files-folder-remove:before {
    content: '';
  }
  &.files-folder-root-add:before {
    content: '';
  }
  &.files-folder-root-remove:before {
    content: '';
  }
  &.files-folder:before {
    content: '';
  }
  &.files-import-1:before {
    content: '';
  }
  &.files-import-arr:before {
    content: '';
  }
  &.files-import-csv:before {
    content: '';
  }
  &.files-import-pdf:before {
    content: '';
  }
  &.files-import-xlsx:before {
    content: '';
  }
  &.files-import:before {
    content: '';
  }
  &.files-import1с:before {
    content: '';
  }
  &.files-in-group:before {
    content: '';
  }
  &.files-incorrect:before {
    content: '';
  }
  &.files-indicator:before {
    content: '';
  }
  &.files-loader:before {
    content: '';
  }
  &.files-method:before {
    content: '';
  }
  &.files-mnemo:before {
    content: '';
  }
  &.files-mode:before {
    content: '';
  }
  &.files-multi:before {
    content: '';
  }
  &.files-norm:before {
    content: '';
  }
  &.files-plus:before {
    content: '';
  }
  &.files-powerpoint:before {
    content: '';
  }
  &.files-specification:before {
    content: '';
  }
  &.files-view-document:before {
    content: '';
  }
  &.files-word-box:before {
    content: '';
  }
  &.files-просмотр:before {
    content: '';
  }
  &.finance-barcode:before {
    content: '';
  }
  &.finance-calculator-auto:before {
    content: '';
  }
  &.finance-calculator:before {
    content: '';
  }
  &.finance-credit-card-of:before {
    content: '';
  }
  &.finance-credit-card-plus:before {
    content: '';
  }
  &.finance-credit-card:before {
    content: '';
  }
  &.finance-currency-btc:before {
    content: '';
  }
  &.finance-currency-usd-off:before {
    content: '';
  }
  &.finance-currency-usd:before {
    content: '';
  }
  &.finance-qr-code-auto-1:before {
    content: '';
  }
  &.finance-qr-code-auto-2:before {
    content: '';
  }
  &.finance-qr-code-auto-3:before {
    content: '';
  }
  &.finance-qr-code-search:before {
    content: '';
  }
  &.finance-qr-code:before {
    content: '';
  }
  &.finance-scanner:before {
    content: '';
  }
  &.finance-wallet:before {
    content: '';
  }
  &.location-compass:before {
    content: '';
  }
  &.location-crosshairs-gps:before {
    content: '';
  }
  &.location-crosshairs:before {
    content: '';
  }
  &.location-language:before {
    content: '';
  }
  &.location-localization-down-list:before {
    content: '';
  }
  &.location-map-2:before {
    content: '';
  }
  &.location-map-marker-date-1:before {
    content: '';
  }
  &.location-map-marker-date-2:before {
    content: '';
  }
  &.location-map-marker-minus:before {
    content: '';
  }
  &.location-map-marker-off:before {
    content: '';
  }
  &.location-map-marker-plus:before {
    content: '';
  }
  &.location-map-marker:before {
    content: '';
  }
  &.location-map:before {
    content: '';
  }
  &.location-navigation-1:before {
    content: '';
  }
  &.location-navigation:before {
    content: '';
  }
  &.location-person-pin-circle:before {
    content: '';
  }
  &.location-pin-1:before {
    content: '';
  }
  &.location-pin-2:before {
    content: '';
  }
  &.location-pin:before {
    content: '';
  }
  &.editor-add-comment:before {
    content: '';
  }
  &.messaging-add-comment:before {
    content: '';
  }
  &.messaging-alert:before {
    content: '';
  }
  &.messaging-at:before {
    content: '';
  }
  &.messaging-emai-down-list:before {
    content: '';
  }
  &.messaging-emai:before {
    content: '';
  }
  &.messaging-email-open:before {
    content: '';
  }
  &.messaging-forward:before {
    content: '';
  }
  &.messaging-inbox:before {
    content: '';
  }
  &.messaging-message-circle:before {
    content: '';
  }
  &.messaging-message-off:before {
    content: '';
  }
  &.messaging-message:before {
    content: '';
  }
  &.messaging-processing:before {
    content: '';
  }
  &.messaging-question-ask:before {
    content: '';
  }
  &.messaging-reply:before {
    content: '';
  }
  &.messaging-send:before {
    content: '';
  }
  &.messaging-text:before {
    content: '';
  }
  &.network-close:before {
    content: '';
  }
  &.network-cloud-download:before {
    content: '';
  }
  &.network-cloud-upload:before {
    content: '';
  }
  &.network-database-administration:before {
    content: '';
  }
  &.network-database-delete:before {
    content: '';
  }
  &.network-database-json:before {
    content: '';
  }
  &.network-database-minus:before {
    content: '';
  }
  &.network-database-plus:before {
    content: '';
  }
  &.network-database-search:before {
    content: '';
  }
  &.network-database-sql:before {
    content: '';
  }
  &.network-database:before {
    content: '';
  }
  &.network-download:before {
    content: '';
  }
  &.network-ethernet-open:before {
    content: '';
  }
  &.network-external-link:before {
    content: '';
  }
  &.network-lan-connect:before {
    content: '';
  }
  &.network-lan-disconnect:before {
    content: '';
  }
  &.network-lan-pending:before {
    content: '';
  }
  &.network-lan:before {
    content: '';
  }
  &.network-layers:before {
    content: '';
  }
  &.network-library:before {
    content: '';
  }
  &.network-minus:before {
    content: '';
  }
  &.network-no-layers:before {
    content: '';
  }
  &.network-object:before {
    content: '';
  }
  &.network-plus:before {
    content: '';
  }
  &.network-scheme-2:before {
    content: '';
  }
  &.network-scheme:before {
    content: '';
  }
  &.network-security:before {
    content: '';
  }
  &.network-server-1:before {
    content: '';
  }
  &.network-server-network-off:before {
    content: '';
  }
  &.network-server-network:before {
    content: '';
  }
  &.network-server-off:before {
    content: '';
  }
  &.network-server-security:before {
    content: '';
  }
  &.network-server:before {
    content: '';
  }
  &.network-upload:before {
    content: '';
  }
  &.phone-call-missed:before {
    content: '';
  }
  &.phone-in-talk:before {
    content: '';
  }
  &.phone-minus:before {
    content: '';
  }
  &.phone-missed:before {
    content: '';
  }
  &.phone-off:before {
    content: '';
  }
  &.phone-paused:before {
    content: '';
  }
  &.phone-plus:before {
    content: '';
  }
  &.phone:before {
    content: '';
  }
  &.player-loop:before {
    content: '';
  }
  &.player-pause:before {
    content: '';
  }
  &.player-play-back:before {
    content: '';
  }
  &.player-play:before {
    content: '';
  }
  &.player-skip-backward:before {
    content: '';
  }
  &.player-skip-forward:before {
    content: '';
  }
  &.player-skip-next-circle-outline:before {
    content: '';
  }
  &.player-skip-previous:before {
    content: '';
  }
  &.player-stop:before {
    content: '';
  }
  &.player-voicemail:before {
    content: '';
  }
  &.burn:before {
    content: '';
  }
  &.cargo-box-2:before {
    content: '';
  }
  &.cargo-box:before {
    content: '';
  }
  &.cargo-container:before {
    content: '';
  }
  &.cargo:before {
    content: '';
  }
  &.fire:before {
    content: '';
  }
  &.freeze:before {
    content: '';
  }
  &.gas:before {
    content: '';
  }
  &.loading:before {
    content: '';
  }
  &.poi-achive-Mir:before {
    content: '';
  }
  &.poi-article-1:before {
    content: '';
  }
  &.poi-barley:before {
    content: '';
  }
  &.poi-bdrv-administration:before {
    content: '';
  }
  &.poi-boiler-hot-water-gas-and-oil:before {
    content: '';
  }
  &.poi-cancel-sample-reject:before {
    content: '';
  }
  &.poi-coal:before {
    content: '';
  }
  &.poi-crane:before {
    content: '';
  }
  &.poi-difference:before {
    content: '';
  }
  &.poi-early-version:before {
    content: '';
  }
  &.poi-ecopost-circle:before {
    content: '';
  }
  &.poi-ecopost-rectangle:before {
    content: '';
  }
  &.poi-electrics:before {
    content: '';
  }
  &.poi-factory pipe:before {
    content: '';
  }
  &.poi-factory-pipes:before {
    content: '';
  }
  &.poi-factory:before {
    content: '';
  }
  &.poi-fan:before {
    content: '';
  }
  &.poi-flask-1:before {
    content: '';
  }
  &.poi-flask-2:before {
    content: '';
  }
  &.poi-flask-3:before {
    content: '';
  }
  &.poi-flask-4:before {
    content: '';
  }
  &.poi-flask-5:before {
    content: '';
  }
  &.poi-fuel-oil-tank:before {
    content: '';
  }
  &.poi-fuel:before {
    content: '';
  }
  &.poi-gas-balloon:before {
    content: '';
  }
  &.poi-gas-monitoring:before {
    content: '';
  }
  &.poi-gas-station:before {
    content: '';
  }
  &.poi-gear-cooling:before {
    content: '';
  }
  &.poi-generator:before {
    content: '';
  }
  &.poi-git-lab:before {
    content: '';
  }
  &.poi-heat-exchanger:before {
    content: '';
  }
  &.poi-heat-recovery-boiler:before {
    content: '';
  }
  &.poi-high-speed-reduction-cooling:before {
    content: '';
  }
  &.poi-lng-storage:before {
    content: '';
  }
  &.poi-medicine:before {
    content: '';
  }
  &.poi-ni-fi:before {
    content: '';
  }
  &.poi-nuclear-station:before {
    content: '';
  }
  &.poi-oil-factory:before {
    content: '';
  }
  &.poi-oil-field:before {
    content: '';
  }
  &.poi-oil-storage:before {
    content: '';
  }
  &.poi-oil-tank:before {
    content: '';
  }
  &.poi-owl:before {
    content: '';
  }
  &.poi-pipe-sensor:before {
    content: '';
  }
  &.poi-pipe-valve:before {
    content: '';
  }
  &.poi-pipeline:before {
    content: '';
  }
  &.poi-plus:before {
    content: '';
  }
  &.poi-press:before {
    content: '';
  }
  &.poi-pump-drop:before {
    content: '';
  }
  &.poi-pump:before {
    content: '';
  }
  &.poi-reject-the-sample:before {
    content: '';
  }
  &.poi-respirator-2-1:before {
    content: '';
  }
  &.poi-respirator-2:before {
    content: '';
  }
  &.poi-respirator-3:before {
    content: '';
  }
  &.poi-respirator-4:before {
    content: '';
  }
  &.poi-respirator-5:before {
    content: '';
  }
  &.poi-respirator:before {
    content: '';
  }
  &.poi-separator:before {
    content: '';
  }
  &.poi-steam-boiler:before {
    content: '';
  }
  &.poi-steam-turbine:before {
    content: '';
  }
  &.poi-steam:before {
    content: '';
  }
  &.poi-strit:before {
    content: '';
  }
  &.poi-tank:before {
    content: '';
  }
  &.poi-water-heater:before {
    content: '';
  }
  &.poi-water-pump:before {
    content: '';
  }
  &.product-grade-according-to-specification:before {
    content: '';
  }
  &.snowflake:before {
    content: '';
  }
  &.selection-check-all:before {
    content: '';
  }
  &.selection-check-simple:before {
    content: '';
  }
  &.selection-checkbox-blank-circle:before {
    content: '';
  }
  &.selection-checkbox-blank:before {
    content: '';
  }
  &.selection-checkbox-marked-circle-chanel:before {
    content: '';
  }
  &.selection-checkbox-marked-circle:before {
    content: '';
  }
  &.selection-checked-1:before {
    content: '';
  }
  &.selection-checked-2:before {
    content: '';
  }
  &.selection-checked-empty:before {
    content: '';
  }
  &.selection-checked:before {
    content: '';
  }
  &.selection-choice:before {
    content: '';
  }
  &.selection-halfchoice:before {
    content: '';
  }
  &.selection-process:before {
    content: '';
  }
  &.selection-radio-off:before {
    content: '';
  }
  &.selection-radio-on:before {
    content: '';
  }
  &.selection-success:before {
    content: '';
  }
  &.selection-toggle-left:before {
    content: '';
  }
  &.selection-toggle-right:before {
    content: '';
  }
  &.selection-unchoice:before {
    content: '';
  }
  &.settings-2:before {
    content: '';
  }
  &.settings-apps:before {
    content: '';
  }
  &.settings-more-horiz:before {
    content: '';
  }
  &.settings-more-vert:before {
    content: '';
  }
  &.settings-power:before {
    content: '';
  }
  &.settings-tune-vertical:before {
    content: '';
  }
  &.settings-tune:before {
    content: '';
  }
  &.settings:before {
    content: '';
  }
  &.shapes-Key:before {
    content: '';
  }
  &.shapes-Star:before {
    content: '';
  }
  &.shapes-animation:before {
    content: '';
  }
  &.shapes-arrange-bring-forward:before {
    content: '';
  }
  &.shapes-arrange-send-backward:before {
    content: '';
  }
  &.shapes-arrange-send-to-back:before {
    content: '';
  }
  &.shapes-box-shadow:before {
    content: '';
  }
  &.shapes-checkbox-intermediate:before {
    content: '';
  }
  &.shapes-checkerboard:before {
    content: '';
  }
  &.shapes-collage:before {
    content: '';
  }
  &.shapes-collector:before {
    content: '';
  }
  &.shapes-cube-3:before {
    content: '';
  }
  &.shapes-cube-outline:before {
    content: '';
  }
  &.shapes-cube:before {
    content: '';
  }
  &.shapes-drawing-box:before {
    content: '';
  }
  &.shapes-drawing-outline:before {
    content: '';
  }
  &.shapes-drawing:before {
    content: '';
  }
  &.shapes-hexagon-fill:before {
    content: '';
  }
  &.shapes-hexagon:before {
    content: '';
  }
  &.shapes-layers-off:before {
    content: '';
  }
  &.shapes-layers:before {
    content: '';
  }
  &.shapes-nest-protec:before {
    content: '';
  }
  &.shapes-nut:before {
    content: '';
  }
  &.shapes-ontology-2:before {
    content: '';
  }
  &.shapes-ontology:before {
    content: '';
  }
  &.shapes-pentagon-fill:before {
    content: '';
  }
  &.shapes-pentagon:before {
    content: '';
  }
  &.shapes-rhombus-fill:before {
    content: '';
  }
  &.shapes-rhombus:before {
    content: '';
  }
  &.shapes-shape-1-f:before {
    content: '';
  }
  &.shapes-shape-1:before {
    content: '';
  }
  &.shapes-shape-2-f:before {
    content: '';
  }
  &.shapes-shape-2:before {
    content: '';
  }
  &.shapes-shape-3-f:before {
    content: '';
  }
  &.shapes-shape-3:before {
    content: '';
  }
  &.shapes-shape-4-f:before {
    content: '';
  }
  &.shapes-shape-4:before {
    content: '';
  }
  &.shapes-shape-5-f:before {
    content: '';
  }
  &.shapes-shape-5:before {
    content: '';
  }
  &.shapes-shape-6:before {
    content: '';
  }
  &.shapes-shape-7:before {
    content: '';
  }
  &.shapes-shape-fill:before {
    content: '';
  }
  &.shapes-shape-plus-1:before {
    content: '';
  }
  &.shapes-shape-plus:before {
    content: '';
  }
  &.shapes-shape:before {
    content: '';
  }
  &.shapes-square-inc:before {
    content: '';
  }
  &.shapes-triangle-fill:before {
    content: '';
  }
  &.shapes-triangle:before {
    content: '';
  }
  &.shopping-box:before {
    content: '';
  }
  &.shopping-cart:before {
    content: '';
  }
  &.shopping-gift:before {
    content: '';
  }
  &.shopping:before {
    content: '';
  }
  &.signal-access-point:before {
    content: '';
  }
  &.signal-bluetooth:before {
    content: '';
  }
  &.signal-cast:before {
    content: '';
  }
  &.signal-off:before {
    content: '';
  }
  &.signal-radio-tower:before {
    content: '';
  }
  &.signal-rss-box:before {
    content: '';
  }
  &.signal-wifi-off:before {
    content: '';
  }
  &.signal-wifi:before {
    content: '';
  }
  &.signal:before {
    content: '';
  }
  &.social-browser:before {
    content: '';
  }
  &.social-globe-2:before {
    content: '';
  }
  &.social-heart-fill:before {
    content: '';
  }
  &.social-heart:before {
    content: '';
  }
  &.social-home-breadcrumbs:before {
    content: '';
  }
  &.social-home:before {
    content: '';
  }
  &.social-instagram:before {
    content: '';
  }
  &.social-moon:before {
    content: '';
  }
  &.social-share:before {
    content: '';
  }
  &.social-star-fill:before {
    content: '';
  }
  &.social-star-half:before {
    content: '';
  }
  &.social-star:before {
    content: '';
  }
  &.social-sun:before {
    content: '';
  }
  &.social-tab:before {
    content: '';
  }
  &.social-telegram:before {
    content: '';
  }
  &.social-thumb-down:before {
    content: '';
  }
  &.social-thumb-up:before {
    content: '';
  }
  &.sort-abc-ru-up:before {
    content: '';
  }
  &.sort-abc-ru:before {
    content: '';
  }
  &.sort-alphabetical-up:before {
    content: '';
  }
  &.sort-alphabetical:before {
    content: '';
  }
  &.sort-apps:before {
    content: '';
  }
  &.sort-asc-arr:before {
    content: '';
  }
  &.sort-desc-arr:before {
    content: '';
  }
  &.sort-eye-off-2:before {
    content: '';
  }
  &.sort-eye-off:before {
    content: '';
  }
  &.sort-eye:before {
    content: '';
  }
  &.sort-filter-remove:before {
    content: '';
  }
  &.sort-filter-setting:before {
    content: '';
  }
  &.sort-filter:before {
    content: '';
  }
  &.sort-funnel-drop-down-list-open:before {
    content: '';
  }
  &.sort-funnel-drop-down-list:before {
    content: '';
  }
  &.sort-look-into-the-buffer:before {
    content: '';
  }
  &.sort-maximize:before {
    content: '';
  }
  &.sort-numeric:before {
    content: '';
  }
  &.sort-real-size:before {
    content: '';
  }
  &.sort-search-mistake:before {
    content: '';
  }
  &.sort-time-down:before {
    content: '';
  }
  &.sort-time-up:before {
    content: '';
  }
  &.sort-version:before {
    content: '';
  }
  &.sort-zoom-in-dropdown:before {
    content: '';
  }
  &.sort-zoom-in:before {
    content: '';
  }
  &.sort-zoom-out:before {
    content: '';
  }
  &.tools-auto-fix:before {
    content: '';
  }
  &.tools-award:before {
    content: '';
  }
  &.tools-briefcase:before {
    content: '';
  }
  &.tools-broom:before {
    content: '';
  }
  &.tools-brush:before {
    content: '';
  }
  &.tools-content-cut:before {
    content: '';
  }
  &.tools-content-save-as:before {
    content: '';
  }
  &.tools-content-save:before {
    content: '';
  }
  &.tools-content-unsave:before {
    content: '';
  }
  &.tools-earth-work:before {
    content: '';
  }
  &.tools-eraser:before {
    content: '';
  }
  &.tools-eyedropper:before {
    content: '';
  }
  &.tools-keyboard-close-1:before {
    content: '';
  }
  &.tools-keyboard-close:before {
    content: '';
  }
  &.tools-keyboard:before {
    content: '';
  }
  &.tools-lightbulb:before {
    content: '';
  }
  &.tools-npm:before {
    content: '';
  }
  &.tools-palette:before {
    content: '';
  }
  &.tools-pantone:before {
    content: '';
  }
  &.tools-ruler:before {
    content: '';
  }
  &.tools-save-all:before {
    content: '';
  }
  &.tools-save-back:before {
    content: '';
  }
  &.tools-solver:before {
    content: '';
  }
  &.tools-thermometer-minus:before {
    content: '';
  }
  &.tools-thermometer-plus:before {
    content: '';
  }
  &.tools-thermometer:before {
    content: '';
  }
  &.tools-umbrella:before {
    content: '';
  }
  &.transport-airplane:before {
    content: '';
  }
  &.transport-ambulance:before {
    content: '';
  }
  &.transport-truck-1:before {
    content: '';
  }
  &.transport-truck-2:before {
    content: '';
  }
  &.transport-truck-3:before {
    content: '';
  }
  &.transport-truck-4:before {
    content: '';
  }
  &.transport-truck-5:before {
    content: '';
  }
  &.transport-truck:before {
    content: '';
  }
  &.vectors-arrange-above:before {
    content: '';
  }
  &.vectors-arrange-below:before {
    content: '';
  }
  &.vectors-circle-variant:before {
    content: '';
  }
  &.vectors-circle:before {
    content: '';
  }
  &.vectors-combine-1:before {
    content: '';
  }
  &.vectors-combine:before {
    content: '';
  }
  &.vectors-curve:before {
    content: '';
  }
  &.vectors-default-24:before {
    content: '';
  }
  &.vectors-difference-ab:before {
    content: '';
  }
  &.vectors-difference-ba:before {
    content: '';
  }
  &.vectors-difference:before {
    content: '';
  }
  &.vectors-ellipse:before {
    content: '';
  }
  &.vectors-intersection:before {
    content: '';
  }
  &.vectors-line:before {
    content: '';
  }
  &.vectors-point:before {
    content: '';
  }
  &.vectors-polygon:before {
    content: '';
  }
  &.vectors-polyline:before {
    content: '';
  }
  &.vectors-radius:before {
    content: '';
  }
  &.vectors-rectangle:before {
    content: '';
  }
  &.vectors-selection:before {
    content: '';
  }
  &.vectors-square:before {
    content: '';
  }
  &.vectors-triangle:before {
    content: '';
  }
  &.view-agenda:before {
    content: '';
  }
  &.view-array:before {
    content: '';
  }
  &.view-carousel:before {
    content: '';
  }
  &.view-column:before {
    content: '';
  }
  &.view-component:before {
    content: '';
  }
  &.view-dashboard-variant:before {
    content: '';
  }
  &.view-dashboard:before {
    content: '';
  }
  &.view-day:before {
    content: '';
  }
  &.view-grid-outline:before {
    content: '';
  }
  &.view-grid:before {
    content: '';
  }
  &.view-headline:before {
    content: '';
  }
  &.view-list:before {
    content: '';
  }
  &.view-logo-grey-z:before {
    content: '';
  }
  &.view-logo-s.grey:before {
    content: '';
  }
  &.view-menu-2:before {
    content: '';
  }
  &.view-menu-arrow-down:before {
    content: '';
  }
  &.view-menu-arrow-left:before {
    content: '';
  }
  &.view-menu-arrow-right:before {
    content: '';
  }
  &.view-menu-arrow-up:before {
    content: '';
  }
  &.view-menu:before {
    content: '';
  }
  &.view-module:before {
    content: '';
  }
  &.view-quilt:before {
    content: '';
  }
  &.view-sequential:before {
    content: '';
  }
}
