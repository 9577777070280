.prizmV3ThemeDarkPalette() {
  --prizm-shadow-color: var(--prizm-shadow-color-dark);
  --prizm-palette-0: var(--prizm-palette-0-dark);
  --prizm-palette-1200: var(--prizm-palette-1200-dark);
  --prizm-palette-gray-h50: var(--prizm-palette-gray-h50-dark);
  --prizm-palette-gray-100: var(--prizm-palette-gray-100-dark);
  --prizm-palette-gray-h150: var(--prizm-palette-gray-h150-dark);
  --prizm-palette-gray-200: var(--prizm-palette-gray-200-dark);
  --prizm-palette-gray-h250: var(--prizm-palette-gray-h250-dark);
  --prizm-palette-gray-300: var(--prizm-palette-gray-300-dark);
  --prizm-palette-gray-h350: var(--prizm-palette-gray-h350-dark);
  --prizm-palette-gray-400: var(--prizm-palette-gray-400-dark);
  --prizm-palette-gray-h450: var(--prizm-palette-gray-h450-dark);
  --prizm-palette-gray-500: var(--prizm-palette-gray-500-dark);
  --prizm-palette-gray-h550: var(--prizm-palette-gray-h550-dark);
  --prizm-palette-gray-600: var(--prizm-palette-gray-600-dark);
  --prizm-palette-gray-h650: var(--prizm-palette-gray-h650-dark);
  --prizm-palette-gray-700: var(--prizm-palette-gray-700-dark);
  --prizm-palette-gray-h750: var(--prizm-palette-gray-h750-dark);
  --prizm-palette-gray-800: var(--prizm-palette-gray-800-dark);
  --prizm-palette-gray-h850: var(--prizm-palette-gray-h850-dark);
  --prizm-palette-gray-900: var(--prizm-palette-gray-900-dark);
  --prizm-palette-gray-h950: var(--prizm-palette-gray-h950-dark);
  --prizm-palette-gray-1000: var(--prizm-palette-gray-1000-dark);
  --prizm-palette-gray-h1050: var(--prizm-palette-gray-h1050-dark);
  --prizm-palette-gray-1100: var(--prizm-palette-gray-1100-dark);
  --prizm-palette-gray-h1150: var(--prizm-palette-gray-h1150-dark);
  --prizm-palette-blue-h50: var(--prizm-palette-blue-h50-dark);
  --prizm-palette-blue-100: var(--prizm-palette-blue-100-dark);
  --prizm-palette-blue-h150: var(--prizm-palette-blue-h150-dark);
  --prizm-palette-blue-200: var(--prizm-palette-blue-200-dark);
  --prizm-palette-blue-h250: var(--prizm-palette-blue-h250-dark);
  --prizm-palette-blue-300: var(--prizm-palette-blue-300-dark);
  --prizm-palette-blue-h350: var(--prizm-palette-blue-h350-dark);
  --prizm-palette-blue-400: var(--prizm-palette-blue-400-dark);
  --prizm-palette-blue-h450: var(--prizm-palette-blue-h450-dark);
  --prizm-palette-blue-500: var(--prizm-palette-blue-500-dark);
  --prizm-palette-blue-h550: var(--prizm-palette-blue-h550-dark);
  --prizm-palette-blue-600: var(--prizm-palette-blue-600-dark);
  --prizm-palette-blue-h650: var(--prizm-palette-blue-h650-dark);
  --prizm-palette-blue-700: var(--prizm-palette-blue-700-dark);
  --prizm-palette-blue-h750: var(--prizm-palette-blue-h750-dark);
  --prizm-palette-blue-800: var(--prizm-palette-blue-800-dark);
  --prizm-palette-blue-h850: var(--prizm-palette-blue-h850-dark);
  --prizm-palette-blue-900: var(--prizm-palette-blue-900-dark);
  --prizm-palette-blue-h950: var(--prizm-palette-blue-h950-dark);
  --prizm-palette-blue-1000: var(--prizm-palette-blue-1000-dark);
  --prizm-palette-blue-h1050: var(--prizm-palette-blue-h1050-dark);
  --prizm-palette-blue-1100: var(--prizm-palette-blue-1100-dark);
  --prizm-palette-blue-h1150: var(--prizm-palette-blue-h1150-dark);
  --prizm-palette-green-h50: var(--prizm-palette-green-h50-dark);
  --prizm-palette-green-100: var(--prizm-palette-green-100-dark);
  --prizm-palette-green-h150: var(--prizm-palette-green-h150-dark);
  --prizm-palette-green-200: var(--prizm-palette-green-200-dark);
  --prizm-palette-green-h250: var(--prizm-palette-green-h250-dark);
  --prizm-palette-green-300: var(--prizm-palette-green-300-dark);
  --prizm-palette-green-h350: var(--prizm-palette-green-h350-dark);
  --prizm-palette-green-400: var(--prizm-palette-green-400-dark);
  --prizm-palette-green-h450: var(--prizm-palette-green-h450-dark);
  --prizm-palette-green-500: var(--prizm-palette-green-500-dark);
  --prizm-palette-green-h550: var(--prizm-palette-green-h550-dark);
  --prizm-palette-green-600: var(--prizm-palette-green-600-dark);
  --prizm-palette-green-h650: var(--prizm-palette-green-h650-dark);
  --prizm-palette-green-700: var(--prizm-palette-green-700-dark);
  --prizm-palette-green-h750: var(--prizm-palette-green-h750-dark);
  --prizm-palette-green-800: var(--prizm-palette-green-800-dark);
  --prizm-palette-green-h850: var(--prizm-palette-green-h850-dark);
  --prizm-palette-green-900: var(--prizm-palette-green-900-dark);
  --prizm-palette-green-h950: var(--prizm-palette-green-h950-dark);
  --prizm-palette-green-1000: var(--prizm-palette-green-1000-dark);
  --prizm-palette-green-h1050: var(--prizm-palette-green-h1050-dark);
  --prizm-palette-green-1100: var(--prizm-palette-green-1100-dark);
  --prizm-palette-green-h1150: var(--prizm-palette-green-h1150-dark);
  --prizm-palette-yellow-h50: var(--prizm-palette-yellow-h50-dark);
  --prizm-palette-yellow-100: var(--prizm-palette-yellow-100-dark);
  --prizm-palette-yellow-h150: var(--prizm-palette-yellow-h150-dark);
  --prizm-palette-yellow-200: var(--prizm-palette-yellow-200-dark);
  --prizm-palette-yellow-h250: var(--prizm-palette-yellow-h250-dark);
  --prizm-palette-yellow-300: var(--prizm-palette-yellow-300-dark);
  --prizm-palette-yellow-h350: var(--prizm-palette-yellow-h350-dark);
  --prizm-palette-yellow-400: var(--prizm-palette-yellow-400-dark);
  --prizm-palette-yellow-h450: var(--prizm-palette-yellow-h450-dark);
  --prizm-palette-yellow-500: var(--prizm-palette-yellow-500-dark);
  --prizm-palette-yellow-h550: var(--prizm-palette-yellow-h550-dark);
  --prizm-palette-yellow-600: var(--prizm-palette-yellow-600-dark);
  --prizm-palette-yellow-h650: var(--prizm-palette-yellow-h650-dark);
  --prizm-palette-yellow-700: var(--prizm-palette-yellow-700-dark);
  --prizm-palette-yellow-h750: var(--prizm-palette-yellow-h750-dark);
  --prizm-palette-yellow-800: var(--prizm-palette-yellow-800-dark);
  --prizm-palette-yellow-h850: var(--prizm-palette-yellow-h850-dark);
  --prizm-palette-yellow-900: var(--prizm-palette-yellow-900-dark);
  --prizm-palette-yellow-h950: var(--prizm-palette-yellow-h950-dark);
  --prizm-palette-yellow-1000: var(--prizm-palette-yellow-1000-dark);
  --prizm-palette-yellow-h1050: var(--prizm-palette-yellow-h1050-dark);
  --prizm-palette-yellow-1100: var(--prizm-palette-yellow-1100-dark);
  --prizm-palette-yellow-h1150: var(--prizm-palette-yellow-h1150-dark);
  --prizm-palette-orange-h50: var(--prizm-palette-orange-h50-dark);
  --prizm-palette-orange-100: var(--prizm-palette-orange-100-dark);
  --prizm-palette-orange-h150: var(--prizm-palette-orange-h150-dark);
  --prizm-palette-orange-200: var(--prizm-palette-orange-200-dark);
  --prizm-palette-orange-h250: var(--prizm-palette-orange-h250-dark);
  --prizm-palette-orange-300: var(--prizm-palette-orange-300-dark);
  --prizm-palette-orange-h350: var(--prizm-palette-orange-h350-dark);
  --prizm-palette-orange-400: var(--prizm-palette-orange-400-dark);
  --prizm-palette-orange-h450: var(--prizm-palette-orange-h450-dark);
  --prizm-palette-orange-500: var(--prizm-palette-orange-500-dark);
  --prizm-palette-orange-h550: var(--prizm-palette-orange-h550-dark);
  --prizm-palette-orange-600: var(--prizm-palette-orange-600-dark);
  --prizm-palette-orange-h650: var(--prizm-palette-orange-h650-dark);
  --prizm-palette-orange-700: var(--prizm-palette-orange-700-dark);
  --prizm-palette-orange-h750: var(--prizm-palette-orange-h750-dark);
  --prizm-palette-orange-800: var(--prizm-palette-orange-800-dark);
  --prizm-palette-orange-h850: var(--prizm-palette-orange-h850-dark);
  --prizm-palette-orange-900: var(--prizm-palette-orange-900-dark);
  --prizm-palette-orange-h950: var(--prizm-palette-orange-h950-dark);
  --prizm-palette-orange-1000: var(--prizm-palette-orange-1000-dark);
  --prizm-palette-orange-h1050: var(--prizm-palette-orange-h1050-dark);
  --prizm-palette-orange-1100: var(--prizm-palette-orange-1100-dark);
  --prizm-palette-orange-h1150: var(--prizm-palette-orange-h1150-dark);
  --prizm-palette-red-h50: var(--prizm-palette-red-h50-dark);
  --prizm-palette-red-100: var(--prizm-palette-red-100-dark);
  --prizm-palette-red-h150: var(--prizm-palette-red-h150-dark);
  --prizm-palette-red-200: var(--prizm-palette-red-200-dark);
  --prizm-palette-red-h250: var(--prizm-palette-red-h250-dark);
  --prizm-palette-red-300: var(--prizm-palette-red-300-dark);
  --prizm-palette-red-h350: var(--prizm-palette-red-h350-dark);
  --prizm-palette-red-400: var(--prizm-palette-red-400-dark);
  --prizm-palette-red-h450: var(--prizm-palette-red-h450-dark);
  --prizm-palette-red-500: var(--prizm-palette-red-500-dark);
  --prizm-palette-red-h550: var(--prizm-palette-red-h550-dark);
  --prizm-palette-red-600: var(--prizm-palette-red-600-dark);
  --prizm-palette-red-h650: var(--prizm-palette-red-h650-dark);
  --prizm-palette-red-700: var(--prizm-palette-red-700-dark);
  --prizm-palette-red-h750: var(--prizm-palette-red-h750-dark);
  --prizm-palette-red-800: var(--prizm-palette-red-800-dark);
  --prizm-palette-red-h850: var(--prizm-palette-red-h850-dark);
  --prizm-palette-red-900: var(--prizm-palette-red-900-dark);
  --prizm-palette-red-h950: var(--prizm-palette-red-h950-dark);
  --prizm-palette-red-1000: var(--prizm-palette-red-1000-dark);
  --prizm-palette-red-h1050: var(--prizm-palette-red-h1050-dark);
  --prizm-palette-red-1050: var(--prizm-palette-red-1050-dark);
  --prizm-palette-red-h1150: var(--prizm-palette-red-h1150-dark);
}
