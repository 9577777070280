@keyframes prizmFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes prizmSkeletonVibe {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
