@font-face {
  font-family: prizmIcons16;
  src: local('Prizm Icons 16'), url('prizmIcons16.woff2') format('woff2'), url('prizmIcons16.woff') format('woff'), url('prizmIcons16.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.prizm-icon-16 {
  font-family: prizmIcons16;
  font-style: normal;
}
.prizm-icon-16.account-card-details:before {
  content: '';
}
.prizm-icon-16.account-circle:before {
  content: '';
}
.prizm-icon-16.account-contact-sync:before {
  content: '';
}
.prizm-icon-16.account-done:before {
  content: '';
}
.prizm-icon-16.account-down-list-open:before {
  content: '';
}
.prizm-icon-16.account-down-list:before {
  content: '';
}
.prizm-icon-16.account-edit:before {
  content: '';
}
.prizm-icon-16.account-group:before {
  content: '';
}
.prizm-icon-16.account-key:before {
  content: '';
}
.prizm-icon-16.account-location:before {
  content: '';
}
.prizm-icon-16.account-lock:before {
  content: '';
}
.prizm-icon-16.account-log-in:before {
  content: '';
}
.prizm-icon-16.account-logout:before {
  content: '';
}
.prizm-icon-16.account-minus:before {
  content: '';
}
.prizm-icon-16.account-multiple-minus:before {
  content: '';
}
.prizm-icon-16.account-multiple-plus:before {
  content: '';
}
.prizm-icon-16.account-multiple-shield:before {
  content: '';
}
.prizm-icon-16.account-plus:before {
  content: '';
}
.prizm-icon-16.account-remove:before {
  content: '';
}
.prizm-icon-16.account-role:before {
  content: '';
}
.prizm-icon-16.account-search:before {
  content: '';
}
.prizm-icon-16.account-setting-1:before {
  content: '';
}
.prizm-icon-16.account-setting:before {
  content: '';
}
.prizm-icon-16.account-shield-1:before {
  content: '';
}
.prizm-icon-16.account-shield-alarm:before {
  content: '';
}
.prizm-icon-16.account-shield-off:before {
  content: '';
}
.prizm-icon-16.account-shield:before {
  content: '';
}
.prizm-icon-16.account-switch:before {
  content: '';
}
.prizm-icon-16.account-unlock:before {
  content: '';
}
.prizm-icon-16.account-worker:before {
  content: '';
}
.prizm-icon-16.account:before {
  content: '';
}
.prizm-icon-16.add-alarm:before {
  content: '';
}
.prizm-icon-16.add-bell-plus:before {
  content: '';
}
.prizm-icon-16.add-bookmark-plus:before {
  content: '';
}
.prizm-icon-16.add-cart-plus:before {
  content: '';
}
.prizm-icon-16.add-circle-fill:before {
  content: '';
}
.prizm-icon-16.add-circle:before {
  content: '';
}
.prizm-icon-16.add-file-plus:before {
  content: '';
}
.prizm-icon-16.add-folder-plus:before {
  content: '';
}
.prizm-icon-16.add-label-add:before {
  content: '';
}
.prizm-icon-16.add-language:before {
  content: '';
}
.prizm-icon-16.add-library-plus:before {
  content: '';
}
.prizm-icon-16.add-line-add:before {
  content: '';
}
.prizm-icon-16.add-map-marker-plus:before {
  content: '';
}
.prizm-icon-16.add-phone:before {
  content: '';
}
.prizm-icon-16.add-plus-box-fill:before {
  content: '';
}
.prizm-icon-16.add-plus-box:before {
  content: '';
}
.prizm-icon-16.add-plus-circle-multiple:before {
  content: '';
}
.prizm-icon-16.add-plus-drop-down-list:before {
  content: '';
}
.prizm-icon-16.add-plus:before {
  content: '';
}
.prizm-icon-16.add-shape-square-plus:before {
  content: '';
}
.prizm-icon-16.alerts-alarm-light:before {
  content: '';
}
.prizm-icon-16.alerts-bell-fill:before {
  content: '';
}
.prizm-icon-16.alerts-bell-off:before {
  content: '';
}
.prizm-icon-16.alerts-bell-ring-fill:before {
  content: '';
}
.prizm-icon-16.alerts-bell-ring:before {
  content: '';
}
.prizm-icon-16.alerts-bell:before {
  content: '';
}
.prizm-icon-16.alerts-box-fill:before {
  content: '';
}
.prizm-icon-16.alerts-circle-fill:before {
  content: '';
}
.prizm-icon-16.alerts-circle-mini-1:before {
  content: '';
}
.prizm-icon-16.alerts-circle-mini:before {
  content: '';
}
.prizm-icon-16.alerts-circle:before {
  content: '';
}
.prizm-icon-16.alerts-decagram-fill:before {
  content: '';
}
.prizm-icon-16.alerts-decagram:before {
  content: '';
}
.prizm-icon-16.alerts-false_alerts1:before {
  content: '';
}
.prizm-icon-16.alerts-false_alerts2:before {
  content: '';
}
.prizm-icon-16.alerts-flag-fill:before {
  content: '';
}
.prizm-icon-16.alerts-flag:before {
  content: '';
}
.prizm-icon-16.alerts-help-box-fill:before {
  content: '';
}
.prizm-icon-16.alerts-help-box:before {
  content: '';
}
.prizm-icon-16.alerts-help-circle-outline:before {
  content: '';
}
.prizm-icon-16.alerts-help-fill:before {
  content: '';
}
.prizm-icon-16.alerts-info-add-incongruity:before {
  content: '';
}
.prizm-icon-16.alerts-info-circle-fill:before {
  content: '';
}
.prizm-icon-16.alerts-info-square-fill:before {
  content: '';
}
.prizm-icon-16.alerts-info-square:before {
  content: '';
}
.prizm-icon-16.alerts-info-triangle-fill:before {
  content: '';
}
.prizm-icon-16.alerts-info-triangle:before {
  content: '';
}
.prizm-icon-16.alerts-inform-fill-mini:before {
  content: '';
}
.prizm-icon-16.alerts-inform-mini:before {
  content: '';
}
.prizm-icon-16.alerts-inform:before {
  content: '';
}
.prizm-icon-16.alerts-information-variant:before {
  content: '';
}
.prizm-icon-16.alerts-mark:before {
  content: '';
}
.prizm-icon-16.alerts-octagon-fill:before {
  content: '';
}
.prizm-icon-16.alerts-octagon:before {
  content: '';
}
.prizm-icon-16.alerts-pulse:before {
  content: '';
}
.prizm-icon-16.alerts-question-mark:before {
  content: '';
}
.prizm-icon-16.alerts-square:before {
  content: '';
}
.prizm-icon-16.alerts-warning:before {
  content: '';
}
.prizm-icon-16.alerts:before {
  content: '';
}
.prizm-icon-16.success-circle-fill:before {
  content: '';
}
.prizm-icon-16.success-circle-outline:before {
  content: '';
}
.prizm-icon-16.success-decagram-fill:before {
  content: '';
}
.prizm-icon-16.success-decagram-outline:before {
  content: '';
}
.prizm-icon-16.arrows-all:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-circle-down:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-circle-left:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-circle-right:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-circle-up:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-expand-horizontal:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-expand-vertical:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-left:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-right:before {
  content: '';
}
.prizm-icon-16.arrows-arrow-up-level:before {
  content: '';
}
.prizm-icon-16.arrows-autorenew-1:before {
  content: '';
}
.prizm-icon-16.arrows-autorenew-circle:before {
  content: '';
}
.prizm-icon-16.arrows-autorenew:before {
  content: '';
}
.prizm-icon-16.arrows-back:before {
  content: '';
}
.prizm-icon-16.arrows-cached-1:before {
  content: '';
}
.prizm-icon-16.arrows-cached:before {
  content: '';
}
.prizm-icon-16.arrows-chevron-left:before {
  content: '';
}
.prizm-icon-16.arrows-chevron-right:before {
  content: '';
}
.prizm-icon-16.arrows-collapse-four-arrows:before {
  content: '';
}
.prizm-icon-16.arrows-collapse:before {
  content: '';
}
.prizm-icon-16.arrows-compare:before {
  content: '';
}
.prizm-icon-16.arrows-corner-chek-in:before {
  content: '';
}
.prizm-icon-16.arrows-corner-down-left:before {
  content: '';
}
.prizm-icon-16.arrows-corner-down-right:before {
  content: '';
}
.prizm-icon-16.arrows-corner-left-down:before {
  content: '';
}
.prizm-icon-16.arrows-corner-left-up:before {
  content: '';
}
.prizm-icon-16.arrows-corner-right-down:before {
  content: '';
}
.prizm-icon-16.arrows-corner-right-up:before {
  content: '';
}
.prizm-icon-16.arrows-corner-up-left:before {
  content: '';
}
.prizm-icon-16.arrows-corner-up-right:before {
  content: '';
}
.prizm-icon-16.arrows-diagonal-arrow-left-down:before {
  content: '';
}
.prizm-icon-16.arrows-diagonal-arrow-left-up:before {
  content: '';
}
.prizm-icon-16.arrows-diagonal-arrow-right-down:before {
  content: '';
}
.prizm-icon-16.arrows-diagonal-arrow-right-up:before {
  content: '';
}
.prizm-icon-16.arrows-down-2:before {
  content: '';
}
.prizm-icon-16.arrows-down-bold:before {
  content: '';
}
.prizm-icon-16.arrows-down-double-2:before {
  content: '';
}
.prizm-icon-16.arrows-down-double-top-2:before {
  content: '';
}
.prizm-icon-16.arrows-down-limit-1:before {
  content: '';
}
.prizm-icon-16.arrows-down-limit-double-1:before {
  content: '';
}
.prizm-icon-16.arrows-down:before {
  content: '';
}
.prizm-icon-16.arrows-download:before {
  content: '';
}
.prizm-icon-16.arrows-expand-all:before {
  content: '';
}
.prizm-icon-16.arrows-expand:before {
  content: '';
}
.prizm-icon-16.arrows-left-bold:before {
  content: '';
}
.prizm-icon-16.arrows-next:before {
  content: '';
}
.prizm-icon-16.arrows-refresh:before {
  content: '';
}
.prizm-icon-16.arrows-replay:before {
  content: '';
}
.prizm-icon-16.arrows-right-bold:before {
  content: '';
}
.prizm-icon-16.arrows-shuffle-variant:before {
  content: '';
}
.prizm-icon-16.arrows-shuffle:before {
  content: '';
}
.prizm-icon-16.arrows-swap-horiz-2:before {
  content: '';
}
.prizm-icon-16.arrows-swap-horiz:before {
  content: '';
}
.prizm-icon-16.arrows-swap-vert-2:before {
  content: '';
}
.prizm-icon-16.arrows-swap-vert:before {
  content: '';
}
.prizm-icon-16.arrows-up-2:before {
  content: '';
}
.prizm-icon-16.arrows-up-bold:before {
  content: '';
}
.prizm-icon-16.arrows-up-double-2:before {
  content: '';
}
.prizm-icon-16.arrows-up-double-top-2:before {
  content: '';
}
.prizm-icon-16.arrows-up-limit-1:before {
  content: '';
}
.prizm-icon-16.arrows-up-limit-double-1:before {
  content: '';
}
.prizm-icon-16.arrows-up:before {
  content: '';
}
.prizm-icon-16.audio-add-library-music:before {
  content: '';
}
.prizm-icon-16.audio-artist:before {
  content: '';
}
.prizm-icon-16.audio-bookmark-music:before {
  content: '';
}
.prizm-icon-16.audio-file-music:before {
  content: '';
}
.prizm-icon-16.audio-microphone-off:before {
  content: '';
}
.prizm-icon-16.audio-microphone:before {
  content: '';
}
.prizm-icon-16.audio-music-box:before {
  content: '';
}
.prizm-icon-16.audio-music-circle:before {
  content: '';
}
.prizm-icon-16.audio-music-off:before {
  content: '';
}
.prizm-icon-16.audio-music:before {
  content: '';
}
.prizm-icon-16.audio-volume-high:before {
  content: '';
}
.prizm-icon-16.audio-volume-low:before {
  content: '';
}
.prizm-icon-16.audio-volume-medium:before {
  content: '';
}
.prizm-icon-16.audio-volume-minus:before {
  content: '';
}
.prizm-icon-16.audio-volume-mute:before {
  content: '';
}
.prizm-icon-16.audio-volume-off:before {
  content: '';
}
.prizm-icon-16.audio-volume-plus:before {
  content: '';
}
.prizm-icon-16.badges-clipboard-tex:before {
  content: '';
}
.prizm-icon-16.badges-clipboard:before {
  content: '';
}
.prizm-icon-16.badges-code-download:before {
  content: '';
}
.prizm-icon-16.badges-code:before {
  content: '';
}
.prizm-icon-16.badges-tag-badges-tag-archve:before {
  content: '';
}
.prizm-icon-16.badges-tag-fill:before {
  content: '';
}
.prizm-icon-16.badges-tag-multiple:before {
  content: '';
}
.prizm-icon-16.badges-tag-plus:before {
  content: '';
}
.prizm-icon-16.badges-tag-remove:before {
  content: '';
}
.prizm-icon-16.badges-tag-search:before {
  content: '';
}
.prizm-icon-16.badges-tag-with-an-exclamation-mark:before {
  content: '';
}
.prizm-icon-16.badges-tag-with-binding-to-data:before {
  content: '';
}
.prizm-icon-16.badges-tag-without-binding-to-data:before {
  content: '';
}
.prizm-icon-16.badges-tag:before {
  content: '';
}
.prizm-icon-16.battery-10:before {
  content: '';
}
.prizm-icon-16.battery-30:before {
  content: '';
}
.prizm-icon-16.battery-50:before {
  content: '';
}
.prizm-icon-16.battery-80:before {
  content: '';
}
.prizm-icon-16.battery-alert:before {
  content: '';
}
.prizm-icon-16.battery-charging:before {
  content: '';
}
.prizm-icon-16.battery-flash-off:before {
  content: '';
}
.prizm-icon-16.battery-flash-ok:before {
  content: '';
}
.prizm-icon-16.battery-flash:before {
  content: '';
}
.prizm-icon-16.battery-minus:before {
  content: '';
}
.prizm-icon-16.battery-plus:before {
  content: '';
}
.prizm-icon-16.battery:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-page-variant:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-plus:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-re-1:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-re-2:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-re:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open-variant:before {
  content: '';
}
.prizm-icon-16.bookmarks-book-open:before {
  content: '';
}
.prizm-icon-16.bookmarks-book:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-check-fill:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-check:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-fill:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-music-fill:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-music:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-plus-fill:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-plus:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-remove-2:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-remove-fill:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark-remove:before {
  content: '';
}
.prizm-icon-16.bookmarks-bookmark:before {
  content: '';
}
.prizm-icon-16.camera-camera:before {
  content: '';
}
.prizm-icon-16.camera-clapperboard-close:before {
  content: '';
}
.prizm-icon-16.camera-clapperboard:before {
  content: '';
}
.prizm-icon-16.camera-close:before {
  content: '';
}
.prizm-icon-16.camera-film-close:before {
  content: '';
}
.prizm-icon-16.camera-film:before {
  content: '';
}
.prizm-icon-16.camera-image:before {
  content: '';
}
.prizm-icon-16.camera-video-close:before {
  content: '';
}
.prizm-icon-16.camera-video-off:before {
  content: '';
}
.prizm-icon-16.camera-video:before {
  content: '';
}
.prizm-icon-16.camera:before {
  content: '';
}
.prizm-icon-16.cancel-block:before {
  content: '';
}
.prizm-icon-16.cancel-close-box-fill:before {
  content: '';
}
.prizm-icon-16.cancel-close-box:before {
  content: '';
}
.prizm-icon-16.cancel-close-circle-fill:before {
  content: '';
}
.prizm-icon-16.cancel-close-circle:before {
  content: '';
}
.prizm-icon-16.cancel-close:before {
  content: '';
}
.prizm-icon-16.cancel-delete-line:before {
  content: '';
}
.prizm-icon-16.cancel-delete-content:before {
  content: '';
}
.prizm-icon-16.cansel-delete-file:before {
  content: '';
}
.prizm-icon-16.charts-arc-1:before {
  content: '';
}
.prizm-icon-16.charts-arc:before {
  content: '';
}
.prizm-icon-16.charts-areaspline:before {
  content: '';
}
.prizm-icon-16.charts-bar-graph:before {
  content: '';
}
.prizm-icon-16.charts-bar-stacked:before {
  content: '';
}
.prizm-icon-16.charts-bar:before {
  content: '';
}
.prizm-icon-16.charts-bubble-line:before {
  content: '';
}
.prizm-icon-16.charts-bubble:before {
  content: '';
}
.prizm-icon-16.charts-chart-donut-variant-half:before {
  content: '';
}
.prizm-icon-16.charts-chart-donut-variant:before {
  content: '';
}
.prizm-icon-16.charts-discharge:before {
  content: '';
}
.prizm-icon-16.charts-donut:before {
  content: '';
}
.prizm-icon-16.charts-elevation-decline:before {
  content: '';
}
.prizm-icon-16.charts-elevation-rise:before {
  content: '';
}
.prizm-icon-16.charts-finance:before {
  content: '';
}
.prizm-icon-16.charts-gantt:before {
  content: '';
}
.prizm-icon-16.charts-google-analytics:before {
  content: '';
}
.prizm-icon-16.charts-google-assistant:before {
  content: '';
}
.prizm-icon-16.charts-hexagon-multiple:before {
  content: '';
}
.prizm-icon-16.charts-histogram-chart-dashed-2:before {
  content: '';
}
.prizm-icon-16.charts-histogram-chart-dashed:before {
  content: '';
}
.prizm-icon-16.charts-histogram:before {
  content: '';
}
.prizm-icon-16.charts-line-variant:before {
  content: '';
}
.prizm-icon-16.charts-pie-1:before {
  content: '';
}
.prizm-icon-16.charts-pie-2:before {
  content: '';
}
.prizm-icon-16.charts-pie:before {
  content: '';
}
.prizm-icon-16.charts-poll-box:before {
  content: '';
}
.prizm-icon-16.charts-poll:before {
  content: '';
}
.prizm-icon-16.charts-scatterplot-hexbin:before {
  content: '';
}
.prizm-icon-16.charts-signal:before {
  content: '';
}
.prizm-icon-16.charts-smart-trend:before {
  content: '';
}
.prizm-icon-16.charts-trending-down:before {
  content: '';
}
.prizm-icon-16.charts-trending-up:before {
  content: '';
}
.prizm-icon-16.chevron-middle:before {
  content: '';
}
.prizm-icon-16.chevrons-close-all:before {
  content: '';
}
.prizm-icon-16.chevrons-double-down:before {
  content: '';
}
.prizm-icon-16.chevrons-double-left-mini:before {
  content: '';
}
.prizm-icon-16.chevrons-double-left:before {
  content: '';
}
.prizm-icon-16.chevrons-double-right-mini:before {
  content: '';
}
.prizm-icon-16.chevrons-double-right:before {
  content: '';
}
.prizm-icon-16.chevrons-double-up:before {
  content: '';
}
.prizm-icon-16.chevrons-down:before {
  content: '';
}
.prizm-icon-16.chevrons-dropdown-small:before {
  content: '';
}
.prizm-icon-16.chevrons-dropdown:before {
  content: '';
}
.prizm-icon-16.chevrons-dropup:before {
  content: '';
}
.prizm-icon-16.chevrons-input:before {
  content: '';
}
.prizm-icon-16.chevrons-left-mini:before {
  content: '';
}
.prizm-icon-16.chevrons-left:before {
  content: '';
}
.prizm-icon-16.chevrons-menu-left-small:before {
  content: '';
}
.prizm-icon-16.chevrons-menu-left:before {
  content: '';
}
.prizm-icon-16.chevrons-menu-right:before {
  content: '';
}
.prizm-icon-16.chevrons-open-all:before {
  content: '';
}
.prizm-icon-16.chevrons-right-mini:before {
  content: '';
}
.prizm-icon-16.chevrons-right:before {
  content: '';
}
.prizm-icon-16.chevrons-unfold-less-horizontal:before {
  content: '';
}
.prizm-icon-16.chevrons-unfold-less-verica:before {
  content: '';
}
.prizm-icon-16.chevrons-unfold-more-horizontal:before {
  content: '';
}
.prizm-icon-16.chevrons-unfold-more-vertical:before {
  content: '';
}
.prizm-icon-16.chevrons-up:before {
  content: '';
}
.prizm-icon-16.date-access-alarm:before {
  content: '';
}
.prizm-icon-16.date-asterisk:before {
  content: '';
}
.prizm-icon-16.date-calendar-blank:before {
  content: '';
}
.prizm-icon-16.date-calendar-check:before {
  content: '';
}
.prizm-icon-16.date-calendar-clock:before {
  content: '';
}
.prizm-icon-16.date-calendar-plus:before {
  content: '';
}
.prizm-icon-16.date-calendar-question:before {
  content: '';
}
.prizm-icon-16.date-calendar-range:before {
  content: '';
}
.prizm-icon-16.date-calendar-remove:before {
  content: '';
}
.prizm-icon-16.date-calendar-text:before {
  content: '';
}
.prizm-icon-16.date-calendar-today:before {
  content: '';
}
.prizm-icon-16.date-calendar:before {
  content: '';
}
.prizm-icon-16.date-clock-alert:before {
  content: '';
}
.prizm-icon-16.date-clock-end:before {
  content: '';
}
.prizm-icon-16.date-clock-in:before {
  content: '';
}
.prizm-icon-16.date-clock-out:before {
  content: '';
}
.prizm-icon-16.date-clock-start:before {
  content: '';
}
.prizm-icon-16.date-clock:before {
  content: '';
}
.prizm-icon-16.date-day:before {
  content: '';
}
.prizm-icon-16.date-history:before {
  content: '';
}
.prizm-icon-16.date-hour:before {
  content: '';
}
.prizm-icon-16.date-inform-add:before {
  content: '';
}
.prizm-icon-16.date-inform-delete:before {
  content: '';
}
.prizm-icon-16.date-midnight:before {
  content: '';
}
.prizm-icon-16.date-minute:before {
  content: '';
}
.prizm-icon-16.date-month:before {
  content: '';
}
.prizm-icon-16.date-now:before {
  content: '';
}
.prizm-icon-16.date-period-dropdown-list-open:before {
  content: '';
}
.prizm-icon-16.date-period-dropdown-list:before {
  content: '';
}
.prizm-icon-16.date-range:before {
  content: '';
}
.prizm-icon-16.date-second:before {
  content: '';
}
.prizm-icon-16.date-time-add-2:before {
  content: '';
}
.prizm-icon-16.date-time-add:before {
  content: '';
}
.prizm-icon-16.date-time-remove-2:before {
  content: '';
}
.prizm-icon-16.date-time-remove:before {
  content: '';
}
.prizm-icon-16.date-timer-sand-empty:before {
  content: '';
}
.prizm-icon-16.date-timer:before {
  content: '';
}
.prizm-icon-16.date-update:before {
  content: '';
}
.prizm-icon-16.date-year:before {
  content: '';
}
.prizm-icon-16.delete-do-not-disturb:before {
  content: '';
}
.prizm-icon-16.delete-drop:before {
  content: '';
}
.prizm-icon-16.delete-empty:before {
  content: '';
}
.prizm-icon-16.delete-forever:before {
  content: '';
}
.prizm-icon-16.delete-label-delete:before {
  content: '';
}
.prizm-icon-16.delete-minus-circle:before {
  content: '';
}
.prizm-icon-16.delete-minus-square:before {
  content: '';
}
.prizm-icon-16.delete-minus:before {
  content: '';
}
.prizm-icon-16.delete-restore:before {
  content: '';
}
.prizm-icon-16.delete:before {
  content: '';
}
.prizm-icon-16.devices-android-debug-bridge:before {
  content: '';
}
.prizm-icon-16.devices-android:before {
  content: '';
}
.prizm-icon-16.devices-camera-front:before {
  content: '';
}
.prizm-icon-16.devices-cellphone-basic:before {
  content: '';
}
.prizm-icon-16.devices-cellphone-link-off:before {
  content: '';
}
.prizm-icon-16.devices-cellphone-link:before {
  content: '';
}
.prizm-icon-16.devices-cellphone-wireless:before {
  content: '';
}
.prizm-icon-16.devices-cellphone:before {
  content: '';
}
.prizm-icon-16.devices-desktop-tower:before {
  content: '';
}
.prizm-icon-16.devices-hard-drive-outline:before {
  content: '';
}
.prizm-icon-16.devices-headphones:before {
  content: '';
}
.prizm-icon-16.devices-input-device:before {
  content: '';
}
.prizm-icon-16.devices-laptop-off:before {
  content: '';
}
.prizm-icon-16.devices-laptop:before {
  content: '';
}
.prizm-icon-16.devices-monitor:before {
  content: '';
}
.prizm-icon-16.devices-printer-dropdown-list:before {
  content: '';
}
.prizm-icon-16.devices-printer:before {
  content: '';
}
.prizm-icon-16.devices-sensor:before {
  content: '';
}
.prizm-icon-16.devices-speaker:before {
  content: '';
}
.prizm-icon-16.devices-tablet:before {
  content: '';
}
.prizm-icon-16.devices-touchpad:before {
  content: '';
}
.prizm-icon-16.devices-transmitter:before {
  content: '';
}
.prizm-icon-16.devices-tv:before {
  content: '';
}
.prizm-icon-16.devices-watch-variant:before {
  content: '';
}
.prizm-icon-16.editor-add-column:before {
  content: '';
}
.prizm-icon-16.editor-add-row:before {
  content: '';
}
.prizm-icon-16.editor-alfavit:before {
  content: '';
}
.prizm-icon-16.editor-attach-2:before {
  content: '';
}
.prizm-icon-16.editor-attach-file:before {
  content: '';
}
.prizm-icon-16.editor-auto:before {
  content: '';
}
.prizm-icon-16.editor-auto1:before {
  content: '';
}
.prizm-icon-16.editor-border-all:before {
  content: '';
}
.prizm-icon-16.editor-border-bottom:before {
  content: '';
}
.prizm-icon-16.editor-border-clear:before {
  content: '';
}
.prizm-icon-16.editor-border-color:before {
  content: '';
}
.prizm-icon-16.editor-border-horizontal:before {
  content: '';
}
.prizm-icon-16.editor-border-inner:before {
  content: '';
}
.prizm-icon-16.editor-border-left:before {
  content: '';
}
.prizm-icon-16.editor-border-outer:before {
  content: '';
}
.prizm-icon-16.editor-border-right:before {
  content: '';
}
.prizm-icon-16.editor-border-style:before {
  content: '';
}
.prizm-icon-16.editor-border-top:before {
  content: '';
}
.prizm-icon-16.editor-border-vertical:before {
  content: '';
}
.prizm-icon-16.editor-brace-left:before {
  content: '';
}
.prizm-icon-16.editor-brace-right:before {
  content: '';
}
.prizm-icon-16.editor-copy-column:before {
  content: '';
}
.prizm-icon-16.editor-copy-drop:before {
  content: '';
}
.prizm-icon-16.editor-copy-row:before {
  content: '';
}
.prizm-icon-16.editor-copy:before {
  content: '';
}
.prizm-icon-16.editor-crop:before {
  content: '';
}
.prizm-icon-16.editor-cross-out:before {
  content: '';
}
.prizm-icon-16.editor-curly-brace-left:before {
  content: '';
}
.prizm-icon-16.editor-curly-brace-right:before {
  content: '';
}
.prizm-icon-16.editor-delete-column:before {
  content: '';
}
.prizm-icon-16.editor-delete-row:before {
  content: '';
}
.prizm-icon-16.editor-disconnect-link:before {
  content: '';
}
.prizm-icon-16.editor-discrete-set-1:before {
  content: '';
}
.prizm-icon-16.editor-discrete-set:before {
  content: '';
}
.prizm-icon-16.editor-division:before {
  content: '';
}
.prizm-icon-16.editor-dots:before {
  content: '';
}
.prizm-icon-16.editor-down-1:before {
  content: '';
}
.prizm-icon-16.editor-down:before {
  content: '';
}
.prizm-icon-16.editor-drag-and-drop:before {
  content: '';
}
.prizm-icon-16.editor-dropdown-list-open:before {
  content: '';
}
.prizm-icon-16.editor-dropdown-list:before {
  content: '';
}
.prizm-icon-16.editor-droplet:before {
  content: '';
}
.prizm-icon-16.editor-format-align-center:before {
  content: '';
}
.prizm-icon-16.editor-format-align-justify:before {
  content: '';
}
.prizm-icon-16.editor-format-align-left:before {
  content: '';
}
.prizm-icon-16.editor-format-align-right:before {
  content: '';
}
.prizm-icon-16.editor-format-bold:before {
  content: '';
}
.prizm-icon-16.editor-format-color-fill:before {
  content: '';
}
.prizm-icon-16.editor-format-color-reset:before {
  content: '';
}
.prizm-icon-16.editor-format-color-text:before {
  content: '';
}
.prizm-icon-16.editor-format-indent:before {
  content: '';
}
.prizm-icon-16.editor-format-indent1:before {
  content: '';
}
.prizm-icon-16.editor-format-italic:before {
  content: '';
}
.prizm-icon-16.editor-format-list-bulleted:before {
  content: '';
}
.prizm-icon-16.editor-format-list-numbered:before {
  content: '';
}
.prizm-icon-16.editor-format-textdirection-l-to-r:before {
  content: '';
}
.prizm-icon-16.editor-format-underlined:before {
  content: '';
}
.prizm-icon-16.editor-formula:before {
  content: '';
}
.prizm-icon-16.editor-functions:before {
  content: '';
}
.prizm-icon-16.editor-group-choose:before {
  content: '';
}
.prizm-icon-16.editor-group-dropdown:before {
  content: '';
}
.prizm-icon-16.editor-group-panel:before {
  content: '';
}
.prizm-icon-16.editor-group:before {
  content: '';
}
.prizm-icon-16.editor-hash:before {
  content: '';
}
.prizm-icon-16.editor-horizontal-align-center-:before {
  content: '';
}
.prizm-icon-16.editor-insert-emoticon-sad:before {
  content: '';
}
.prizm-icon-16.editor-insert-emoticon:before {
  content: '';
}
.prizm-icon-16.editor-insert-link-add:before {
  content: '';
}
.prizm-icon-16.editor-insert-link-delete:before {
  content: '';
}
.prizm-icon-16.editor-insert-link:before {
  content: '';
}
.prizm-icon-16.editor-line-height:before {
  content: '';
}
.prizm-icon-16.editor-link:before {
  content: '';
}
.prizm-icon-16.editor-list-dushed-2:before {
  content: '';
}
.prizm-icon-16.editor-merge-type:before {
  content: '';
}
.prizm-icon-16.editor-mini:before {
  content: '';
}
.prizm-icon-16.editor-minus:before {
  content: '';
}
.prizm-icon-16.editor-mode:before {
  content: '';
}
.prizm-icon-16.editor-multiplication:before {
  content: '';
}
.prizm-icon-16.editor-panel-down:before {
  content: '';
}
.prizm-icon-16.editor-panel-left:before {
  content: '';
}
.prizm-icon-16.editor-panel-right:before {
  content: '';
}
.prizm-icon-16.editor-panel-top:before {
  content: '';
}
.prizm-icon-16.editor-percent-1:before {
  content: '';
}
.prizm-icon-16.editor-percent:before {
  content: '';
}
.prizm-icon-16.editor-plus:before {
  content: '';
}
.prizm-icon-16.editor-right-1:before {
  content: '';
}
.prizm-icon-16.editor-right:before {
  content: '';
}
.prizm-icon-16.editor-screen:before {
  content: '';
}
.prizm-icon-16.editor-search-hierarchy:before {
  content: '';
}
.prizm-icon-16.editor-search-tags-words:before {
  content: '';
}
.prizm-icon-16.editor-sort-asc:before {
  content: '';
}
.prizm-icon-16.editor-subskrit-text:before {
  content: '';
}
.prizm-icon-16.editor-superskrit-text:before {
  content: '';
}
.prizm-icon-16.editor-table-lookup:before {
  content: '';
}
.prizm-icon-16.editor-table:before {
  content: '';
}
.prizm-icon-16.editor-tag:before {
  content: '';
}
.prizm-icon-16.editor-template:before {
  content: '';
}
.prizm-icon-16.editor-title:before {
  content: '';
}
.prizm-icon-16.editor-transpose-right:before {
  content: '';
}
.prizm-icon-16.editor-transpose-top:before {
  content: '';
}
.prizm-icon-16.editor-transpose:before {
  content: '';
}
.prizm-icon-16.editor-ungroup:before {
  content: '';
}
.prizm-icon-16.editor-vertical-align-bottom:before {
  content: '';
}
.prizm-icon-16.editor-vertical-align-center-2:before {
  content: '';
}
.prizm-icon-16.editor-vertical-align-center:before {
  content: '';
}
.prizm-icon-16.editor-vertical-align-top:before {
  content: '';
}
.prizm-icon-16.editor-window:before {
  content: '';
}
.prizm-icon-16.editor-wrap-text:before {
  content: '';
}
.prizm-icon-16.export-files-csv:before {
  content: '';
}
.prizm-icon-16.export-files-pdf:before {
  content: '';
}
.prizm-icon-16.export-files-sap:before {
  content: '';
}
.prizm-icon-16.export-files-xlsx:before {
  content: '';
}
.prizm-icon-16.files-alarm:before {
  content: '';
}
.prizm-icon-16.files-archive:before {
  content: '';
}
.prizm-icon-16.files-box:before {
  content: '';
}
.prizm-icon-16.files-cloud-check:before {
  content: '';
}
.prizm-icon-16.files-cloud:before {
  content: '';
}
.prizm-icon-16.files-cloup-upload:before {
  content: '';
}
.prizm-icon-16.files-collection-2:before {
  content: '';
}
.prizm-icon-16.files-confirmation:before {
  content: '';
}
.prizm-icon-16.files-correct:before {
  content: '';
}
.prizm-icon-16.files-description-properties:before {
  content: '';
}
.prizm-icon-16.files-document-image:before {
  content: '';
}
.prizm-icon-16.files-document-text:before {
  content: '';
}
.prizm-icon-16.files-excel-box:before {
  content: '';
}
.prizm-icon-16.files-export-1:before {
  content: '';
}
.prizm-icon-16.files-export-arr:before {
  content: '';
}
.prizm-icon-16.files-export:before {
  content: '';
}
.prizm-icon-16.files-file-close:before {
  content: '';
}
.prizm-icon-16.files-file-doc:before {
  content: '';
}
.prizm-icon-16.files-file-docx:before {
  content: '';
}
.prizm-icon-16.files-file-open:before {
  content: '';
}
.prizm-icon-16.files-file-pdf:before {
  content: '';
}
.prizm-icon-16.files-file-play:before {
  content: '';
}
.prizm-icon-16.files-file-ppt:before {
  content: '';
}
.prizm-icon-16.files-file-pptx:before {
  content: '';
}
.prizm-icon-16.files-file-remove:before {
  content: '';
}
.prizm-icon-16.files-file-txt:before {
  content: '';
}
.prizm-icon-16.files-file-video:before {
  content: '';
}
.prizm-icon-16.files-file-xls:before {
  content: '';
}
.prizm-icon-16.files-file-xlsx:before {
  content: '';
}
.prizm-icon-16.files-file:before {
  content: '';
}
.prizm-icon-16.files-find-1:before {
  content: '';
}
.prizm-icon-16.files-find:before {
  content: '';
}
.prizm-icon-16.files-folder-1:before {
  content: '';
}
.prizm-icon-16.files-folder-add:before {
  content: '';
}
.prizm-icon-16.files-folder-close:before {
  content: '';
}
.prizm-icon-16.files-folder-delete:before {
  content: '';
}
.prizm-icon-16.files-folder-mode:before {
  content: '';
}
.prizm-icon-16.files-folder-open-1:before {
  content: '';
}
.prizm-icon-16.files-folder-open:before {
  content: '';
}
.prizm-icon-16.files-folder-remove:before {
  content: '';
}
.prizm-icon-16.files-folder-root-add:before {
  content: '';
}
.prizm-icon-16.files-folder-root-remove:before {
  content: '';
}
.prizm-icon-16.files-folder:before {
  content: '';
}
.prizm-icon-16.files-import-1:before {
  content: '';
}
.prizm-icon-16.files-import-arr:before {
  content: '';
}
.prizm-icon-16.files-import:before {
  content: '';
}
.prizm-icon-16.files-in-group:before {
  content: '';
}
.prizm-icon-16.files-incorrect:before {
  content: '';
}
.prizm-icon-16.files-indicator:before {
  content: '';
}
.prizm-icon-16.files-loader:before {
  content: '';
}
.prizm-icon-16.files-method:before {
  content: '';
}
.prizm-icon-16.files-mnemo:before {
  content: '';
}
.prizm-icon-16.files-mode:before {
  content: '';
}
.prizm-icon-16.files-multi:before {
  content: '';
}
.prizm-icon-16.files-norm:before {
  content: '';
}
.prizm-icon-16.files-plus:before {
  content: '';
}
.prizm-icon-16.files-powerpoint:before {
  content: '';
}
.prizm-icon-16.files-specification:before {
  content: '';
}
.prizm-icon-16.files-view-document:before {
  content: '';
}
.prizm-icon-16.files-word-box:before {
  content: '';
}
.prizm-icon-16.files-просмотр:before {
  content: '';
}
.prizm-icon-16.import-files-1с:before {
  content: '';
}
.prizm-icon-16.import-files-csv:before {
  content: '';
}
.prizm-icon-16.import-files-pdf:before {
  content: '';
}
.prizm-icon-16.import-files-xlsx:before {
  content: '';
}
.prizm-icon-16.finance-barcode:before {
  content: '';
}
.prizm-icon-16.finance-calculator-auto:before {
  content: '';
}
.prizm-icon-16.finance-calculator:before {
  content: '';
}
.prizm-icon-16.finance-credit-card-of:before {
  content: '';
}
.prizm-icon-16.finance-credit-card-plus:before {
  content: '';
}
.prizm-icon-16.finance-credit-card:before {
  content: '';
}
.prizm-icon-16.finance-currency-btc:before {
  content: '';
}
.prizm-icon-16.finance-currency-usd-off:before {
  content: '';
}
.prizm-icon-16.finance-currency-usd:before {
  content: '';
}
.prizm-icon-16.finance-qr-code-auto-1:before {
  content: '';
}
.prizm-icon-16.finance-qr-code-auto-2:before {
  content: '';
}
.prizm-icon-16.finance-qr-code-auto-3:before {
  content: '';
}
.prizm-icon-16.finance-qr-code-search:before {
  content: '';
}
.prizm-icon-16.finance-qr-code:before {
  content: '';
}
.prizm-icon-16.finance-scanner:before {
  content: '';
}
.prizm-icon-16.finance-wallet:before {
  content: '';
}
.prizm-icon-16.location-compass:before {
  content: '';
}
.prizm-icon-16.location-crosshairs-gps:before {
  content: '';
}
.prizm-icon-16.location-crosshairs:before {
  content: '';
}
.prizm-icon-16.location-language:before {
  content: '';
}
.prizm-icon-16.location-localization-down-list:before {
  content: '';
}
.prizm-icon-16.location-map-2:before {
  content: '';
}
.prizm-icon-16.location-map-marker-date-1:before {
  content: '';
}
.prizm-icon-16.location-map-marker-date-2:before {
  content: '';
}
.prizm-icon-16.location-map-marker-minus:before {
  content: '';
}
.prizm-icon-16.location-map-marker-off:before {
  content: '';
}
.prizm-icon-16.location-map-marker-plus:before {
  content: '';
}
.prizm-icon-16.location-map-marker:before {
  content: '';
}
.prizm-icon-16.location-map:before {
  content: '';
}
.prizm-icon-16.location-navigation-1:before {
  content: '';
}
.prizm-icon-16.location-navigation:before {
  content: '';
}
.prizm-icon-16.location-person-pin-circle:before {
  content: '';
}
.prizm-icon-16.location-pin-1:before {
  content: '';
}
.prizm-icon-16.location-pin-2:before {
  content: '';
}
.prizm-icon-16.location-pin:before {
  content: '';
}
.prizm-icon-16.messaging-add-comment:before {
  content: '';
}
.prizm-icon-16.messaging-alert:before {
  content: '';
}
.prizm-icon-16.messaging-at:before {
  content: '';
}
.prizm-icon-16.messaging-emai-down-list:before {
  content: '';
}
.prizm-icon-16.messaging-emai:before {
  content: '';
}
.prizm-icon-16.messaging-email-open:before {
  content: '';
}
.prizm-icon-16.messaging-forward:before {
  content: '';
}
.prizm-icon-16.messaging-inbox:before {
  content: '';
}
.prizm-icon-16.messaging-message-circle:before {
  content: '';
}
.prizm-icon-16.messaging-message-off:before {
  content: '';
}
.prizm-icon-16.messaging-message:before {
  content: '';
}
.prizm-icon-16.messaging-processing:before {
  content: '';
}
.prizm-icon-16.messaging-question-ask:before {
  content: '';
}
.prizm-icon-16.messaging-reply:before {
  content: '';
}
.prizm-icon-16.messaging-send:before {
  content: '';
}
.prizm-icon-16.messaging-text:before {
  content: '';
}
.prizm-icon-16.network-close:before {
  content: '';
}
.prizm-icon-16.network-cloud-download:before {
  content: '';
}
.prizm-icon-16.network-cloud-upload:before {
  content: '';
}
.prizm-icon-16.network-database-administration:before {
  content: '';
}
.prizm-icon-16.network-database-delete:before {
  content: '';
}
.prizm-icon-16.network-database-json:before {
  content: '';
}
.prizm-icon-16.network-database-minus:before {
  content: '';
}
.prizm-icon-16.network-database-plus:before {
  content: '';
}
.prizm-icon-16.network-database-search:before {
  content: '';
}
.prizm-icon-16.network-database-sql:before {
  content: '';
}
.prizm-icon-16.network-database:before {
  content: '';
}
.prizm-icon-16.network-download:before {
  content: '';
}
.prizm-icon-16.network-ethernet-open:before {
  content: '';
}
.prizm-icon-16.network-external-link:before {
  content: '';
}
.prizm-icon-16.network-lan-connect:before {
  content: '';
}
.prizm-icon-16.network-lan-disconnect:before {
  content: '';
}
.prizm-icon-16.network-lan-pending:before {
  content: '';
}
.prizm-icon-16.network-lan:before {
  content: '';
}
.prizm-icon-16.network-layers:before {
  content: '';
}
.prizm-icon-16.network-library:before {
  content: '';
}
.prizm-icon-16.network-minus:before {
  content: '';
}
.prizm-icon-16.network-no-layers:before {
  content: '';
}
.prizm-icon-16.network-object:before {
  content: '';
}
.prizm-icon-16.network-plus:before {
  content: '';
}
.prizm-icon-16.network-scheme-2:before {
  content: '';
}
.prizm-icon-16.network-scheme:before {
  content: '';
}
.prizm-icon-16.network-security:before {
  content: '';
}
.prizm-icon-16.network-server-1:before {
  content: '';
}
.prizm-icon-16.network-server-network-off:before {
  content: '';
}
.prizm-icon-16.network-server-network:before {
  content: '';
}
.prizm-icon-16.network-server-off:before {
  content: '';
}
.prizm-icon-16.network-server-security:before {
  content: '';
}
.prizm-icon-16.network-server:before {
  content: '';
}
.prizm-icon-16.network-upload:before {
  content: '';
}
.prizm-icon-16.phone-call-missed:before {
  content: '';
}
.prizm-icon-16.phone-in-talk:before {
  content: '';
}
.prizm-icon-16.phone-minus:before {
  content: '';
}
.prizm-icon-16.phone-missed:before {
  content: '';
}
.prizm-icon-16.phone-off:before {
  content: '';
}
.prizm-icon-16.phone-paused:before {
  content: '';
}
.prizm-icon-16.phone-plus:before {
  content: '';
}
.prizm-icon-16.phone:before {
  content: '';
}
.prizm-icon-16.player-loop:before {
  content: '';
}
.prizm-icon-16.player-pause:before {
  content: '';
}
.prizm-icon-16.player-play-back:before {
  content: '';
}
.prizm-icon-16.player-play:before {
  content: '';
}
.prizm-icon-16.player-skip-backward:before {
  content: '';
}
.prizm-icon-16.player-skip-forward:before {
  content: '';
}
.prizm-icon-16.player-skip-next-circle-outline:before {
  content: '';
}
.prizm-icon-16.player-skip-previous:before {
  content: '';
}
.prizm-icon-16.player-stop:before {
  content: '';
}
.prizm-icon-16.player-voicemail:before {
  content: '';
}
.prizm-icon-16.burn:before {
  content: '';
}
.prizm-icon-16.cargo-box-2:before {
  content: '';
}
.prizm-icon-16.cargo-box:before {
  content: '';
}
.prizm-icon-16.cargo-container:before {
  content: '';
}
.prizm-icon-16.cargo:before {
  content: '';
}
.prizm-icon-16.fire:before {
  content: '';
}
.prizm-icon-16.freeze:before {
  content: '';
}
.prizm-icon-16.gas:before {
  content: '';
}
.prizm-icon-16.loading:before {
  content: '';
}
.prizm-icon-16.poi-achive-Mir:before {
  content: '';
}
.prizm-icon-16.poi-article-1:before {
  content: '';
}
.prizm-icon-16.poi-barley:before {
  content: '';
}
.prizm-icon-16.poi-bdrv-administration:before {
  content: '';
}
.prizm-icon-16.poi-boiler-hot-water-gas-and-oil:before {
  content: '';
}
.prizm-icon-16.poi-cancel sample reject:before {
  content: '';
}
.prizm-icon-16.poi-coal:before {
  content: '';
}
.prizm-icon-16.poi-crane:before {
  content: '';
}
.prizm-icon-16.poi-difference:before {
  content: '';
}
.prizm-icon-16.poi-early-version:before {
  content: '';
}
.prizm-icon-16.poi-ecopost-circle:before {
  content: '';
}
.prizm-icon-16.poi-ecopost-rectangle:before {
  content: '';
}
.prizm-icon-16.poi-electrics:before {
  content: '';
}
.prizm-icon-16.poi-factory pipe:before {
  content: '';
}
.prizm-icon-16.poi-factory-pipes:before {
  content: '';
}
.prizm-icon-16.poi-factory:before {
  content: '';
}
.prizm-icon-16.poi-fan:before {
  content: '';
}
.prizm-icon-16.poi-flask-1:before {
  content: '';
}
.prizm-icon-16.poi-flask-2:before {
  content: '';
}
.prizm-icon-16.poi-flask-3:before {
  content: '';
}
.prizm-icon-16.poi-flask-4:before {
  content: '';
}
.prizm-icon-16.poi-flask-5:before {
  content: '';
}
.prizm-icon-16.poi-fuel-oil-tank:before {
  content: '';
}
.prizm-icon-16.poi-fuel:before {
  content: '';
}
.prizm-icon-16.poi-gas-balloon:before {
  content: '';
}
.prizm-icon-16.poi-gas-monitoring:before {
  content: '';
}
.prizm-icon-16.poi-gas-station:before {
  content: '';
}
.prizm-icon-16.poi-gear-cooling:before {
  content: '';
}
.prizm-icon-16.poi-generator:before {
  content: '';
}
.prizm-icon-16.poi-git-lab:before {
  content: '';
}
.prizm-icon-16.poi-heat-exchanger:before {
  content: '';
}
.prizm-icon-16.poi-heat-recovery-boiler:before {
  content: '';
}
.prizm-icon-16.poi-high-speed-reduction-cooling:before {
  content: '';
}
.prizm-icon-16.poi-lng-storage:before {
  content: '';
}
.prizm-icon-16.poi-medicine:before {
  content: '';
}
.prizm-icon-16.poi-ni-fi:before {
  content: '';
}
.prizm-icon-16.poi-nuclear-station:before {
  content: '';
}
.prizm-icon-16.poi-oil-factory:before {
  content: '';
}
.prizm-icon-16.poi-oil-field:before {
  content: '';
}
.prizm-icon-16.poi-oil-storage:before {
  content: '';
}
.prizm-icon-16.poi-oil-tank:before {
  content: '';
}
.prizm-icon-16.poi-owl:before {
  content: '';
}
.prizm-icon-16.poi-pipe-sensor:before {
  content: '';
}
.prizm-icon-16.poi-pipe-valve:before {
  content: '';
}
.prizm-icon-16.poi-pipeline:before {
  content: '';
}
.prizm-icon-16.poi-plus:before {
  content: '';
}
.prizm-icon-16.poi-press:before {
  content: '';
}
.prizm-icon-16.poi-pump-drop:before {
  content: '';
}
.prizm-icon-16.poi-pump:before {
  content: '';
}
.prizm-icon-16.poi-respirator-2-1:before {
  content: '';
}
.prizm-icon-16.poi-respirator-2:before {
  content: '';
}
.prizm-icon-16.poi-respirator-3:before {
  content: '';
}
.prizm-icon-16.poi-respirator-4:before {
  content: '';
}
.prizm-icon-16.poi-respirator-5:before {
  content: '';
}
.prizm-icon-16.poi-respirator:before {
  content: '';
}
.prizm-icon-16.poi-separator:before {
  content: '';
}
.prizm-icon-16.poi-steam-boiler:before {
  content: '';
}
.prizm-icon-16.poi-steam-turbine:before {
  content: '';
}
.prizm-icon-16.poi-steam:before {
  content: '';
}
.prizm-icon-16.poi-strit:before {
  content: '';
}
.prizm-icon-16.poi-tank:before {
  content: '';
}
.prizm-icon-16.poi-water-heater:before {
  content: '';
}
.prizm-icon-16.poi-water-pump:before {
  content: '';
}
.prizm-icon-16.product-grade-according-to-specification:before {
  content: '';
}
.prizm-icon-16.snowflake:before {
  content: '';
}
.prizm-icon-16.selection-check-all:before {
  content: '';
}
.prizm-icon-16.selection-check-simple:before {
  content: '';
}
.prizm-icon-16.selection-checkbox-blank-circle:before {
  content: '';
}
.prizm-icon-16.selection-checkbox-blank:before {
  content: '';
}
.prizm-icon-16.selection-checkbox-marked-circle-chanel:before {
  content: '';
}
.prizm-icon-16.selection-checkbox-marked-circle:before {
  content: '';
}
.prizm-icon-16.selection-checked-1:before {
  content: '';
}
.prizm-icon-16.selection-checked-2:before {
  content: '';
}
.prizm-icon-16.selection-checked-empty:before {
  content: '';
}
.prizm-icon-16.selection-checked:before {
  content: '';
}
.prizm-icon-16.selection-choice:before {
  content: '';
}
.prizm-icon-16.selection-halfchoice:before {
  content: '';
}
.prizm-icon-16.selection-process:before {
  content: '';
}
.prizm-icon-16.selection-radio-off:before {
  content: '';
}
.prizm-icon-16.selection-radio-on:before {
  content: '';
}
.prizm-icon-16.selection-success:before {
  content: '';
}
.prizm-icon-16.selection-toggle-left:before {
  content: '';
}
.prizm-icon-16.selection-toggle-right:before {
  content: '';
}
.prizm-icon-16.selection-unchoice:before {
  content: '';
}
.prizm-icon-16.settings-2:before {
  content: '';
}
.prizm-icon-16.settings-apps:before {
  content: '';
}
.prizm-icon-16.settings-more-horiz:before {
  content: '';
}
.prizm-icon-16.settings-more-vert:before {
  content: '';
}
.prizm-icon-16.settings-power:before {
  content: '';
}
.prizm-icon-16.settings-tune-vertical:before {
  content: '';
}
.prizm-icon-16.settings-tune:before {
  content: '';
}
.prizm-icon-16.settings:before {
  content: '';
}
.prizm-icon-16.shapes-Key:before {
  content: '';
}
.prizm-icon-16.shapes-Star:before {
  content: '';
}
.prizm-icon-16.shapes-animation:before {
  content: '';
}
.prizm-icon-16.shapes-arrange-bring-forward:before {
  content: '';
}
.prizm-icon-16.shapes-arrange-send-backward:before {
  content: '';
}
.prizm-icon-16.shapes-arrange-send-to-back:before {
  content: '';
}
.prizm-icon-16.shapes-box-shadow:before {
  content: '';
}
.prizm-icon-16.shapes-checkbox-intermediate:before {
  content: '';
}
.prizm-icon-16.shapes-checkerboard:before {
  content: '';
}
.prizm-icon-16.shapes-collage:before {
  content: '';
}
.prizm-icon-16.shapes-collector:before {
  content: '';
}
.prizm-icon-16.shapes-cube-3:before {
  content: '';
}
.prizm-icon-16.shapes-cube-outline:before {
  content: '';
}
.prizm-icon-16.shapes-cube:before {
  content: '';
}
.prizm-icon-16.shapes-drawing-box:before {
  content: '';
}
.prizm-icon-16.shapes-drawing-outline:before {
  content: '';
}
.prizm-icon-16.shapes-drawing:before {
  content: '';
}
.prizm-icon-16.shapes-hexagon-fill:before {
  content: '';
}
.prizm-icon-16.shapes-hexagon:before {
  content: '';
}
.prizm-icon-16.shapes-layers-off:before {
  content: '';
}
.prizm-icon-16.shapes-layers:before {
  content: '';
}
.prizm-icon-16.shapes-nest-protec:before {
  content: '';
}
.prizm-icon-16.shapes-nut:before {
  content: '';
}
.prizm-icon-16.shapes-ontology-2:before {
  content: '';
}
.prizm-icon-16.shapes-ontology:before {
  content: '';
}
.prizm-icon-16.shapes-pentagon-fill:before {
  content: '';
}
.prizm-icon-16.shapes-pentagon:before {
  content: '';
}
.prizm-icon-16.shapes-rhombus-fill:before {
  content: '';
}
.prizm-icon-16.shapes-rhombus:before {
  content: '';
}
.prizm-icon-16.shapes-shape-1-f:before {
  content: '';
}
.prizm-icon-16.shapes-shape-1:before {
  content: '';
}
.prizm-icon-16.shapes-shape-2-f:before {
  content: '';
}
.prizm-icon-16.shapes-shape-2:before {
  content: '';
}
.prizm-icon-16.shapes-shape-3-f:before {
  content: '';
}
.prizm-icon-16.shapes-shape-3:before {
  content: '';
}
.prizm-icon-16.shapes-shape-4-f:before {
  content: '';
}
.prizm-icon-16.shapes-shape-4:before {
  content: '';
}
.prizm-icon-16.shapes-shape-5-f:before {
  content: '';
}
.prizm-icon-16.shapes-shape-5:before {
  content: '';
}
.prizm-icon-16.shapes-shape-6:before {
  content: '';
}
.prizm-icon-16.shapes-shape-7:before {
  content: '';
}
.prizm-icon-16.shapes-shape-fill:before {
  content: '';
}
.prizm-icon-16.shapes-shape-plus-1:before {
  content: '';
}
.prizm-icon-16.shapes-shape-plus:before {
  content: '';
}
.prizm-icon-16.shapes-shape:before {
  content: '';
}
.prizm-icon-16.shapes-square-inc:before {
  content: '';
}
.prizm-icon-16.shapes-triangle-fill:before {
  content: '';
}
.prizm-icon-16.shapes-triangle:before {
  content: '';
}
.prizm-icon-16.shopping-box:before {
  content: '';
}
.prizm-icon-16.shopping-cart:before {
  content: '';
}
.prizm-icon-16.shopping-gift:before {
  content: '';
}
.prizm-icon-16.shopping:before {
  content: '';
}
.prizm-icon-16.signal-access-point:before {
  content: '';
}
.prizm-icon-16.signal-bluetooth:before {
  content: '';
}
.prizm-icon-16.signal-cast:before {
  content: '';
}
.prizm-icon-16.signal-off:before {
  content: '';
}
.prizm-icon-16.signal-radio-tower:before {
  content: '';
}
.prizm-icon-16.signal-rss-box:before {
  content: '';
}
.prizm-icon-16.signal-wifi-off:before {
  content: '';
}
.prizm-icon-16.signal-wifi:before {
  content: '';
}
.prizm-icon-16.signal:before {
  content: '';
}
.prizm-icon-16.social-browser:before {
  content: '';
}
.prizm-icon-16.social-globe-2:before {
  content: '';
}
.prizm-icon-16.social-heart-fill:before {
  content: '';
}
.prizm-icon-16.social-heart:before {
  content: '';
}
.prizm-icon-16.social-home-breadcrumbs:before {
  content: '';
}
.prizm-icon-16.social-home:before {
  content: '';
}
.prizm-icon-16.social-instagram:before {
  content: '';
}
.prizm-icon-16.social-moon:before {
  content: '';
}
.prizm-icon-16.social-share:before {
  content: '';
}
.prizm-icon-16.social-star-fill:before {
  content: '';
}
.prizm-icon-16.social-star-half:before {
  content: '';
}
.prizm-icon-16.social-star:before {
  content: '';
}
.prizm-icon-16.social-sun:before {
  content: '';
}
.prizm-icon-16.social-tab:before {
  content: '';
}
.prizm-icon-16.social-telegram:before {
  content: '';
}
.prizm-icon-16.social-thumb-down:before {
  content: '';
}
.prizm-icon-16.social-thumb-up:before {
  content: '';
}
.prizm-icon-16.sort-abc-ru-up:before {
  content: '';
}
.prizm-icon-16.sort-abc-ru:before {
  content: '';
}
.prizm-icon-16.sort-alphabetical-up:before {
  content: '';
}
.prizm-icon-16.sort-alphabetical:before {
  content: '';
}
.prizm-icon-16.sort-apps:before {
  content: '';
}
.prizm-icon-16.sort-asc-arr:before {
  content: '';
}
.prizm-icon-16.sort-desc-arr:before {
  content: '';
}
.prizm-icon-16.sort-eye-off-2:before {
  content: '';
}
.prizm-icon-16.sort-eye-off:before {
  content: '';
}
.prizm-icon-16.sort-eye:before {
  content: '';
}
.prizm-icon-16.sort-filter-remove:before {
  content: '';
}
.prizm-icon-16.sort-filter-setting:before {
  content: '';
}
.prizm-icon-16.sort-filter:before {
  content: '';
}
.prizm-icon-16.sort-funnel-drop-down-list-open:before {
  content: '';
}
.prizm-icon-16.sort-funnel-drop-down-list:before {
  content: '';
}
.prizm-icon-16.sort-look-into-the-buffer:before {
  content: '';
}
.prizm-icon-16.sort-maximize:before {
  content: '';
}
.prizm-icon-16.sort-numeric:before {
  content: '';
}
.prizm-icon-16.sort-real-size:before {
  content: '';
}
.prizm-icon-16.sort-search-mistake:before {
  content: '';
}
.prizm-icon-16.sort-time-down:before {
  content: '';
}
.prizm-icon-16.sort-time-up:before {
  content: '';
}
.prizm-icon-16.sort-version:before {
  content: '';
}
.prizm-icon-16.sort-zoom-in-dropdown:before {
  content: '';
}
.prizm-icon-16.sort-zoom-in:before {
  content: '';
}
.prizm-icon-16.sort-zoom-out:before {
  content: '';
}
.prizm-icon-16.tools-auto-fix:before {
  content: '';
}
.prizm-icon-16.tools-award:before {
  content: '';
}
.prizm-icon-16.tools-briefcase:before {
  content: '';
}
.prizm-icon-16.tools-broom:before {
  content: '';
}
.prizm-icon-16.tools-brush:before {
  content: '';
}
.prizm-icon-16.tools-content-cut:before {
  content: '';
}
.prizm-icon-16.tools-content-save-as:before {
  content: '';
}
.prizm-icon-16.tools-content-save:before {
  content: '';
}
.prizm-icon-16.tools-content-unsave:before {
  content: '';
}
.prizm-icon-16.tools-earth-work:before {
  content: '';
}
.prizm-icon-16.tools-eraser:before {
  content: '';
}
.prizm-icon-16.tools-eyedropper:before {
  content: '';
}
.prizm-icon-16.tools-keyboard-close-1:before {
  content: '';
}
.prizm-icon-16.tools-keyboard-close:before {
  content: '';
}
.prizm-icon-16.tools-keyboard:before {
  content: '';
}
.prizm-icon-16.tools-lightbulb:before {
  content: '';
}
.prizm-icon-16.tools-npm:before {
  content: '';
}
.prizm-icon-16.tools-palette:before {
  content: '';
}
.prizm-icon-16.tools-pantone:before {
  content: '';
}
.prizm-icon-16.tools-ruler:before {
  content: '';
}
.prizm-icon-16.tools-save-all:before {
  content: '';
}
.prizm-icon-16.tools-save-back:before {
  content: '';
}
.prizm-icon-16.tools-solver:before {
  content: '';
}
.prizm-icon-16.tools-thermometer-minus:before {
  content: '';
}
.prizm-icon-16.tools-thermometer-plus:before {
  content: '';
}
.prizm-icon-16.tools-thermometer:before {
  content: '';
}
.prizm-icon-16.tools-umbrella:before {
  content: '';
}
.prizm-icon-16.transport-airplane:before {
  content: '';
}
.prizm-icon-16.transport-ambulance:before {
  content: '';
}
.prizm-icon-16.transport-truck-1:before {
  content: '';
}
.prizm-icon-16.transport-truck-2:before {
  content: '';
}
.prizm-icon-16.transport-truck-3:before {
  content: '';
}
.prizm-icon-16.transport-truck-4:before {
  content: '';
}
.prizm-icon-16.transport-truck-5:before {
  content: '';
}
.prizm-icon-16.transport-truck:before {
  content: '';
}
.prizm-icon-16.vectors-arrange-above:before {
  content: '';
}
.prizm-icon-16.vectors-arrange-below:before {
  content: '';
}
.prizm-icon-16.vectors-circle-variant:before {
  content: '';
}
.prizm-icon-16.vectors-circle:before {
  content: '';
}
.prizm-icon-16.vectors-combine-1:before {
  content: '';
}
.prizm-icon-16.vectors-combine:before {
  content: '';
}
.prizm-icon-16.vectors-curve:before {
  content: '';
}
.prizm-icon-16.vectors-default-16:before {
  content: '';
}
.prizm-icon-16.vectors-difference-ab:before {
  content: '';
}
.prizm-icon-16.vectors-difference-ba:before {
  content: '';
}
.prizm-icon-16.vectors-difference:before {
  content: '';
}
.prizm-icon-16.vectors-ellipse:before {
  content: '';
}
.prizm-icon-16.vectors-empty-16:before {
  content: '';
}
.prizm-icon-16.vectors-intersection:before {
  content: '';
}
.prizm-icon-16.vectors-line:before {
  content: '';
}
.prizm-icon-16.vectors-point:before {
  content: '';
}
.prizm-icon-16.vectors-polygon:before {
  content: '';
}
.prizm-icon-16.vectors-polyline:before {
  content: '';
}
.prizm-icon-16.vectors-radius:before {
  content: '';
}
.prizm-icon-16.vectors-rectangle:before {
  content: '';
}
.prizm-icon-16.vectors-selection:before {
  content: '';
}
.prizm-icon-16.vectors-square:before {
  content: '';
}
.prizm-icon-16.vectors-triangle:before {
  content: '';
}
.prizm-icon-16.view-agenda:before {
  content: '';
}
.prizm-icon-16.view-array:before {
  content: '';
}
.prizm-icon-16.view-carousel:before {
  content: '';
}
.prizm-icon-16.view-column:before {
  content: '';
}
.prizm-icon-16.view-component:before {
  content: '';
}
.prizm-icon-16.view-dashboard-variant:before {
  content: '';
}
.prizm-icon-16.view-dashboard:before {
  content: '';
}
.prizm-icon-16.view-day:before {
  content: '';
}
.prizm-icon-16.view-grid-outline:before {
  content: '';
}
.prizm-icon-16.view-grid:before {
  content: '';
}
.prizm-icon-16.view-headline:before {
  content: '';
}
.prizm-icon-16.view-list:before {
  content: '';
}
.prizm-icon-16.view-logo-grey-z:before {
  content: '';
}
.prizm-icon-16.view-logo-s.grey:before {
  content: '';
}
.prizm-icon-16.view-menu-2:before {
  content: '';
}
.prizm-icon-16.view-menu-arrow-down:before {
  content: '';
}
.prizm-icon-16.view-menu-arrow-left:before {
  content: '';
}
.prizm-icon-16.view-menu-arrow-right:before {
  content: '';
}
.prizm-icon-16.view-menu-arrow-up:before {
  content: '';
}
.prizm-icon-16.view-menu:before {
  content: '';
}
.prizm-icon-16.view-module:before {
  content: '';
}
.prizm-icon-16.view-quilt:before {
  content: '';
}
.prizm-icon-16.view-sequential:before {
  content: '';
}
