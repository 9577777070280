@font-face {
  font-family: prizmIcons;
  src: local('Prizm Icons'), url('prizmIcons.woff2') format('woff2'), url('prizmIcons.woff') format('woff'), url('prizmIcons.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.prizm-icon {
  font-family: prizmIcons;
  font-style: normal;
}
.prizm-icon.account-card-details:before {
  content: '';
}
.prizm-icon.account-circle:before {
  content: '';
}
.prizm-icon.account-contact-sync:before {
  content: '';
}
.prizm-icon.account-done:before {
  content: '';
}
.prizm-icon.account-down-list-open:before {
  content: '';
}
.prizm-icon.account-down-list:before {
  content: '';
}
.prizm-icon.account-edit:before {
  content: '';
}
.prizm-icon.account-group:before {
  content: '';
}
.prizm-icon.account-key:before {
  content: '';
}
.prizm-icon.account-location:before {
  content: '';
}
.prizm-icon.account-lock:before {
  content: '';
}
.prizm-icon.account-log-in:before {
  content: '';
}
.prizm-icon.account-logout:before {
  content: '';
}
.prizm-icon.account-minus:before {
  content: '';
}
.prizm-icon.account-multiple-minus:before {
  content: '';
}
.prizm-icon.account-multiple-plus:before {
  content: '';
}
.prizm-icon.account-multiple-shield:before {
  content: '';
}
.prizm-icon.account-plus:before {
  content: '';
}
.prizm-icon.account-remove:before {
  content: '';
}
.prizm-icon.account-role:before {
  content: '';
}
.prizm-icon.account-search:before {
  content: '';
}
.prizm-icon.account-setting-1:before {
  content: '';
}
.prizm-icon.account-setting:before {
  content: '';
}
.prizm-icon.account-shield-1:before {
  content: '';
}
.prizm-icon.account-shield-alarm:before {
  content: '';
}
.prizm-icon.account-shield-off:before {
  content: '';
}
.prizm-icon.account-shield:before {
  content: '';
}
.prizm-icon.account-switch:before {
  content: '';
}
.prizm-icon.account-unlock:before {
  content: '';
}
.prizm-icon.account-worker:before {
  content: '';
}
.prizm-icon.account:before {
  content: '';
}
.prizm-icon.add-alarm:before {
  content: '';
}
.prizm-icon.add-bell-plus:before {
  content: '';
}
.prizm-icon.add-bookmark-plus:before {
  content: '';
}
.prizm-icon.add-cart-plus:before {
  content: '';
}
.prizm-icon.add-circle-fill:before {
  content: '';
}
.prizm-icon.add-circle:before {
  content: '';
}
.prizm-icon.add-file-plus:before {
  content: '';
}
.prizm-icon.add-folder-plus:before {
  content: '';
}
.prizm-icon.add-label-add:before {
  content: '';
}
.prizm-icon.add-language:before {
  content: '';
}
.prizm-icon.add-library-plus:before {
  content: '';
}
.prizm-icon.add-line-add:before {
  content: '';
}
.prizm-icon.add-map-marker-plus:before {
  content: '';
}
.prizm-icon.add-phone:before {
  content: '';
}
.prizm-icon.add-plus-box-fill:before {
  content: '';
}
.prizm-icon.add-plus-box:before {
  content: '';
}
.prizm-icon.add-plus-circle-multiple:before {
  content: '';
}
.prizm-icon.add-plus-drop-down-list:before {
  content: '';
}
.prizm-icon.add-plus:before {
  content: '';
}
.prizm-icon.add-shape-square-plus:before {
  content: '';
}
.prizm-icon.alerts-alarm-light:before {
  content: '';
}
.prizm-icon.alerts-bell-fill:before {
  content: '';
}
.prizm-icon.alerts-bell-off:before {
  content: '';
}
.prizm-icon.alerts-bell-ring-fill:before {
  content: '';
}
.prizm-icon.alerts-bell-ring:before {
  content: '';
}
.prizm-icon.alerts-bell:before {
  content: '';
}
.prizm-icon.alerts-box-fill:before {
  content: '';
}
.prizm-icon.alerts-circle-fill:before {
  content: '';
}
.prizm-icon.alerts-circle-mini-1:before {
  content: '';
}
.prizm-icon.alerts-circle-mini:before {
  content: '';
}
.prizm-icon.alerts-circle:before {
  content: '';
}
.prizm-icon.alerts-decagram-fill:before {
  content: '';
}
.prizm-icon.alerts-decagram:before {
  content: '';
}
.prizm-icon.alerts-false_alerts1:before {
  content: '';
}
.prizm-icon.alerts-false_alerts2:before {
  content: '';
}
.prizm-icon.alerts-flag-fill:before {
  content: '';
}
.prizm-icon.alerts-flag:before {
  content: '';
}
.prizm-icon.alerts-help-box-fill:before {
  content: '';
}
.prizm-icon.alerts-help-box:before {
  content: '';
}
.prizm-icon.alerts-help-circle-outline:before {
  content: '';
}
.prizm-icon.alerts-info-add-incongruity:before {
  content: '';
}
.prizm-icon.alerts-info-circle-fill:before {
  content: '';
}
.prizm-icon.alerts-info-square-fill:before {
  content: '';
}
.prizm-icon.alerts-info-square:before {
  content: '';
}
.prizm-icon.alerts-info-triangle-fill:before {
  content: '';
}
.prizm-icon.alerts-info-triangle:before {
  content: '';
}
.prizm-icon.alerts-inform-fill-mini:before {
  content: '';
}
.prizm-icon.alerts-inform-mini:before {
  content: '';
}
.prizm-icon.alerts-inform:before {
  content: '';
}
.prizm-icon.alerts-information-variant:before {
  content: '';
}
.prizm-icon.alerts-mark:before {
  content: '';
}
.prizm-icon.alerts-octagon-fill:before {
  content: '';
}
.prizm-icon.alerts-octagon:before {
  content: '';
}
.prizm-icon.alerts-pulse:before {
  content: '';
}
.prizm-icon.alerts-question-mark:before {
  content: '';
}
.prizm-icon.alerts-square:before {
  content: '';
}
.prizm-icon.alerts-warning:before {
  content: '';
}
.prizm-icon.alerts:before {
  content: '';
}
.prizm-icon.success-circle-fill:before {
  content: '';
}
.prizm-icon.success-circle-outline:before {
  content: '';
}
.prizm-icon.success-decagram-fill:before {
  content: '';
}
.prizm-icon.success-decagram-outline:before {
  content: '';
}
.prizm-icon.arrows-all:before {
  content: '';
}
.prizm-icon.arrows-arrow-circle-down:before {
  content: '';
}
.prizm-icon.arrows-arrow-circle-left:before {
  content: '';
}
.prizm-icon.arrows-arrow-circle-right:before {
  content: '';
}
.prizm-icon.arrows-arrow-circle-up:before {
  content: '';
}
.prizm-icon.arrows-arrow-expand-horizontal:before {
  content: '';
}
.prizm-icon.arrows-arrow-expand-vertical:before {
  content: '';
}
.prizm-icon.arrows-arrow-left:before {
  content: '';
}
.prizm-icon.arrows-arrow-right:before {
  content: '';
}
.prizm-icon.arrows-arrow-up-level:before {
  content: '';
}
.prizm-icon.arrows-autorenew-1:before {
  content: '';
}
.prizm-icon.arrows-autorenew:before {
  content: '';
}
.prizm-icon.arrows-back:before {
  content: '';
}
.prizm-icon.arrows-cached-1:before {
  content: '';
}
.prizm-icon.arrows-cached:before {
  content: '';
}
.prizm-icon.arrows-chevron-left:before {
  content: '';
}
.prizm-icon.arrows-chevron-right:before {
  content: '';
}
.prizm-icon.arrows-collapse-four-arrows:before {
  content: '';
}
.prizm-icon.arrows-collapse:before {
  content: '';
}
.prizm-icon.arrows-compare:before {
  content: '';
}
.prizm-icon.arrows-corner-chek-in:before {
  content: '';
}
.prizm-icon.arrows-corner-down-left:before {
  content: '';
}
.prizm-icon.arrows-corner-down-right:before {
  content: '';
}
.prizm-icon.arrows-corner-left-down:before {
  content: '';
}
.prizm-icon.arrows-corner-left-up:before {
  content: '';
}
.prizm-icon.arrows-corner-right-down:before {
  content: '';
}
.prizm-icon.arrows-corner-right-up:before {
  content: '';
}
.prizm-icon.arrows-corner-up-left:before {
  content: '';
}
.prizm-icon.arrows-corner-up-right:before {
  content: '';
}
.prizm-icon.arrows-diagonal-arrow-left-down:before {
  content: '';
}
.prizm-icon.arrows-diagonal-arrow-left-up:before {
  content: '';
}
.prizm-icon.arrows-diagonal-arrow-right-down:before {
  content: '';
}
.prizm-icon.arrows-diagonal-arrow-right-up:before {
  content: '';
}
.prizm-icon.arrows-down-2:before {
  content: '';
}
.prizm-icon.arrows-down-bold:before {
  content: '';
}
.prizm-icon.arrows-down-double-2:before {
  content: '';
}
.prizm-icon.arrows-down-double-top-2:before {
  content: '';
}
.prizm-icon.arrows-down-limit-1:before {
  content: '';
}
.prizm-icon.arrows-down-limit-double-1:before {
  content: '';
}
.prizm-icon.arrows-down:before {
  content: '';
}
.prizm-icon.arrows-download:before {
  content: '';
}
.prizm-icon.arrows-expand-all:before {
  content: '';
}
.prizm-icon.arrows-expand:before {
  content: '';
}
.prizm-icon.arrows-left-bold:before {
  content: '';
}
.prizm-icon.arrows-next:before {
  content: '';
}
.prizm-icon.arrows-refresh:before {
  content: '';
}
.prizm-icon.arrows-replay:before {
  content: '';
}
.prizm-icon.arrows-right-bold:before {
  content: '';
}
.prizm-icon.arrows-shuffle-variant:before {
  content: '';
}
.prizm-icon.arrows-shuffle:before {
  content: '';
}
.prizm-icon.arrows-swap-horiz-2:before {
  content: '';
}
.prizm-icon.arrows-swap-horiz:before {
  content: '';
}
.prizm-icon.arrows-swap-vert-2:before {
  content: '';
}
.prizm-icon.arrows-swap-vert:before {
  content: '';
}
.prizm-icon.arrows-up-2:before {
  content: '';
}
.prizm-icon.arrows-up-bold:before {
  content: '';
}
.prizm-icon.arrows-up-double-2:before {
  content: '';
}
.prizm-icon.arrows-up-double-top-2:before {
  content: '';
}
.prizm-icon.arrows-up-limit-1:before {
  content: '';
}
.prizm-icon.arrows-up-limit-double-1:before {
  content: '';
}
.prizm-icon.arrows-up:before {
  content: '';
}
.prizm-icon.audio-add-library-music:before {
  content: '';
}
.prizm-icon.audio-artist:before {
  content: '';
}
.prizm-icon.audio-bookmark-music:before {
  content: '';
}
.prizm-icon.audio-file-music:before {
  content: '';
}
.prizm-icon.audio-microphone-off:before {
  content: '';
}
.prizm-icon.audio-microphone:before {
  content: '';
}
.prizm-icon.audio-music-box:before {
  content: '';
}
.prizm-icon.audio-music-circle:before {
  content: '';
}
.prizm-icon.audio-music-off:before {
  content: '';
}
.prizm-icon.audio-music:before {
  content: '';
}
.prizm-icon.audio-volume-high:before {
  content: '';
}
.prizm-icon.audio-volume-low:before {
  content: '';
}
.prizm-icon.audio-volume-medium:before {
  content: '';
}
.prizm-icon.audio-volume-minus:before {
  content: '';
}
.prizm-icon.audio-volume-mute:before {
  content: '';
}
.prizm-icon.audio-volume-off:before {
  content: '';
}
.prizm-icon.audio-volume-plus:before {
  content: '';
}
.prizm-icon.badges-clipboard-tex:before {
  content: '';
}
.prizm-icon.badges-clipboard:before {
  content: '';
}
.prizm-icon.badges-code-download:before {
  content: '';
}
.prizm-icon.badges-code:before {
  content: '';
}
.prizm-icon.badges-tag-badges-tag-archve:before {
  content: '';
}
.prizm-icon.badges-tag-fill:before {
  content: '';
}
.prizm-icon.badges-tag-multiple:before {
  content: '';
}
.prizm-icon.badges-tag-plus:before {
  content: '';
}
.prizm-icon.badges-tag-remove:before {
  content: '';
}
.prizm-icon.badges-tag-search:before {
  content: '';
}
.prizm-icon.badges-tag-with-an-exclamation-mark:before {
  content: '';
}
.prizm-icon.badges-tag-with-binding-to-data:before {
  content: '';
}
.prizm-icon.badges-tag-without-binding-to-data:before {
  content: '';
}
.prizm-icon.badges-tag:before {
  content: '';
}
.prizm-icon.battery-10:before {
  content: '';
}
.prizm-icon.battery-30:before {
  content: '';
}
.prizm-icon.battery-50:before {
  content: '';
}
.prizm-icon.battery-80:before {
  content: '';
}
.prizm-icon.battery-alert:before {
  content: '';
}
.prizm-icon.battery-charging:before {
  content: '';
}
.prizm-icon.battery-flash-off:before {
  content: '';
}
.prizm-icon.battery-flash-ok:before {
  content: '';
}
.prizm-icon.battery-flash:before {
  content: '';
}
.prizm-icon.battery-minus:before {
  content: '';
}
.prizm-icon.battery-plus:before {
  content: '';
}
.prizm-icon.battery:before {
  content: '';
}
.prizm-icon.bookmarks-book-open-page-variant:before {
  content: '';
}
.prizm-icon.bookmarks-book-open-plus:before {
  content: '';
}
.prizm-icon.bookmarks-book-open-re-1:before {
  content: '';
}
.prizm-icon.bookmarks-book-open-re:before {
  content: '';
}
.prizm-icon.bookmarks-book-open-variant:before {
  content: '';
}
.prizm-icon.bookmarks-book-open:before {
  content: '';
}
.prizm-icon.bookmarks-book:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-check-fill:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-check:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-fill:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-music-fill:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-music:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-plus-fill:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-plus:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-remove-2:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-remove-fill:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark-remove:before {
  content: '';
}
.prizm-icon.bookmarks-bookmark:before {
  content: '';
}
.prizm-icon.camera-camera:before {
  content: '';
}
.prizm-icon.camera-clapperboard-close:before {
  content: '';
}
.prizm-icon.camera-clapperboard:before {
  content: '';
}
.prizm-icon.camera-close:before {
  content: '';
}
.prizm-icon.camera-film-close:before {
  content: '';
}
.prizm-icon.camera-film:before {
  content: '';
}
.prizm-icon.camera-image:before {
  content: '';
}
.prizm-icon.camera-video-close:before {
  content: '';
}
.prizm-icon.camera-video-off:before {
  content: '';
}
.prizm-icon.camera-video:before {
  content: '';
}
.prizm-icon.camera:before {
  content: '';
}
.prizm-icon.cancel-block:before {
  content: '';
}
.prizm-icon.cancel-close-box-fill:before {
  content: '';
}
.prizm-icon.cancel-close-box:before {
  content: '';
}
.prizm-icon.cancel-close-circle-fill:before {
  content: '';
}
.prizm-icon.cancel-close-circle:before {
  content: '';
}
.prizm-icon.cancel-close-small:before {
  content: '';
}
.prizm-icon.cancel-close:before {
  content: '';
}
.prizm-icon.cancel-delete-line:before {
  content: '';
}
.prizm-icon.cancel-delete-content:before {
  content: '';
}
.prizm-icon.cansel-delete-file:before {
  content: '';
}
.prizm-icon.charts-arc-1:before {
  content: '';
}
.prizm-icon.charts-arc:before {
  content: '';
}
.prizm-icon.charts-areaspline:before {
  content: '';
}
.prizm-icon.charts-bar-graph:before {
  content: '';
}
.prizm-icon.charts-bar-stacked:before {
  content: '';
}
.prizm-icon.charts-bar:before {
  content: '';
}
.prizm-icon.charts-bubble-line:before {
  content: '';
}
.prizm-icon.charts-bubble:before {
  content: '';
}
.prizm-icon.charts-chart-donut-variant-half:before {
  content: '';
}
.prizm-icon.charts-chart-donut-variant:before {
  content: '';
}
.prizm-icon.charts-discharge:before {
  content: '';
}
.prizm-icon.charts-donut:before {
  content: '';
}
.prizm-icon.charts-elevation-decline:before {
  content: '';
}
.prizm-icon.charts-elevation-rise:before {
  content: '';
}
.prizm-icon.charts-finance:before {
  content: '';
}
.prizm-icon.charts-gantt:before {
  content: '';
}
.prizm-icon.charts-google-analytics:before {
  content: '';
}
.prizm-icon.charts-google-assistant:before {
  content: '';
}
.prizm-icon.charts-hexagon-multiple:before {
  content: '';
}
.prizm-icon.charts-histogram-chart-dashed-2:before {
  content: '';
}
.prizm-icon.charts-histogram-chart-dashed:before {
  content: '';
}
.prizm-icon.charts-histogram:before {
  content: '';
}
.prizm-icon.charts-line-variant:before {
  content: '';
}
.prizm-icon.charts-pie-1:before {
  content: '';
}
.prizm-icon.charts-pie-2:before {
  content: '';
}
.prizm-icon.charts-pie:before {
  content: '';
}
.prizm-icon.charts-poll-box:before {
  content: '';
}
.prizm-icon.charts-poll:before {
  content: '';
}
.prizm-icon.charts-scatterplot-hexbin:before {
  content: '';
}
.prizm-icon.charts-signal:before {
  content: '';
}
.prizm-icon.charts-smart-trend:before {
  content: '';
}
.prizm-icon.charts-trending-down:before {
  content: '';
}
.prizm-icon.charts-trending-up:before {
  content: '';
}
.prizm-icon.chevron-middle:before {
  content: '';
}
.prizm-icon.chevrons-close-all:before {
  content: '';
}
.prizm-icon.chevrons-double-down:before {
  content: '';
}
.prizm-icon.chevrons-double-left-mini:before {
  content: '';
}
.prizm-icon.chevrons-double-left:before {
  content: '';
}
.prizm-icon.chevrons-double-right-mini:before {
  content: '';
}
.prizm-icon.chevrons-double-right:before {
  content: '';
}
.prizm-icon.chevrons-double-up:before {
  content: '';
}
.prizm-icon.chevrons-down:before {
  content: '';
}
.prizm-icon.chevrons-dropdown-small:before {
  content: '';
}
.prizm-icon.chevrons-dropdown:before {
  content: '';
}
.prizm-icon.chevrons-dropup:before {
  content: '';
}
.prizm-icon.chevrons-input:before {
  content: '';
}
.prizm-icon.chevrons-left-mini:before {
  content: '';
}
.prizm-icon.chevrons-left:before {
  content: '';
}
.prizm-icon.chevrons-menu-left-small:before {
  content: '';
}
.prizm-icon.chevrons-menu-left:before {
  content: '';
}
.prizm-icon.chevrons-menu-right:before {
  content: '';
}
.prizm-icon.chevrons-open-all:before {
  content: '';
}
.prizm-icon.chevrons-right-mini:before {
  content: '';
}
.prizm-icon.chevrons-right:before {
  content: '';
}
.prizm-icon.chevrons-unfold-less-horizontal:before {
  content: '';
}
.prizm-icon.chevrons-unfold-less-verica:before {
  content: '';
}
.prizm-icon.chevrons-unfold-more-horizontal:before {
  content: '';
}
.prizm-icon.chevrons-unfold-more-vertical:before {
  content: '';
}
.prizm-icon.chevrons-up:before {
  content: '';
}
.prizm-icon.date-access-alarm:before {
  content: '';
}
.prizm-icon.date-asterisk:before {
  content: '';
}
.prizm-icon.date-calendar-blank:before {
  content: '';
}
.prizm-icon.date-calendar-check:before {
  content: '';
}
.prizm-icon.date-calendar-clock:before {
  content: '';
}
.prizm-icon.date-calendar-plus:before {
  content: '';
}
.prizm-icon.date-calendar-question:before {
  content: '';
}
.prizm-icon.date-calendar-range:before {
  content: '';
}
.prizm-icon.date-calendar-remove:before {
  content: '';
}
.prizm-icon.date-calendar-text:before {
  content: '';
}
.prizm-icon.date-calendar-today:before {
  content: '';
}
.prizm-icon.date-calendar:before {
  content: '';
}
.prizm-icon.date-clock-alert:before {
  content: '';
}
.prizm-icon.date-clock-end:before {
  content: '';
}
.prizm-icon.date-clock-in:before {
  content: '';
}
.prizm-icon.date-clock-out:before {
  content: '';
}
.prizm-icon.date-clock-start:before {
  content: '';
}
.prizm-icon.date-clock:before {
  content: '';
}
.prizm-icon.date-day:before {
  content: '';
}
.prizm-icon.date-history:before {
  content: '';
}
.prizm-icon.date-hour:before {
  content: '';
}
.prizm-icon.date-inform-add:before {
  content: '';
}
.prizm-icon.date-inform-delete:before {
  content: '';
}
.prizm-icon.date-midnight:before {
  content: '';
}
.prizm-icon.date-minute:before {
  content: '';
}
.prizm-icon.date-month:before {
  content: '';
}
.prizm-icon.date-now:before {
  content: '';
}
.prizm-icon.date-period-dropdown-list-open:before {
  content: '';
}
.prizm-icon.date-period-dropdown-list:before {
  content: '';
}
.prizm-icon.date-range:before {
  content: '';
}
.prizm-icon.date-second:before {
  content: '';
}
.prizm-icon.date-time-add-2:before {
  content: '';
}
.prizm-icon.date-time-add:before {
  content: '';
}
.prizm-icon.date-time-remove-2:before {
  content: '';
}
.prizm-icon.date-time-remove:before {
  content: '';
}
.prizm-icon.date-timer-sand-empty:before {
  content: '';
}
.prizm-icon.date-timer:before {
  content: '';
}
.prizm-icon.date-update:before {
  content: '';
}
.prizm-icon.date-year:before {
  content: '';
}
.prizm-icon.delete-do-not-disturb:before {
  content: '';
}
.prizm-icon.delete-drop:before {
  content: '';
}
.prizm-icon.delete-empty:before {
  content: '';
}
.prizm-icon.delete-forever:before {
  content: '';
}
.prizm-icon.delete-label-delete:before {
  content: '';
}
.prizm-icon.delete-minus-circle:before {
  content: '';
}
.prizm-icon.delete-minus-square:before {
  content: '';
}
.prizm-icon.delete-minus:before {
  content: '';
}
.prizm-icon.delete-restore:before {
  content: '';
}
.prizm-icon.delete:before {
  content: '';
}
.prizm-icon.devices-android-debug-bridge:before {
  content: '';
}
.prizm-icon.devices-android:before {
  content: '';
}
.prizm-icon.devices-camera-front:before {
  content: '';
}
.prizm-icon.devices-cellphone-basic:before {
  content: '';
}
.prizm-icon.devices-cellphone-link-off:before {
  content: '';
}
.prizm-icon.devices-cellphone-link:before {
  content: '';
}
.prizm-icon.devices-cellphone-wireless:before {
  content: '';
}
.prizm-icon.devices-cellphone:before {
  content: '';
}
.prizm-icon.devices-desktop-tower:before {
  content: '';
}
.prizm-icon.devices-hard-drive-outline:before {
  content: '';
}
.prizm-icon.devices-headphones:before {
  content: '';
}
.prizm-icon.devices-input-device:before {
  content: '';
}
.prizm-icon.devices-laptop-off-1:before {
  content: '';
}
.prizm-icon.devices-laptop-off:before {
  content: '';
}
.prizm-icon.devices-laptop:before {
  content: '';
}
.prizm-icon.devices-monitor:before {
  content: '';
}
.prizm-icon.devices-printer-dropdown-list:before {
  content: '';
}
.prizm-icon.devices-printer:before {
  content: '';
}
.prizm-icon.devices-sensor:before {
  content: '';
}
.prizm-icon.devices-speaker:before {
  content: '';
}
.prizm-icon.devices-tablet:before {
  content: '';
}
.prizm-icon.devices-touchpad:before {
  content: '';
}
.prizm-icon.devices-transmitter:before {
  content: '';
}
.prizm-icon.devices-tv:before {
  content: '';
}
.prizm-icon.devices-watch-variant:before {
  content: '';
}
.prizm-icon.editor-add-column:before {
  content: '';
}
.prizm-icon.editor-add-row:before {
  content: '';
}
.prizm-icon.editor-alfavit:before {
  content: '';
}
.prizm-icon.editor-attach-2:before {
  content: '';
}
.prizm-icon.editor-attach-file:before {
  content: '';
}
.prizm-icon.editor-auto:before {
  content: '';
}
.prizm-icon.editor-auto1:before {
  content: '';
}
.prizm-icon.editor-border-all:before {
  content: '';
}
.prizm-icon.editor-border-bottom:before {
  content: '';
}
.prizm-icon.editor-border-clear:before {
  content: '';
}
.prizm-icon.editor-border-color:before {
  content: '';
}
.prizm-icon.editor-border-horizontal:before {
  content: '';
}
.prizm-icon.editor-border-inner:before {
  content: '';
}
.prizm-icon.editor-border-left:before {
  content: '';
}
.prizm-icon.editor-border-outer:before {
  content: '';
}
.prizm-icon.editor-border-right:before {
  content: '';
}
.prizm-icon.editor-border-style:before {
  content: '';
}
.prizm-icon.editor-border-top:before {
  content: '';
}
.prizm-icon.editor-border-vertical:before {
  content: '';
}
.prizm-icon.editor-brace-left:before {
  content: '';
}
.prizm-icon.editor-brace-right:before {
  content: '';
}
.prizm-icon.editor-copy-column:before {
  content: '';
}
.prizm-icon.editor-copy-drop:before {
  content: '';
}
.prizm-icon.editor-copy-row:before {
  content: '';
}
.prizm-icon.editor-copy:before {
  content: '';
}
.prizm-icon.editor-crop:before {
  content: '';
}
.prizm-icon.editor-cross-out:before {
  content: '';
}
.prizm-icon.editor-curly-brace-left:before {
  content: '';
}
.prizm-icon.editor-curly-brace-right:before {
  content: '';
}
.prizm-icon.editor-delete-column:before {
  content: '';
}
.prizm-icon.editor-delete-row:before {
  content: '';
}
.prizm-icon.editor-disconnect-link:before {
  content: '';
}
.prizm-icon.editor-discrete-set-1:before {
  content: '';
}
.prizm-icon.editor-discrete-set:before {
  content: '';
}
.prizm-icon.editor-division:before {
  content: '';
}
.prizm-icon.editor-dots:before {
  content: '';
}
.prizm-icon.editor-down-1:before {
  content: '';
}
.prizm-icon.editor-down:before {
  content: '';
}
.prizm-icon.editor-drag-and-drop:before {
  content: '';
}
.prizm-icon.editor-dropdown-list-open:before {
  content: '';
}
.prizm-icon.editor-dropdown-list:before {
  content: '';
}
.prizm-icon.editor-droplet:before {
  content: '';
}
.prizm-icon.editor-format-align-center:before {
  content: '';
}
.prizm-icon.editor-format-align-justify:before {
  content: '';
}
.prizm-icon.editor-format-align-left:before {
  content: '';
}
.prizm-icon.editor-format-align-right:before {
  content: '';
}
.prizm-icon.editor-format-bold:before {
  content: '';
}
.prizm-icon.editor-format-color-fill:before {
  content: '';
}
.prizm-icon.editor-format-color-reset:before {
  content: '';
}
.prizm-icon.editor-format-color-text:before {
  content: '';
}
.prizm-icon.editor-format-indent:before {
  content: '';
}
.prizm-icon.editor-format-indent1:before {
  content: '';
}
.prizm-icon.editor-format-italic:before {
  content: '';
}
.prizm-icon.editor-format-list-bulleted:before {
  content: '';
}
.prizm-icon.editor-format-list-numbered:before {
  content: '';
}
.prizm-icon.editor-format-textdirection-l-to-r:before {
  content: '';
}
.prizm-icon.editor-format-underlined:before {
  content: '';
}
.prizm-icon.editor-formula:before {
  content: '';
}
.prizm-icon.editor-functions:before {
  content: '';
}
.prizm-icon.editor-group-choose:before {
  content: '';
}
.prizm-icon.editor-group-dropdown:before {
  content: '';
}
.prizm-icon.editor-group-panel:before {
  content: '';
}
.prizm-icon.editor-group:before {
  content: '';
}
.prizm-icon.editor-hash:before {
  content: '';
}
.prizm-icon.editor-horizontal-align-center-:before {
  content: '';
}
.prizm-icon.editor-insert-emoticon-sad:before {
  content: '';
}
.prizm-icon.editor-insert-emoticon:before {
  content: '';
}
.prizm-icon.editor-insert-link-add:before {
  content: '';
}
.prizm-icon.editor-insert-link-delete:before {
  content: '';
}
.prizm-icon.editor-insert-link:before {
  content: '';
}
.prizm-icon.editor-line-height:before {
  content: '';
}
.prizm-icon.editor-link:before {
  content: '';
}
.prizm-icon.editor-list-dushed-2:before {
  content: '';
}
.prizm-icon.editor-merge-type:before {
  content: '';
}
.prizm-icon.editor-mini:before {
  content: '';
}
.prizm-icon.editor-minus:before {
  content: '';
}
.prizm-icon.editor-mode:before {
  content: '';
}
.prizm-icon.editor-multiplication:before {
  content: '';
}
.prizm-icon.editor-panel-down:before {
  content: '';
}
.prizm-icon.editor-panel-left:before {
  content: '';
}
.prizm-icon.editor-panel-right:before {
  content: '';
}
.prizm-icon.editor-panel-top:before {
  content: '';
}
.prizm-icon.editor-percent-1:before {
  content: '';
}
.prizm-icon.editor-percent:before {
  content: '';
}
.prizm-icon.editor-plus:before {
  content: '';
}
.prizm-icon.editor-right-1:before {
  content: '';
}
.prizm-icon.editor-right:before {
  content: '';
}
.prizm-icon.editor-screen:before {
  content: '';
}
.prizm-icon.editor-search-hierarchy:before {
  content: '';
}
.prizm-icon.editor-search-tags-words:before {
  content: '';
}
.prizm-icon.editor-sort-asc:before {
  content: '';
}
.prizm-icon.editor-subskrit-text:before {
  content: '';
}
.prizm-icon.editor-superskrit-text:before {
  content: '';
}
.prizm-icon.editor-table-lookup:before {
  content: '';
}
.prizm-icon.editor-table:before {
  content: '';
}
.prizm-icon.editor-tag:before {
  content: '';
}
.prizm-icon.editor-template:before {
  content: '';
}
.prizm-icon.editor-title:before {
  content: '';
}
.prizm-icon.editor-transpose-right:before {
  content: '';
}
.prizm-icon.editor-transpose-top:before {
  content: '';
}
.prizm-icon.editor-transpose:before {
  content: '';
}
.prizm-icon.editor-ungroup:before {
  content: '';
}
.prizm-icon.editor-vertical-align-bottom:before {
  content: '';
}
.prizm-icon.editor-vertical-align-center:before {
  content: '';
}
.prizm-icon.editor-vertical-align-center1:before {
  content: '';
}
.prizm-icon.editor-vertical-align-top:before {
  content: '';
}
.prizm-icon.editor-window:before {
  content: '';
}
.prizm-icon.editor-wrap-text:before {
  content: '';
}
.prizm-icon.files-alarm:before {
  content: '';
}
.prizm-icon.files-archive:before {
  content: '';
}
.prizm-icon.files-box:before {
  content: '';
}
.prizm-icon.files-cloud-check:before {
  content: '';
}
.prizm-icon.files-cloud:before {
  content: '';
}
.prizm-icon.files-cloup-upload:before {
  content: '';
}
.prizm-icon.files-collection-2:before {
  content: '';
}
.prizm-icon.files-confirmation:before {
  content: '';
}
.prizm-icon.files-correct:before {
  content: '';
}
.prizm-icon.files-description-properties:before {
  content: '';
}
.prizm-icon.files-document-image:before {
  content: '';
}
.prizm-icon.files-document-text:before {
  content: '';
}
.prizm-icon.files-excel-box:before {
  content: '';
}
.prizm-icon.files-export-1:before {
  content: '';
}
.prizm-icon.files-export-arr:before {
  content: '';
}
.prizm-icon.files-export-csv:before {
  content: '';
}
.prizm-icon.files-export-pdf:before {
  content: '';
}
.prizm-icon.files-export-sap:before {
  content: '';
}
.prizm-icon.files-export-xlsx:before {
  content: '';
}
.prizm-icon.files-export:before {
  content: '';
}
.prizm-icon.files-file-close:before {
  content: '';
}
.prizm-icon.files-file-doc:before {
  content: '';
}
.prizm-icon.files-file-docx:before {
  content: '';
}
.prizm-icon.files-file-open:before {
  content: '';
}
.prizm-icon.files-file-pdf:before {
  content: '';
}
.prizm-icon.files-file-play:before {
  content: '';
}
.prizm-icon.files-file-ppt:before {
  content: '';
}
.prizm-icon.files-file-pptx:before {
  content: '';
}
.prizm-icon.files-file-remove:before {
  content: '';
}
.prizm-icon.files-file-txt:before {
  content: '';
}
.prizm-icon.files-file-video:before {
  content: '';
}
.prizm-icon.files-file-xls:before {
  content: '';
}
.prizm-icon.files-file-xlsx:before {
  content: '';
}
.prizm-icon.files-file:before {
  content: '';
}
.prizm-icon.files-find-1:before {
  content: '';
}
.prizm-icon.files-find:before {
  content: '';
}
.prizm-icon.files-folder-1:before {
  content: '';
}
.prizm-icon.files-folder-add:before {
  content: '';
}
.prizm-icon.files-folder-close:before {
  content: '';
}
.prizm-icon.files-folder-delete:before {
  content: '';
}
.prizm-icon.files-folder-open-1:before {
  content: '';
}
.prizm-icon.files-folder-open:before {
  content: '';
}
.prizm-icon.files-folder-remove:before {
  content: '';
}
.prizm-icon.files-folder-root-add:before {
  content: '';
}
.prizm-icon.files-folder-root-remove:before {
  content: '';
}
.prizm-icon.files-folder:before {
  content: '';
}
.prizm-icon.files-import-1:before {
  content: '';
}
.prizm-icon.files-import-arr:before {
  content: '';
}
.prizm-icon.files-import-csv:before {
  content: '';
}
.prizm-icon.files-import-pdf:before {
  content: '';
}
.prizm-icon.files-import-xlsx:before {
  content: '';
}
.prizm-icon.files-import:before {
  content: '';
}
.prizm-icon.files-import1с:before {
  content: '';
}
.prizm-icon.files-in-group:before {
  content: '';
}
.prizm-icon.files-incorrect:before {
  content: '';
}
.prizm-icon.files-indicator:before {
  content: '';
}
.prizm-icon.files-loader:before {
  content: '';
}
.prizm-icon.files-method:before {
  content: '';
}
.prizm-icon.files-mnemo:before {
  content: '';
}
.prizm-icon.files-mode:before {
  content: '';
}
.prizm-icon.files-multi:before {
  content: '';
}
.prizm-icon.files-norm:before {
  content: '';
}
.prizm-icon.files-plus:before {
  content: '';
}
.prizm-icon.files-powerpoint:before {
  content: '';
}
.prizm-icon.files-specification:before {
  content: '';
}
.prizm-icon.files-view-document:before {
  content: '';
}
.prizm-icon.files-word-box:before {
  content: '';
}
.prizm-icon.files-просмотр:before {
  content: '';
}
.prizm-icon.finance-barcode:before {
  content: '';
}
.prizm-icon.finance-calculator-auto:before {
  content: '';
}
.prizm-icon.finance-calculator:before {
  content: '';
}
.prizm-icon.finance-credit-card-of:before {
  content: '';
}
.prizm-icon.finance-credit-card-plus:before {
  content: '';
}
.prizm-icon.finance-credit-card:before {
  content: '';
}
.prizm-icon.finance-currency-btc:before {
  content: '';
}
.prizm-icon.finance-currency-usd-off:before {
  content: '';
}
.prizm-icon.finance-currency-usd:before {
  content: '';
}
.prizm-icon.finance-qr-code-auto-1:before {
  content: '';
}
.prizm-icon.finance-qr-code-auto-2:before {
  content: '';
}
.prizm-icon.finance-qr-code-auto-3:before {
  content: '';
}
.prizm-icon.finance-qr-code-search:before {
  content: '';
}
.prizm-icon.finance-qr-code:before {
  content: '';
}
.prizm-icon.finance-scanner:before {
  content: '';
}
.prizm-icon.finance-wallet:before {
  content: '';
}
.prizm-icon.location-compass:before {
  content: '';
}
.prizm-icon.location-crosshairs-gps:before {
  content: '';
}
.prizm-icon.location-crosshairs:before {
  content: '';
}
.prizm-icon.location-language:before {
  content: '';
}
.prizm-icon.location-localization-down-list:before {
  content: '';
}
.prizm-icon.location-map-2:before {
  content: '';
}
.prizm-icon.location-map-marker-date-1:before {
  content: '';
}
.prizm-icon.location-map-marker-date-2:before {
  content: '';
}
.prizm-icon.location-map-marker-minus:before {
  content: '';
}
.prizm-icon.location-map-marker-off:before {
  content: '';
}
.prizm-icon.location-map-marker-plus:before {
  content: '';
}
.prizm-icon.location-map-marker:before {
  content: '';
}
.prizm-icon.location-map:before {
  content: '';
}
.prizm-icon.location-navigation-1:before {
  content: '';
}
.prizm-icon.location-navigation:before {
  content: '';
}
.prizm-icon.location-person-pin-circle:before {
  content: '';
}
.prizm-icon.location-pin-1:before {
  content: '';
}
.prizm-icon.location-pin-2:before {
  content: '';
}
.prizm-icon.location-pin:before {
  content: '';
}
.prizm-icon.editor-add-comment:before {
  content: '';
}
.prizm-icon.messaging-add-comment:before {
  content: '';
}
.prizm-icon.messaging-alert:before {
  content: '';
}
.prizm-icon.messaging-at:before {
  content: '';
}
.prizm-icon.messaging-emai-down-list:before {
  content: '';
}
.prizm-icon.messaging-emai:before {
  content: '';
}
.prizm-icon.messaging-email-open:before {
  content: '';
}
.prizm-icon.messaging-forward:before {
  content: '';
}
.prizm-icon.messaging-inbox:before {
  content: '';
}
.prizm-icon.messaging-message-circle:before {
  content: '';
}
.prizm-icon.messaging-message-off:before {
  content: '';
}
.prizm-icon.messaging-message:before {
  content: '';
}
.prizm-icon.messaging-processing:before {
  content: '';
}
.prizm-icon.messaging-question-ask:before {
  content: '';
}
.prizm-icon.messaging-reply:before {
  content: '';
}
.prizm-icon.messaging-send:before {
  content: '';
}
.prizm-icon.messaging-text:before {
  content: '';
}
.prizm-icon.network-close:before {
  content: '';
}
.prizm-icon.network-cloud-download:before {
  content: '';
}
.prizm-icon.network-cloud-upload:before {
  content: '';
}
.prizm-icon.network-database-administration:before {
  content: '';
}
.prizm-icon.network-database-delete:before {
  content: '';
}
.prizm-icon.network-database-json:before {
  content: '';
}
.prizm-icon.network-database-minus:before {
  content: '';
}
.prizm-icon.network-database-plus:before {
  content: '';
}
.prizm-icon.network-database-search:before {
  content: '';
}
.prizm-icon.network-database-sql:before {
  content: '';
}
.prizm-icon.network-database:before {
  content: '';
}
.prizm-icon.network-download:before {
  content: '';
}
.prizm-icon.network-ethernet-open:before {
  content: '';
}
.prizm-icon.network-external-link:before {
  content: '';
}
.prizm-icon.network-lan-connect:before {
  content: '';
}
.prizm-icon.network-lan-disconnect:before {
  content: '';
}
.prizm-icon.network-lan-pending:before {
  content: '';
}
.prizm-icon.network-lan:before {
  content: '';
}
.prizm-icon.network-layers:before {
  content: '';
}
.prizm-icon.network-library:before {
  content: '';
}
.prizm-icon.network-minus:before {
  content: '';
}
.prizm-icon.network-no-layers:before {
  content: '';
}
.prizm-icon.network-object:before {
  content: '';
}
.prizm-icon.network-plus:before {
  content: '';
}
.prizm-icon.network-scheme-2:before {
  content: '';
}
.prizm-icon.network-scheme:before {
  content: '';
}
.prizm-icon.network-security:before {
  content: '';
}
.prizm-icon.network-server-1:before {
  content: '';
}
.prizm-icon.network-server-network-off:before {
  content: '';
}
.prizm-icon.network-server-network:before {
  content: '';
}
.prizm-icon.network-server-off:before {
  content: '';
}
.prizm-icon.network-server-security:before {
  content: '';
}
.prizm-icon.network-server:before {
  content: '';
}
.prizm-icon.network-upload:before {
  content: '';
}
.prizm-icon.phone-call-missed:before {
  content: '';
}
.prizm-icon.phone-in-talk:before {
  content: '';
}
.prizm-icon.phone-minus:before {
  content: '';
}
.prizm-icon.phone-missed:before {
  content: '';
}
.prizm-icon.phone-off:before {
  content: '';
}
.prizm-icon.phone-paused:before {
  content: '';
}
.prizm-icon.phone-plus:before {
  content: '';
}
.prizm-icon.phone:before {
  content: '';
}
.prizm-icon.player-loop:before {
  content: '';
}
.prizm-icon.player-pause:before {
  content: '';
}
.prizm-icon.player-play-back:before {
  content: '';
}
.prizm-icon.player-play:before {
  content: '';
}
.prizm-icon.player-skip-backward:before {
  content: '';
}
.prizm-icon.player-skip-forward:before {
  content: '';
}
.prizm-icon.player-skip-next-circle-outline:before {
  content: '';
}
.prizm-icon.player-skip-previous:before {
  content: '';
}
.prizm-icon.player-stop:before {
  content: '';
}
.prizm-icon.player-voicemail:before {
  content: '';
}
.prizm-icon.burn:before {
  content: '';
}
.prizm-icon.cargo-box-2:before {
  content: '';
}
.prizm-icon.cargo-box:before {
  content: '';
}
.prizm-icon.cargo-container:before {
  content: '';
}
.prizm-icon.cargo:before {
  content: '';
}
.prizm-icon.fire:before {
  content: '';
}
.prizm-icon.freeze:before {
  content: '';
}
.prizm-icon.gas:before {
  content: '';
}
.prizm-icon.loading:before {
  content: '';
}
.prizm-icon.poi-achive-Mir:before {
  content: '';
}
.prizm-icon.poi-article-1:before {
  content: '';
}
.prizm-icon.poi-barley:before {
  content: '';
}
.prizm-icon.poi-bdrv-administration:before {
  content: '';
}
.prizm-icon.poi-boiler-hot-water-gas-and-oil:before {
  content: '';
}
.prizm-icon.poi-cancel-sample-reject:before {
  content: '';
}
.prizm-icon.poi-coal:before {
  content: '';
}
.prizm-icon.poi-crane:before {
  content: '';
}
.prizm-icon.poi-difference:before {
  content: '';
}
.prizm-icon.poi-early-version:before {
  content: '';
}
.prizm-icon.poi-ecopost-circle:before {
  content: '';
}
.prizm-icon.poi-ecopost-rectangle:before {
  content: '';
}
.prizm-icon.poi-electrics:before {
  content: '';
}
.prizm-icon.poi-factory pipe:before {
  content: '';
}
.prizm-icon.poi-factory-pipes:before {
  content: '';
}
.prizm-icon.poi-factory:before {
  content: '';
}
.prizm-icon.poi-fan:before {
  content: '';
}
.prizm-icon.poi-flask-1:before {
  content: '';
}
.prizm-icon.poi-flask-2:before {
  content: '';
}
.prizm-icon.poi-flask-3:before {
  content: '';
}
.prizm-icon.poi-flask-4:before {
  content: '';
}
.prizm-icon.poi-flask-5:before {
  content: '';
}
.prizm-icon.poi-fuel-oil-tank:before {
  content: '';
}
.prizm-icon.poi-fuel:before {
  content: '';
}
.prizm-icon.poi-gas-balloon:before {
  content: '';
}
.prizm-icon.poi-gas-monitoring:before {
  content: '';
}
.prizm-icon.poi-gas-station:before {
  content: '';
}
.prizm-icon.poi-gear-cooling:before {
  content: '';
}
.prizm-icon.poi-generator:before {
  content: '';
}
.prizm-icon.poi-git-lab:before {
  content: '';
}
.prizm-icon.poi-heat-exchanger:before {
  content: '';
}
.prizm-icon.poi-heat-recovery-boiler:before {
  content: '';
}
.prizm-icon.poi-high-speed-reduction-cooling:before {
  content: '';
}
.prizm-icon.poi-lng-storage:before {
  content: '';
}
.prizm-icon.poi-medicine:before {
  content: '';
}
.prizm-icon.poi-ni-fi:before {
  content: '';
}
.prizm-icon.poi-nuclear-station:before {
  content: '';
}
.prizm-icon.poi-oil-factory:before {
  content: '';
}
.prizm-icon.poi-oil-field:before {
  content: '';
}
.prizm-icon.poi-oil-storage:before {
  content: '';
}
.prizm-icon.poi-oil-tank:before {
  content: '';
}
.prizm-icon.poi-owl:before {
  content: '';
}
.prizm-icon.poi-pipe-sensor:before {
  content: '';
}
.prizm-icon.poi-pipe-valve:before {
  content: '';
}
.prizm-icon.poi-pipeline:before {
  content: '';
}
.prizm-icon.poi-plus:before {
  content: '';
}
.prizm-icon.poi-press:before {
  content: '';
}
.prizm-icon.poi-pump-drop:before {
  content: '';
}
.prizm-icon.poi-pump:before {
  content: '';
}
.prizm-icon.poi-reject-the-sample:before {
  content: '';
}
.prizm-icon.poi-respirator-2-1:before {
  content: '';
}
.prizm-icon.poi-respirator-2:before {
  content: '';
}
.prizm-icon.poi-respirator-3:before {
  content: '';
}
.prizm-icon.poi-respirator-4:before {
  content: '';
}
.prizm-icon.poi-respirator-5:before {
  content: '';
}
.prizm-icon.poi-respirator:before {
  content: '';
}
.prizm-icon.poi-separator:before {
  content: '';
}
.prizm-icon.poi-steam-boiler:before {
  content: '';
}
.prizm-icon.poi-steam-turbine:before {
  content: '';
}
.prizm-icon.poi-steam:before {
  content: '';
}
.prizm-icon.poi-strit:before {
  content: '';
}
.prizm-icon.poi-tank:before {
  content: '';
}
.prizm-icon.poi-water-heater:before {
  content: '';
}
.prizm-icon.poi-water-pump:before {
  content: '';
}
.prizm-icon.product-grade-according-to-specification:before {
  content: '';
}
.prizm-icon.snowflake:before {
  content: '';
}
.prizm-icon.selection-check-all:before {
  content: '';
}
.prizm-icon.selection-check-simple:before {
  content: '';
}
.prizm-icon.selection-checkbox-blank-circle:before {
  content: '';
}
.prizm-icon.selection-checkbox-blank:before {
  content: '';
}
.prizm-icon.selection-checkbox-marked-circle-chanel:before {
  content: '';
}
.prizm-icon.selection-checkbox-marked-circle:before {
  content: '';
}
.prizm-icon.selection-checked-1:before {
  content: '';
}
.prizm-icon.selection-checked-2:before {
  content: '';
}
.prizm-icon.selection-checked-empty:before {
  content: '';
}
.prizm-icon.selection-checked:before {
  content: '';
}
.prizm-icon.selection-choice:before {
  content: '';
}
.prizm-icon.selection-halfchoice:before {
  content: '';
}
.prizm-icon.selection-process:before {
  content: '';
}
.prizm-icon.selection-radio-off:before {
  content: '';
}
.prizm-icon.selection-radio-on:before {
  content: '';
}
.prizm-icon.selection-success:before {
  content: '';
}
.prizm-icon.selection-toggle-left:before {
  content: '';
}
.prizm-icon.selection-toggle-right:before {
  content: '';
}
.prizm-icon.selection-unchoice:before {
  content: '';
}
.prizm-icon.settings-2:before {
  content: '';
}
.prizm-icon.settings-apps:before {
  content: '';
}
.prizm-icon.settings-more-horiz:before {
  content: '';
}
.prizm-icon.settings-more-vert:before {
  content: '';
}
.prizm-icon.settings-power:before {
  content: '';
}
.prizm-icon.settings-tune-vertical:before {
  content: '';
}
.prizm-icon.settings-tune:before {
  content: '';
}
.prizm-icon.settings:before {
  content: '';
}
.prizm-icon.shapes-Key:before {
  content: '';
}
.prizm-icon.shapes-Star:before {
  content: '';
}
.prizm-icon.shapes-animation:before {
  content: '';
}
.prizm-icon.shapes-arrange-bring-forward:before {
  content: '';
}
.prizm-icon.shapes-arrange-send-backward:before {
  content: '';
}
.prizm-icon.shapes-arrange-send-to-back:before {
  content: '';
}
.prizm-icon.shapes-box-shadow:before {
  content: '';
}
.prizm-icon.shapes-checkbox-intermediate:before {
  content: '';
}
.prizm-icon.shapes-checkerboard:before {
  content: '';
}
.prizm-icon.shapes-collage:before {
  content: '';
}
.prizm-icon.shapes-collector:before {
  content: '';
}
.prizm-icon.shapes-cube-3:before {
  content: '';
}
.prizm-icon.shapes-cube-outline:before {
  content: '';
}
.prizm-icon.shapes-cube:before {
  content: '';
}
.prizm-icon.shapes-drawing-box:before {
  content: '';
}
.prizm-icon.shapes-drawing-outline:before {
  content: '';
}
.prizm-icon.shapes-drawing:before {
  content: '';
}
.prizm-icon.shapes-hexagon-fill:before {
  content: '';
}
.prizm-icon.shapes-hexagon:before {
  content: '';
}
.prizm-icon.shapes-layers-off:before {
  content: '';
}
.prizm-icon.shapes-layers:before {
  content: '';
}
.prizm-icon.shapes-nest-protec:before {
  content: '';
}
.prizm-icon.shapes-nut:before {
  content: '';
}
.prizm-icon.shapes-ontology-2:before {
  content: '';
}
.prizm-icon.shapes-ontology:before {
  content: '';
}
.prizm-icon.shapes-pentagon-fill:before {
  content: '';
}
.prizm-icon.shapes-pentagon:before {
  content: '';
}
.prizm-icon.shapes-rhombus-fill:before {
  content: '';
}
.prizm-icon.shapes-rhombus:before {
  content: '';
}
.prizm-icon.shapes-shape-1-f:before {
  content: '';
}
.prizm-icon.shapes-shape-1:before {
  content: '';
}
.prizm-icon.shapes-shape-2-f:before {
  content: '';
}
.prizm-icon.shapes-shape-2:before {
  content: '';
}
.prizm-icon.shapes-shape-3-f:before {
  content: '';
}
.prizm-icon.shapes-shape-3:before {
  content: '';
}
.prizm-icon.shapes-shape-4-f:before {
  content: '';
}
.prizm-icon.shapes-shape-4:before {
  content: '';
}
.prizm-icon.shapes-shape-5-f:before {
  content: '';
}
.prizm-icon.shapes-shape-5:before {
  content: '';
}
.prizm-icon.shapes-shape-6:before {
  content: '';
}
.prizm-icon.shapes-shape-7:before {
  content: '';
}
.prizm-icon.shapes-shape-fill:before {
  content: '';
}
.prizm-icon.shapes-shape-plus-1:before {
  content: '';
}
.prizm-icon.shapes-shape-plus:before {
  content: '';
}
.prizm-icon.shapes-shape:before {
  content: '';
}
.prizm-icon.shapes-square-inc:before {
  content: '';
}
.prizm-icon.shapes-triangle-fill:before {
  content: '';
}
.prizm-icon.shapes-triangle:before {
  content: '';
}
.prizm-icon.shopping-box:before {
  content: '';
}
.prizm-icon.shopping-cart:before {
  content: '';
}
.prizm-icon.shopping-gift:before {
  content: '';
}
.prizm-icon.shopping:before {
  content: '';
}
.prizm-icon.signal-access-point:before {
  content: '';
}
.prizm-icon.signal-bluetooth:before {
  content: '';
}
.prizm-icon.signal-cast:before {
  content: '';
}
.prizm-icon.signal-off:before {
  content: '';
}
.prizm-icon.signal-radio-tower:before {
  content: '';
}
.prizm-icon.signal-rss-box:before {
  content: '';
}
.prizm-icon.signal-wifi-off:before {
  content: '';
}
.prizm-icon.signal-wifi:before {
  content: '';
}
.prizm-icon.signal:before {
  content: '';
}
.prizm-icon.social-browser:before {
  content: '';
}
.prizm-icon.social-globe-2:before {
  content: '';
}
.prizm-icon.social-heart-fill:before {
  content: '';
}
.prizm-icon.social-heart:before {
  content: '';
}
.prizm-icon.social-home-breadcrumbs:before {
  content: '';
}
.prizm-icon.social-home:before {
  content: '';
}
.prizm-icon.social-instagram:before {
  content: '';
}
.prizm-icon.social-moon:before {
  content: '';
}
.prizm-icon.social-share:before {
  content: '';
}
.prizm-icon.social-star-fill:before {
  content: '';
}
.prizm-icon.social-star-half:before {
  content: '';
}
.prizm-icon.social-star:before {
  content: '';
}
.prizm-icon.social-sun:before {
  content: '';
}
.prizm-icon.social-tab:before {
  content: '';
}
.prizm-icon.social-telegram:before {
  content: '';
}
.prizm-icon.social-thumb-down:before {
  content: '';
}
.prizm-icon.social-thumb-up:before {
  content: '';
}
.prizm-icon.sort-abc-ru-up:before {
  content: '';
}
.prizm-icon.sort-abc-ru:before {
  content: '';
}
.prizm-icon.sort-alphabetical-up:before {
  content: '';
}
.prizm-icon.sort-alphabetical:before {
  content: '';
}
.prizm-icon.sort-apps:before {
  content: '';
}
.prizm-icon.sort-asc-arr:before {
  content: '';
}
.prizm-icon.sort-desc-arr:before {
  content: '';
}
.prizm-icon.sort-eye-off-2:before {
  content: '';
}
.prizm-icon.sort-eye-off:before {
  content: '';
}
.prizm-icon.sort-eye:before {
  content: '';
}
.prizm-icon.sort-filter-remove:before {
  content: '';
}
.prizm-icon.sort-filter-setting:before {
  content: '';
}
.prizm-icon.sort-filter:before {
  content: '';
}
.prizm-icon.sort-funnel-drop-down-list-open:before {
  content: '';
}
.prizm-icon.sort-funnel-drop-down-list:before {
  content: '';
}
.prizm-icon.sort-look-into-the-buffer:before {
  content: '';
}
.prizm-icon.sort-maximize:before {
  content: '';
}
.prizm-icon.sort-numeric:before {
  content: '';
}
.prizm-icon.sort-real-size:before {
  content: '';
}
.prizm-icon.sort-search-mistake:before {
  content: '';
}
.prizm-icon.sort-time-down:before {
  content: '';
}
.prizm-icon.sort-time-up:before {
  content: '';
}
.prizm-icon.sort-version:before {
  content: '';
}
.prizm-icon.sort-zoom-in-dropdown:before {
  content: '';
}
.prizm-icon.sort-zoom-in:before {
  content: '';
}
.prizm-icon.sort-zoom-out:before {
  content: '';
}
.prizm-icon.tools-auto-fix:before {
  content: '';
}
.prizm-icon.tools-award:before {
  content: '';
}
.prizm-icon.tools-briefcase:before {
  content: '';
}
.prizm-icon.tools-broom:before {
  content: '';
}
.prizm-icon.tools-brush:before {
  content: '';
}
.prizm-icon.tools-content-cut:before {
  content: '';
}
.prizm-icon.tools-content-save-as:before {
  content: '';
}
.prizm-icon.tools-content-save:before {
  content: '';
}
.prizm-icon.tools-content-unsave:before {
  content: '';
}
.prizm-icon.tools-earth-work:before {
  content: '';
}
.prizm-icon.tools-eraser:before {
  content: '';
}
.prizm-icon.tools-eyedropper:before {
  content: '';
}
.prizm-icon.tools-keyboard-close-1:before {
  content: '';
}
.prizm-icon.tools-keyboard-close:before {
  content: '';
}
.prizm-icon.tools-keyboard:before {
  content: '';
}
.prizm-icon.tools-lightbulb:before {
  content: '';
}
.prizm-icon.tools-npm:before {
  content: '';
}
.prizm-icon.tools-palette:before {
  content: '';
}
.prizm-icon.tools-pantone:before {
  content: '';
}
.prizm-icon.tools-ruler:before {
  content: '';
}
.prizm-icon.tools-save-all:before {
  content: '';
}
.prizm-icon.tools-save-back:before {
  content: '';
}
.prizm-icon.tools-solver:before {
  content: '';
}
.prizm-icon.tools-thermometer-minus:before {
  content: '';
}
.prizm-icon.tools-thermometer-plus:before {
  content: '';
}
.prizm-icon.tools-thermometer:before {
  content: '';
}
.prizm-icon.tools-umbrella:before {
  content: '';
}
.prizm-icon.transport-airplane:before {
  content: '';
}
.prizm-icon.transport-ambulance:before {
  content: '';
}
.prizm-icon.transport-truck-1:before {
  content: '';
}
.prizm-icon.transport-truck-2:before {
  content: '';
}
.prizm-icon.transport-truck-3:before {
  content: '';
}
.prizm-icon.transport-truck-4:before {
  content: '';
}
.prizm-icon.transport-truck-5:before {
  content: '';
}
.prizm-icon.transport-truck:before {
  content: '';
}
.prizm-icon.vectors-arrange-above:before {
  content: '';
}
.prizm-icon.vectors-arrange-below:before {
  content: '';
}
.prizm-icon.vectors-circle-variant:before {
  content: '';
}
.prizm-icon.vectors-circle:before {
  content: '';
}
.prizm-icon.vectors-combine-1:before {
  content: '';
}
.prizm-icon.vectors-combine:before {
  content: '';
}
.prizm-icon.vectors-curve:before {
  content: '';
}
.prizm-icon.vectors-default-24:before {
  content: '';
}
.prizm-icon.vectors-difference-ab:before {
  content: '';
}
.prizm-icon.vectors-difference-ba:before {
  content: '';
}
.prizm-icon.vectors-difference:before {
  content: '';
}
.prizm-icon.vectors-ellipse:before {
  content: '';
}
.prizm-icon.vectors-intersection:before {
  content: '';
}
.prizm-icon.vectors-line:before {
  content: '';
}
.prizm-icon.vectors-point:before {
  content: '';
}
.prizm-icon.vectors-polygon:before {
  content: '';
}
.prizm-icon.vectors-polyline:before {
  content: '';
}
.prizm-icon.vectors-radius:before {
  content: '';
}
.prizm-icon.vectors-rectangle:before {
  content: '';
}
.prizm-icon.vectors-selection:before {
  content: '';
}
.prizm-icon.vectors-square:before {
  content: '';
}
.prizm-icon.vectors-triangle:before {
  content: '';
}
.prizm-icon.view-agenda:before {
  content: '';
}
.prizm-icon.view-array:before {
  content: '';
}
.prizm-icon.view-carousel:before {
  content: '';
}
.prizm-icon.view-column:before {
  content: '';
}
.prizm-icon.view-component:before {
  content: '';
}
.prizm-icon.view-dashboard-variant:before {
  content: '';
}
.prizm-icon.view-dashboard:before {
  content: '';
}
.prizm-icon.view-day:before {
  content: '';
}
.prizm-icon.view-grid-outline:before {
  content: '';
}
.prizm-icon.view-grid:before {
  content: '';
}
.prizm-icon.view-headline:before {
  content: '';
}
.prizm-icon.view-list:before {
  content: '';
}
.prizm-icon.view-logo-grey-z:before {
  content: '';
}
.prizm-icon.view-logo-s.grey:before {
  content: '';
}
.prizm-icon.view-menu-2:before {
  content: '';
}
.prizm-icon.view-menu-arrow-down:before {
  content: '';
}
.prizm-icon.view-menu-arrow-left:before {
  content: '';
}
.prizm-icon.view-menu-arrow-right:before {
  content: '';
}
.prizm-icon.view-menu-arrow-up:before {
  content: '';
}
.prizm-icon.view-menu:before {
  content: '';
}
.prizm-icon.view-module:before {
  content: '';
}
.prizm-icon.view-quilt:before {
  content: '';
}
.prizm-icon.view-sequential:before {
  content: '';
}
