:root {
  --prizm-dropdown-host-container-offset: 4px;
}

.prizm-overlay-dropdown-host {
  &.t,
  &.tl,
  &.tr {
    transform: translateY(calc(0px - var(--prizm-dropdown-host-container-offset)));
  }

  &.b,
  &.bl,
  &.br {
    transform: translateY(var(--prizm-dropdown-host-container-offset));
  }

  &.l,
  &.lt,
  &.lb {
    transform: translateX(calc(0px - var(--prizm-dropdown-host-container-offset)));
  }

  &.r,
  &.rt,
  &.rb {
    transform: translateX(var(--prizm-dropdown-host-container-offset));
  }
}
