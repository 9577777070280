/* You can add global styles to this file, and also import other style files */
.t-logo .t-img {
  height: 26px;
}

/* hide mode toggle in api page  */
prizm-doc-demo {
  margin-top: 0 !important;

  .t-bg-toggle {
    display: none !important;
  }
}

/* hide header mode toggle  */
.night-mode {
  display: none !important;
}

prizm-input-layout,
prizm-input-date-range,
prizm-input-layout-date,
prizm-input-date-time,
prizm-input-date-time-range,
prizm-input-time {
  width: 20rem;
}

* {
  font-family: 'Inter', sans-serif;
}

body {
  --prizm-input-layout-width: 20rem;
}

prizm-data-list.is-full .t-empty {
  display: none;
}

.prizm-doc-content {
  --tui-base-01: var(--prizm-background-fill-primary);
  --tui-radius-m: 2px;
  --tui-base-03: var(--prizm-background-stroke);
  --tui-text-01: var(--prizm-text-icon-secondary);

  background-color: var(--prizm-background-fill-primary);
  border-style: var(--prizm-background-stroke);
  color: var(--prizm-text-icon-secondary);

  prizm-tag {
    display: none;
  }
}

.t-highlight {
  color: var(--prizm-text-icon-tertiary);
}

prizm-doc-demo.remove-sticky {
  position: static !important;
  top: 0 !important;
}

prizm-doc-page header.t-header {
  padding-left: min(10vw, 8.75rem) !important;
  padding-right: min(10vw, 8.75rem) !important;
  margin: 0 !important;
  background-image: url('./assets/head.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .t-title {
    font-size: 96px;
    display: contents;
  }

  .t-highlight,
  .t-title {
    font-family: 'Inter';
    color: white;
  }
}

.page-description {
  font-size: 20px;
  font-family: 'Inter';
  margin-top: 40px;
  font-weight: 300;
  color: #f4f6fb;
  line-height: 30px;
}

li {
  list-style: none;
}

prizm-doc-page,
.prizm-doc-page {
  min-height: 100vh;
}

.t-content prizm-doc-example:first-child {
  padding-top: 20px;
}
//for page with info
prizm-doc-page.info-page {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  ol {
    line-height: 36px;
  }

  a {
    color: rgb(0, 119, 255);
  }

  .italic {
    font-style: italic;
  }

  .warning-agreement {
    color: #ff6600;
    font-style: italic;
  }

  .addition {
    color: #b1acb5;
    font-style: italic;
  }

  .doc {
    &-table {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &-cell {
      border: 1px solid #aab8c6;
      border-radius: 5px;
      padding: 10px;
      flex: 1 1 calc((100% / 3) - 2rem);
    }
  }

  .disc {
    li {
      list-style-type: disc;
    }
  }

  .decimal {
    li {
      list-style-type: decimal;
    }
  }
}

[hidden] {
  visibility: hidden;
}

prizm-doc-navigation tui-accordion-item {
  --tui-base-02: var(--prizm-button-ghost-hover);
}

.template-link {
  color: var(--prizm-text-icon-link);

  &:hover {
    color: var(--prizm-text-icon-link-hover);
  }

  &:visited:not(:hover) {
    color: var(--prizm-text-icon-link-visited);
  }
}
