.prizmV3Palette() {
  --prizm-palette-shadow-dark: rgba(0, 0, 0, 0.5);
  --prizm-palette-shadow-light: rgba(191, 198, 215, 0.5);
  --prizm-palette-0-light: #ffffff;
  --prizm-palette-1200-light: #0f1115;
  --prizm-palette-gray-h50-light: #f8f8f9;
  --prizm-palette-gray-100-light: #f3f4f5;
  --prizm-palette-gray-h150-light: #ebedef;
  --prizm-palette-gray-200-light: #e0e3e7;
  --prizm-palette-gray-h250-light: #d4d8dd;
  --prizm-palette-gray-300-light: #c3c7cf;
  --prizm-palette-gray-h350-light: #b7bcc6;
  --prizm-palette-gray-400-light: #a7acb8;
  --prizm-palette-gray-h450-light: #9ba1af;
  --prizm-palette-gray-500-light: #8a91a2;
  --prizm-palette-gray-h550-light: #7e8799;
  --prizm-palette-gray-600-light: #6e778c;
  --prizm-palette-gray-h650-light: #646d84;
  --prizm-palette-gray-700-light: #545f77;
  --prizm-palette-gray-h750-light: #4c556b;
  --prizm-palette-gray-800-light: #3f4759;
  --prizm-palette-gray-h850-light: #373e4e;
  --prizm-palette-gray-900-light: #2c313d;
  --prizm-palette-gray-h950-light: #292e3a;
  --prizm-palette-gray-1000-light: #252a35;
  --prizm-palette-gray-h1050-light: #222731;
  --prizm-palette-gray-1100-light: #1e222a;
  --prizm-palette-gray-h1150-light: #181b22;
  --prizm-palette-blue-h50-light: #f6f8ff;
  --prizm-palette-blue-100-light: #f0f4ff;
  --prizm-palette-blue-h150-light: #e7edff;
  --prizm-palette-blue-200-light: #d9e2ff;
  --prizm-palette-blue-h250-light: #cad6fe;
  --prizm-palette-blue-300-light: #b4c4fd;
  --prizm-palette-blue-h350-light: #a4b8fd;
  --prizm-palette-blue-400-light: #8da7fd;
  --prizm-palette-blue-h450-light: #7d9bfd;
  --prizm-palette-blue-500-light: #6689fd;
  --prizm-palette-blue-h550-light: #587df7;
  --prizm-palette-blue-600-light: #436cee;
  --prizm-palette-blue-h650-light: #3d63da;
  --prizm-palette-blue-700-light: #3555bd;
  --prizm-palette-blue-h750-light: #304daa;
  --prizm-palette-blue-800-light: #28408d;
  --prizm-palette-blue-h850-light: #23387c;
  --prizm-palette-blue-900-light: #1b2b62;
  --prizm-palette-blue-h950-light: #1a295d;
  --prizm-palette-blue-1000-light: #182556;
  --prizm-palette-blue-h1050-light: #16234f;
  --prizm-palette-blue-1100-light: #132045;
  --prizm-palette-blue-h1150-light: #111a32;
  --prizm-palette-green-h50-light: #f5faf2;
  --prizm-palette-green-100-light: #eff6ea;
  --prizm-palette-green-h150-light: #e6f0dd;
  --prizm-palette-green-200-light: #d8e7ca;
  --prizm-palette-green-h250-light: #c9deb6;
  --prizm-palette-green-300-light: #b2d198;
  --prizm-palette-green-h350-light: #a3c784;
  --prizm-palette-green-400-light: #8cb966;
  --prizm-palette-green-h450-light: #7caf52;
  --prizm-palette-green-500-light: #65a134;
  --prizm-palette-green-h550-light: #5a9727;
  --prizm-palette-green-600-light: #498714;
  --prizm-palette-green-h650-light: #3e7c13;
  --prizm-palette-green-700-light: #3b6a15;
  --prizm-palette-green-h750-light: #366015;
  --prizm-palette-green-800-light: #2f5014;
  --prizm-palette-green-h850-light: #2a4513;
  --prizm-palette-green-900-light: #223512;
  --prizm-palette-green-h950-light: #203212;
  --prizm-palette-green-1000-light: #1e2e11;
  --prizm-palette-green-h1050-light: #1c2b0f;
  --prizm-palette-green-1100-light: #1a260d;
  --prizm-palette-green-h1150-light: #161e10;
  --prizm-palette-yellow-h50-light: #fef8e3;
  --prizm-palette-yellow-100-light: #fdf3d1;
  --prizm-palette-yellow-h150-light: #fbecb3;
  --prizm-palette-yellow-200-light: #f9e187;
  --prizm-palette-yellow-h250-light: #f4d560;
  --prizm-palette-yellow-300-light: #edc225;
  --prizm-palette-yellow-h350-light: #e1b725;
  --prizm-palette-yellow-400-light: #cea724;
  --prizm-palette-yellow-h450-light: #c29d24;
  --prizm-palette-yellow-500-light: #af8d23;
  --prizm-palette-yellow-h550-light: #a28322;
  --prizm-palette-yellow-600-light: #8f7420;
  --prizm-palette-yellow-h650-light: #836a1e;
  --prizm-palette-yellow-700-light: #725b1c;
  --prizm-palette-yellow-h750-light: #67521a;
  --prizm-palette-yellow-800-light: #564518;
  --prizm-palette-yellow-h850-light: #4b3d17;
  --prizm-palette-yellow-900-light: #3b3015;
  --prizm-palette-yellow-h950-light: #382d14;
  --prizm-palette-yellow-1000-light: #342912;
  --prizm-palette-yellow-h1050-light: #302511;
  --prizm-palette-yellow-1100-light: #29200f;
  --prizm-palette-yellow-h1150-light: #1f1a11;
  --prizm-palette-orange-h50-light: #fef7ee;
  --prizm-palette-orange-100-light: #fdf1e2;
  --prizm-palette-orange-h150-light: #fbe9d1;
  --prizm-palette-orange-200-light: #f8deb8;
  --prizm-palette-orange-h250-light: #f6d09a;
  --prizm-palette-orange-300-light: #f2bc6d;
  --prizm-palette-orange-h350-light: #f0ae51;
  --prizm-palette-orange-400-light: #ec9827;
  --prizm-palette-orange-h450-light: #de8e27;
  --prizm-palette-orange-500-light: #c98027;
  --prizm-palette-orange-h550-light: #bb7725;
  --prizm-palette-orange-600-light: #a66923;
  --prizm-palette-orange-h650-light: #986122;
  --prizm-palette-orange-700-light: #835420;
  --prizm-palette-orange-h750-light: #764b1e;
  --prizm-palette-orange-800-light: #623e1b;
  --prizm-palette-orange-h850-light: #553619;
  --prizm-palette-orange-900-light: #422b16;
  --prizm-palette-orange-h950-light: #3e2915;
  --prizm-palette-orange-1000-light: #392513;
  --prizm-palette-orange-h1050-light: #352312;
  --prizm-palette-orange-1100-light: #2e1f11;
  --prizm-palette-orange-h1150-light: #221913;
  --prizm-palette-red-h50-light: #fdf7f5;
  --prizm-palette-red-100-light: #fcf1ee;
  --prizm-palette-red-h150-light: #fae9e4;
  --prizm-palette-red-200-light: #f7dcd4;
  --prizm-palette-red-h250-light: #f4cec3;
  --prizm-palette-red-300-light: #f0baa9;
  --prizm-palette-red-h350-light: #eeab99;
  --prizm-palette-red-400-light: #eb9481;
  --prizm-palette-red-h450-light: #e9836f;
  --prizm-palette-red-500-light: #e66a54;
  --prizm-palette-red-h550-light: #df5a46;
  --prizm-palette-red-600-light: #d44332;
  --prizm-palette-red-h650-light: #c13f2f;
  --prizm-palette-red-700-light: #a5382a;
  --prizm-palette-red-h750-light: #943427;
  --prizm-palette-red-800-light: #7a2d22;
  --prizm-palette-red-h850-light: #6a291e;
  --prizm-palette-red-900-light: #512218;
  --prizm-palette-red-h950-light: #4d2017;
  --prizm-palette-red-1000-light: #461e15;
  --prizm-palette-red-h1050-light: #401c14;
  --prizm-palette-red-1050-light: #371912;
  --prizm-palette-red-h1150-light: #271613;
  --prizm-palette-0-dark: #0f1115;
  --prizm-palette-1200-dark: #ffffff;
  --prizm-palette-gray-h50-dark: #181b22;
  --prizm-palette-gray-100-dark: #1e222a;
  --prizm-palette-gray-h150-dark: #222731;
  --prizm-palette-gray-200-dark: #252a35;
  --prizm-palette-gray-h250-dark: #292e3a;
  --prizm-palette-gray-300-dark: #2c313d;
  --prizm-palette-gray-h350-dark: #373e4e;
  --prizm-palette-gray-400-dark: #3f4759;
  --prizm-palette-gray-h450-dark: #4c556b;
  --prizm-palette-gray-500-dark: #545f77;
  --prizm-palette-gray-h550-dark: #646d84;
  --prizm-palette-gray-600-dark: #6e778c;
  --prizm-palette-gray-h650-dark: #7e8799;
  --prizm-palette-gray-700-dark: #8a91a2;
  --prizm-palette-gray-h750-dark: #9ba1af;
  --prizm-palette-gray-800-dark: #a7acb8;
  --prizm-palette-gray-h850-dark: #b7bcc6;
  --prizm-palette-gray-900-dark: #c3c7cf;
  --prizm-palette-gray-h950-dark: #d4d8dd;
  --prizm-palette-gray-1000-dark: #e0e3e7;
  --prizm-palette-gray-h1050-dark: #ebedef;
  --prizm-palette-gray-1100-dark: #f3f4f5;
  --prizm-palette-gray-h1150-dark: #f8f8f9;
  --prizm-palette-blue-h50-dark: #111a32;
  --prizm-palette-blue-100-dark: #132045;
  --prizm-palette-blue-h150-dark: #16234f;
  --prizm-palette-blue-200-dark: #182556;
  --prizm-palette-blue-h250-dark: #1a295d;
  --prizm-palette-blue-300-dark: #1b2b62;
  --prizm-palette-blue-h350-dark: #23387c;
  --prizm-palette-blue-400-dark: #28408d;
  --prizm-palette-blue-h450-dark: #304daa;
  --prizm-palette-blue-500-dark: #3555bd;
  --prizm-palette-blue-h550-dark: #3d63da;
  --prizm-palette-blue-600-dark: #436cee;
  --prizm-palette-blue-h650-dark: #587df7;
  --prizm-palette-blue-700-dark: #6689fd;
  --prizm-palette-blue-h750-dark: #7d9bfd;
  --prizm-palette-blue-800-dark: #8da7fd;
  --prizm-palette-blue-h850-dark: #a4b8fd;
  --prizm-palette-blue-900-dark: #b4c4fd;
  --prizm-palette-blue-h950-dark: #cad6fe;
  --prizm-palette-blue-1000-dark: #d9e2ff;
  --prizm-palette-blue-h1050-dark: #e7edff;
  --prizm-palette-blue-1100-dark: #f0f4ff;
  --prizm-palette-blue-h1150-dark: #f4f7fe;
  --prizm-palette-green-h50-dark: #161e10;
  --prizm-palette-green-100-dark: #1a260d;
  --prizm-palette-green-h150-dark: #1c2b0f;
  --prizm-palette-green-200-dark: #1e2e11;
  --prizm-palette-green-h250-dark: #203212;
  --prizm-palette-green-300-dark: #223512;
  --prizm-palette-green-h350-dark: #2a4513;
  --prizm-palette-green-400-dark: #2f5014;
  --prizm-palette-green-h450-dark: #366015;
  --prizm-palette-green-500-dark: #3b6a15;
  --prizm-palette-green-h550-dark: #3e7c13;
  --prizm-palette-green-600-dark: #498714;
  --prizm-palette-green-h650-dark: #5a9727;
  --prizm-palette-green-700-dark: #65a134;
  --prizm-palette-green-h750-dark: #7caf52;
  --prizm-palette-green-800-dark: #8cb966;
  --prizm-palette-green-h850-dark: #a3c784;
  --prizm-palette-green-900-dark: #b2d198;
  --prizm-palette-green-h950-dark: #c9deb6;
  --prizm-palette-green-1000-dark: #d8e7ca;
  --prizm-palette-green-h1050-dark: #e6f0dd;
  --prizm-palette-green-1100-dark: #eff6ea;
  --prizm-palette-green-h1150-dark: #f5faf2;
  --prizm-palette-yellow-h50-dark: #1f1a11;
  --prizm-palette-yellow-100-dark: #29200f;
  --prizm-palette-yellow-h150-dark: #302511;
  --prizm-palette-yellow-200-dark: #342912;
  --prizm-palette-yellow-h250-dark: #382d14;
  --prizm-palette-yellow-300-dark: #3b3015;
  --prizm-palette-yellow-h350-dark: #4b3d17;
  --prizm-palette-yellow-400-dark: #564518;
  --prizm-palette-yellow-h450-dark: #67521a;
  --prizm-palette-yellow-500-dark: #725b1c;
  --prizm-palette-yellow-h550-dark: #836a1e;
  --prizm-palette-yellow-600-dark: #8f7420;
  --prizm-palette-yellow-h650-dark: #a28322;
  --prizm-palette-yellow-700-dark: #af8d23;
  --prizm-palette-yellow-h750-dark: #c29d24;
  --prizm-palette-yellow-800-dark: #cea724;
  --prizm-palette-yellow-h850-dark: #e1b725;
  --prizm-palette-yellow-900-dark: #edc225;
  --prizm-palette-yellow-h950-dark: #f4d560;
  --prizm-palette-yellow-1000-dark: #f9e187;
  --prizm-palette-yellow-h1050-dark: #fbecb3;
  --prizm-palette-yellow-1100-dark: #fdf3d1;
  --prizm-palette-yellow-h1150-dark: #fef8e3;
  --prizm-palette-orange-h50-dark: #221913;
  --prizm-palette-orange-100-dark: #2e1f11;
  --prizm-palette-orange-h150-dark: #352312;
  --prizm-palette-orange-200-dark: #392513;
  --prizm-palette-orange-h250-dark: #3e2915;
  --prizm-palette-orange-300-dark: #422b16;
  --prizm-palette-orange-h350-dark: #553619;
  --prizm-palette-orange-400-dark: #623e1b;
  --prizm-palette-orange-h450-dark: #764b1e;
  --prizm-palette-orange-500-dark: #835420;
  --prizm-palette-orange-h550-dark: #986122;
  --prizm-palette-orange-600-dark: #a66923;
  --prizm-palette-orange-h650-dark: #bb7725;
  --prizm-palette-orange-700-dark: #c98027;
  --prizm-palette-orange-h750-dark: #de8e27;
  --prizm-palette-orange-800-dark: #ec9827;
  --prizm-palette-orange-h850-dark: #f0ae51;
  --prizm-palette-orange-900-dark: #f2bc6d;
  --prizm-palette-orange-h950-dark: #f6d09a;
  --prizm-palette-orange-1000-dark: #f8deb8;
  --prizm-palette-orange-h1050-dark: #fbe9d1;
  --prizm-palette-orange-1100-dark: #fdf1e2;
  --prizm-palette-orange-h1150-dark: #fef7ee;
  --prizm-palette-red-h50-dark: #271613;
  --prizm-palette-red-100-dark: #371912;
  --prizm-palette-red-h150-dark: #401c14;
  --prizm-palette-red-200-dark: #461e15;
  --prizm-palette-red-h250-dark: #4d2017;
  --prizm-palette-red-300-dark: #512218;
  --prizm-palette-red-h350-dark: #6a291e;
  --prizm-palette-red-400-dark: #7a2d22;
  --prizm-palette-red-h450-dark: #943427;
  --prizm-palette-red-500-dark: #a5382a;
  --prizm-palette-red-h550-dark: #c13f2f;
  --prizm-palette-red-600-dark: #d44332;
  --prizm-palette-red-h650-dark: #df5a46;
  --prizm-palette-red-700-dark: #e66a54;
  --prizm-palette-red-h750-dark: #e9836f;
  --prizm-palette-red-800-dark: #eb9481;
  --prizm-palette-red-h850-dark: #eeab99;
  --prizm-palette-red-900-dark: #f0baa9;
  --prizm-palette-red-h950-dark: #f4cec3;
  --prizm-palette-red-1000-dark: #f7dcd4;
  --prizm-palette-red-h1050-dark: #fae9e4;
  --prizm-palette-red-1050-dark: #fcf1ee;
  --prizm-palette-red-h1150-dark: #fdf7f5;
}
