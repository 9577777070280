@icon-font-family: prizmIcons;

@font-face {
  font-family: @icon-font-family;
  src:
    local('Prizm Icons'),
    url('prizmIcons.woff2') format('woff2'),
    url('prizmIcons.woff') format('woff'),
    url('prizmIcons.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
