/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
.prizmDarkThemeV3() {
  --prizm-text-icon-primary: var(--prizm-palette-1200);
  --prizm-text-icon-secondary: var(--prizm-palette-gray-800);
  --prizm-text-icon-tertiary: var(--prizm-palette-gray-600);
  --prizm-text-icon-disable: var(--prizm-palette-gray-500);
  --prizm-text-icon-link: var(--prizm-palette-blue-500);
  --prizm-text-icon-link-hover: var(--prizm-palette-blue-h750);
  --prizm-text-icon-link-visited: var(--prizm-palette-blue-700);
  --prizm-text-icon-reverse: var(--prizm-palette-0);
  --prizm-text-icon-exception: var(--prizm-palette-1200);

  --prizm-background-fill-primary: var(--prizm-palette-gray-100);
  --prizm-background-fill-secondary: var(--prizm-palette-0);
  --prizm-background-fill-panel: var(--prizm-palette-gray-300);
  --prizm-background-fill-overlay: var(--prizm-palette-gray-200);
  --prizm-background-fill-blanket: #0f11157f;
  --prizm-background-fill-reverse: var(--prizm-palette-gray-1100);
  --prizm-background-stroke: var(--prizm-palette-gray-400);
  --prizm-background-stroke-focus: var(--prizm-palette-blue-800);

  --prizm-table-fill-header-default: var(--prizm-palette-gray-200);
  --prizm-table-fill-header-hover: var(--prizm-palette-gray-h250);
  --prizm-table-fill-row-zebra_default: var(--prizm-palette-gray-200);
  --prizm-table-fill-row-hover: var(--prizm-palette-gray-h250);
  --prizm-table-fill-row-active: var(--prizm-palette-blue-400);
  --prizm-table-fill-cell-disable: var(--prizm-palette-gray-300);
  --prizm-table-stroke-cell-default: var(--prizm-palette-gray-400);
  --prizm-table-stroke-cell-hover: var(--prizm-palette-gray-h650);
  --prizm-table-stroke-cell-active: var(--prizm-palette-blue-600);

  --prizm-status-info-primary-default: var(--prizm-palette-blue-600);
  --prizm-status-info-primary-hover: var(--prizm-palette-blue-h650);
  --prizm-status-info-secondary-default: var(--prizm-palette-blue-300);
  --prizm-status-info-secondary-hover: var(--prizm-palette-blue-h350);
  --prizm-status-none-primary-default: var(--prizm-palette-gray-600);
  --prizm-status-none-primary-hover: var(--prizm-palette-gray-h650);
  --prizm-status-none-secondary-default: var(--prizm-palette-gray-300);
  --prizm-status-none-secondary-hover: var(--prizm-palette-gray-h350);
  --prizm-status-success-primary-default: var(--prizm-palette-green-600);
  --prizm-status-success-primary-hover: var(--prizm-palette-green-h650);
  --prizm-status-success-secondary-default: var(--prizm-palette-green-300);
  --prizm-status-success-secondary-hover: var(--prizm-palette-green-h350);
  --prizm-status-attention-primary-default: var(--prizm-palette-yellow-900);
  --prizm-status-attention-primary-hover: var(--prizm-palette-yellow-h950);
  --prizm-status-attention-secondary-default: var(--prizm-palette-yellow-300);
  --prizm-status-attention-secondary-hover: var(--prizm-palette-yellow-h350);
  --prizm-status-warning-primary-default: var(--prizm-palette-orange-800);
  --prizm-status-warning-primary-hover: var(--prizm-palette-orange-h850);
  --prizm-status-warning-secondary-default: var(--prizm-palette-orange-300);
  --prizm-status-warning-secondary-hover: var(--prizm-palette-orange-h350);
  --prizm-status-alarm-primary-default: var(--prizm-palette-red-600);
  --prizm-status-alarm-primary-hover: var(--prizm-palette-red-h650);
  --prizm-status-alarm-secondary-default: var(--prizm-palette-red-300);
  --prizm-status-alarm-secondary-hover: var(--prizm-palette-red-h350);

  --prizm-index-plan: #4075ff;
  --prizm-index-fact: #ffffff;
  --prizm-index-prognosis: var(--prizm-palette-gray-600);

  --prizm-button-primary-solid-default: var(--prizm-palette-blue-600);
  --prizm-button-primary-solid-hover: var(--prizm-palette-blue-h650);
  --prizm-button-primary-solid-active: var(--prizm-palette-blue-700);
  --prizm-button-primary-ghost-active: var(--prizm-palette-blue-400);
  --prizm-button-secondary-solid-default: var(--prizm-palette-gray-600);
  --prizm-button-secondary-solid-hover: var(--prizm-palette-gray-h650);
  --prizm-button-secondary-solid-active: var(--prizm-palette-gray-700);
  --prizm-button-secondary-ghost-active: var(--prizm-palette-gray-400);
  --prizm-button-success-solid-default: var(--prizm-palette-green-600);
  --prizm-button-success-solid-hover: var(--prizm-palette-green-h650);
  --prizm-button-success-solid-active: var(--prizm-palette-green-700);
  --prizm-button-success-ghost-active: var(--prizm-palette-green-400);
  --prizm-button-warning-solid-default: var(--prizm-palette-orange-800);
  --prizm-button-warning-solid-hover: var(--prizm-palette-orange-h850);
  --prizm-button-warning-solid-active: var(--prizm-palette-orange-900);
  --prizm-button-warning-ghost-active: var(--prizm-palette-orange-400);
  --prizm-button-alarm-solid-default: var(--prizm-palette-red-600);
  --prizm-button-alarm-solid-hover: var(--prizm-palette-red-h650);
  --prizm-button-alarm-solid-active: var(--prizm-palette-red-700);
  --prizm-button-alarm-ghost-active: var(--prizm-palette-red-400);
  --prizm-button-ghost-hover: var(--prizm-palette-gray-h350);
  --prizm-button-disable: var(--prizm-palette-gray-300);

  --prizm-form-fill-default: var(--prizm-palette-0);
  --prizm-form-fill-disable: var(--prizm-palette-0);
  --prizm-form-stroke-default: var(--prizm-palette-gray-500);
  --prizm-form-stroke-hover: var(--prizm-palette-gray-h650);
  --prizm-form-stroke-disable: var(--prizm-palette-1200);
  --prizm-form-active: var(--prizm-palette-blue-600);
  --prizm-form-active-hover: var(--prizm-palette-blue-h650);
  --prizm-form-active-disable: var(--prizm-palette-blue-400);

  --prizm-chart-axis-default: var(--prizm-palette-gray-300);
  --prizm-chart-axis-line-hover: var(--prizm-palette-1200);
  --prizm-chart-axis-line-active: var(--prizm-palette-blue-600);
  --prizm-chart-axis-area-hover: #8a91a233;
  --prizm-chart-axis-area-active: #436cee33;
  --prizm-chart-fill-blanket: #8a91a233;

  --prizm-shadow-color: var(--prizm-palette-shadow-dark);
  --prizm-shadow-big-top: 0px -8px 32px 0px var(--prizm-shadow-color);
  --prizm-shadow-big-right: 8px 0px 32px 0px var(--prizm-shadow-color);
  --prizm-shadow-big-left: -8px 0px 32px 0px var(--prizm-shadow-color);
  --prizm-shadow-big-bottom: 0px 8px 32px 0px var(--prizm-shadow-color);
  --prizm-shadow-mini-top: 0px -2px 4px 0px var(--prizm-shadow-color);
  --prizm-shadow-mini-right: 2px 0px 4px 0px var(--prizm-shadow-color);
  --prizm-shadow-mini-left: -2px 0px 4px 0px var(--prizm-shadow-color);
  --prizm-shadow-mini-bottom: 0px 2px 4px 0px var(--prizm-shadow-color);
}
