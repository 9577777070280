@import '../mixins/mixins.less';

.prizm-skeleton {
  position: relative;
  color: transparent !important;
  background: transparent !important;
  pointer-events: none;
  user-select: none;
  border-color: transparent !important;
  outline-color: transparent !important;
  box-shadow: none !important;

  &:after {
    .fullsize();
    content: '';
    background-color: var(--prizm-status-none-secondary-default);
    animation: prizmSkeletonVibe ease-in-out 1s infinite alternate;
  }

  &_rounded:after {
    border-radius: inherit;
  }

  &_short:after {
    width: 40%;
  }

  &_center:after {
    .center-left();
    width: 40%;
  }

  &_text:before {
    content: '\a0';
  }

  & * {
    opacity: 0;
    visibility: hidden;
  }
}
